import React, { PureComponent } from 'react'

import { hasValue } from '../../../utils/utils'
import { Row } from '../row/Row'

export class BooleanEditor extends PureComponent {
  onClick = () => {
    const { onClick } = this.props
    if (hasValue(onClick)) {
      onClick()
    }
  }

  onChange = (e) => {
    const { onChange } = this.props
    if (hasValue(onChange)) {
      onChange(e.target.checked)
    }
  }

  render() {
    const { id, caption, value = false, style, ml, mt, labelWidth = '100%', autoFocus, readOnly } = this.props
    return (
      <Row style={{ justifyContent: 'space-between', ...style, marginLeft: ml, marginTop: mt }}>
        <label
          htmlFor={id}
          style={{
            width: labelWidth,
            marginRight: '8px',
            fontSize: 'small',
            fontWeight: 'bold',
            color: 'rgb(101, 121, 141)',
          }}
        >
          {caption}
        </label>
        <input
          id={id}
          type={'checkbox'}
          checked={value}
          tabIndex={0}
          autoFocus={autoFocus}
          disabled={readOnly}
          onClick={this.onClick}
          onChange={this.onChange}
        />
      </Row>
    )
  }
}
