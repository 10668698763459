import React, { useState, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { Button, ButtonsRow } from '../../ui'
import { TextInput } from '../../ui/textInput/TextInput'
import { useParams } from 'react-router'

export const CreateEmploymentButton = props => {
  const { onClick, style, l } = props

  const [email, setEmail] = useState('')
  const client = useClient()
  const { companyId } = useParams()

  const onEmailChange = useCallback(value => {
    setEmail(value)
  }, [])

  const onCreateEmploymentButtonClick = useCallback(() => {
    client.query({ name: 'Saturn', resolver: 'simpleUserByEmail', args: { email } }, (error, result) => {
      const user = hasValue(result) ? result.data : null
      if (hasValue(onClick) && hasValue(user) && user.email === email) {
        onClick(user.id)
        setEmail('')
      }
    })
  }, [email])
  const onSendInvitationButtonClick = useCallback(async () => {
    const payload = new URLSearchParams()
    payload.set('email', email)
    payload.set('companyId', companyId)
    const res = await fetch('/api/send-magic-link', { method: 'POST', credentials: 'same-origin', body: payload })
    if (!res.ok) {
      const { error: errorMesage } = await res.json()
      alert(errorMesage)
    } else {
      setEmail('')
    }
  }, [email])

  return (
    <ButtonsRow style={{ ...style }}>
      <TextInput
        id={'email'}
        value={email}
        placeholder={l.t('Employments.Actions.AddAndSendLinkDescription')}
        width={'450px'}
        onChange={onEmailChange}
      />
      <Button
        id={'createEmployment'}
        variant={'small'}
        title={l.t('Employments.Actions.Add')}
        onClick={onCreateEmploymentButtonClick}
      />
      <Button
        id={'sendInvitation'}
        variant={'small'}
        title={l.t('Employments.Actions.SendLink')}
        onClick={onSendInvitationButtonClick}
      />
    </ButtonsRow>
  )
}
