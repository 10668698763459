import React from 'react'
import { Box } from '@primer/react'

export const RootContainer = props => {
  const { children } = props
  return (
    <Box
      bg={'canvas.default'}
      display={'flex'}
      flexDirection={'column'}
      position={'absolute'}
      left={'0px'}
      top={'0px'}
      right={'0px'}
      bottom={'0px'}
      children={children}
    />
  )
}
