export const ticketActivityTypes = {
  created: { id: 'Created' },
  subjectChanged: { id: 'SubjectChanged' },
  clientChanged: { id: 'ClientChanged' },
  contactChanged: { id: 'ContactChanged' },
  agentChanged: { id: 'AgentChanged' },
  takenForClassification: { id: 'TakenForClassification' },
  sentForClarification: { id: 'SentForClarification' },
  commented: { id: 'Commented' },
  refused: { id: 'Refused' },
  returned: { id: 'Returned' },
  assigned: { id: 'Assigned' },
  slaChanged: { id: 'SlaChanged' },
  typeChanged: { id: 'TypeChanged' },
  accepted: { id: 'Accepted' },
  started: { id: 'Started' },
  paused: { id: 'Paused' },
  resumed: { id: 'Resumed' },
  reportCreated: { id: 'ReportCreated' },
  completed: { id: 'Completed' },
  rejected: { id: 'Rejected' },
  confirmed: { id: 'Confirmed' },
  feedbackSubmitted: { id: 'FeedbackSubmitted' },
}
