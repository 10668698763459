import React, { PureComponent } from 'react'
import { Box, Button, ButtonPrimary, Flash, Text, TextInput } from '@primer/react'

import { hasValue } from '../utils'

export class LoginPanel extends PureComponent {
  onEmailChange = e => {
    const { onEmailChange } = this.props
    if (onEmailChange instanceof Function) {
      onEmailChange(e.target.value)
    }
  }

  onPasswordChange = e => {
    const { onPasswordChange } = this.props
    if (onPasswordChange instanceof Function) {
      onPasswordChange(e.target.value)
    }
  }

  render() {
    const {
      title = 'Log in',
      emailTitle = 'Email',
      passwordTitle = 'Password',
      loginTitle = 'Log in',
      orTitle = 'Or',
      email,
      password,
      errorText,
      formAction,
      width = '310px',
      alternativeLogins,
      onLoginButtonClick,
    } = this.props
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: width }}>
        <Text id={'title'} sx={{ textAlign: 'center', fontSize: 4, mt: '35px', mb: '15px' }}>
          {title}
        </Text>
        {hasValue(errorText) && errorText !== '' ? (
          <Flash variant={'danger'} sx={{ paddingX: '20px', mb: '16px' }}>
            <Text sx={{ fontSize: 1, wordWrap: 'anywhere' }}>{errorText}</Text>
          </Flash>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'canvas.subtle',
            padding: '20px',
            borderStyle: 'solid',
            borderColor: 'border.muted',
            borderWidth: 1,
            borderRadius: 2,
          }}
        >
          <Text htmlFor={'email'} sx={{ fontSize: 1 }} as={'label'}>
            {emailTitle}
          </Text>
          <TextInput
            id={'email'}
            name={'email'}
            value={email}
            autoFocus={true}
            sx={{ width: '100%', mt: '5px', bg: 'canvas.default' }}
            onChange={this.onEmailChange}
          />
          <Text htmlFor={'password'} sx={{ fontSize: 1, mt: '15px' }} as={'label'}>
            {passwordTitle}
          </Text>
          <TextInput
            id={'password'}
            name={'password'}
            type={'password'}
            value={password}
            sx={{ width: '100%', mt: '5px', bg: 'canvas.default' }}
            onChange={this.onPasswordChange}
          />
          <ButtonPrimary
            id={'login'}
            type={'submit'}
            formAction={formAction}
            sx={{ width: '100%', mt: '20px' }}
            onClick={onLoginButtonClick}
          >
            {loginTitle}
          </ButtonPrimary>
          <Text sx={{ textAlign: 'center', fontSize: 1, width: '100%', mt: '15px' }}>{orTitle}</Text>
          {Array.isArray(alternativeLogins)
            ? alternativeLogins.map((alternativeLogin, index) => {
                return (
                  <Button key={index} href={alternativeLogin.url} sx={{ width: '100%', mt: '15px' }} as={'a'}>
                    {alternativeLogin.text}
                  </Button>
                )
              })
            : null}
        </Box>
      </Box>
    )
  }
}
