import styled from 'styled-components'

export const PanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 8px;
  }
  > :first-child {
    margin-top: 0;
  }
`
PanelColumn.displayName = 'PanelColumn'

export const Panel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
  background-color: rgb(255, 255, 255);

  > * {
    flex: 1;
    margin-right: 24px;
    margin-bottom: 24px;
  }
  > :last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    > * {
      margin-right: 0;
    }
  }
`
Panel.displayName = 'Panel'
