import React, { PureComponent } from 'react'
import getRelativeLuminance from 'get-relative-luminance'
import { Label as LibLabel } from '@primer/react'
import { hasValue } from '../utils'

const textColor1 = 'rgb(255, 255, 255)'
const textColor2 = 'rgb(37, 42, 47)'

export class Label extends PureComponent {
  correctColor = color => {
    const style = new Option().style
    style.color = color
    return style.color
  }

  getTextColor = color => {
    let result = null
    try {
      const l = getRelativeLuminance(color)
      result = l < 0.5 ? textColor1 : textColor2
    } catch (e) {
      result = textColor2
    }
    return result
  }

  render() {
    const { id, variant, color, ml, mt, mr, mb, children } = this.props
    let colors = {}
    if (hasValue(color)) {
      const correctedColor = this.correctColor(color)
      colors = {
        color: this.getTextColor(correctedColor),
        bg: correctedColor,
      }
    }
    return <LibLabel id={id} variant={variant} sx={{ ml, mt, mr, mb, ...colors }} children={children} />
  }
}
