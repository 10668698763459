import { pick } from '../../utils/utils'
import { User_Created, User_Updated, User_Password_Set, User_Superadmin_Changed, User_Superadmin_Set, User_Superadmin_Cleared } from '../eventTypes'

const userViewModel = {
  Init: () => ({}),

  [User_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, name, email } = payload
    return { id: aggregateId, authorId, createdOn: new Date(timestamp ?? 0), name, email }
  },

  [User_Updated]: (state: any, event: any) => {
    const { payload } = event
    const changes = pick(payload, ['name', 'email'])
    return { ...state, ...changes }
  },

  [User_Password_Set]: (state: any, event: any) => {
    return { ...state }
  },

  [User_Superadmin_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { superadmin } = payload
    return { ...state, superadmin }
  },

  [User_Superadmin_Set]: (state: any, event: any) => {
    return { ...state, superadmin: true }
  },

  [User_Superadmin_Cleared]: (state: any, event: any) => {
    return { ...state, superadmin: false }
  },
}

export default userViewModel
