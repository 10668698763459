import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Row } from '../row/Row'

const Text = styled.div`
  font-size: small;
  font-weight: normal;
  color: rgb(25, 42, 62);
`
Text.displayName = 'Text'

export class TextField extends PureComponent {
  render() {
    const { id, caption, value, style } = this.props
    return (
      <Row style={{ justifyContent: 'space-between', ...style }}>
        <label htmlFor={id} style={{ width: '100px', fontSize: 'small', fontWeight: 'bold', color: 'rgb(101, 121, 141)' }}>
          {caption}
        </label>
        <Text id={id}>{value}</Text>
      </Row>
    )
  }
}
