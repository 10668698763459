import React, { useState, useCallback } from 'react'

import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, Row } from '../../ui'

const MagicLinkLoginView = props => {
  const { l } = props
  const [email, setEmail] = useState('')

  const onEmailChange = value => {
    setEmail(value)
  }

  const onSendInvitation = useCallback(async () => {
    const payload = new URLSearchParams()
    payload.set('email', email)
    const res = await fetch('/api/send-magic-link', { method: 'POST', credentials: 'same-origin', body: payload })
    if (!res.ok) {
      const { error: errorMesage } = await res.json()
      alert(errorMesage)
    } else {
      setEmail('')
      alert(l.t('MagicLinkLoginView.CheckYourInbox'))
    }
  }, [email])

  return (
    <View>
      <ViewHeader text={l.t('MagicLinkLoginView.Title')} />
      <Row style={{ alignItems: 'flex-start' }}>
        <div style={{ width: '50%' }}>
          <TextEditor
            caption={l.t('MagicLinkLoginView.Email')}
            name={'email'}
            value={email}
            onChange={onEmailChange}
            placeholder={l.t('MagicLinkLoginView.EmailPlaceholder')}
            autoFocus={true}
          />
          <Row style={{ marginTop: '16px' }}>
            <Button variant={'small'} title={l.t('MagicLinkLoginView.Login')} onClick={onSendInvitation} />
          </Row>
        </div>
      </Row>
    </View>
  )
}

export default MagicLinkLoginView
