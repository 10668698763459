import { hasValue } from '../../utils/utils'
import {
  Company_Created,
  Company_Renamed,
  Company_Activity_Changed,
  Company_Superagent_Changed,
  Company_Employment_Created,
  Company_Employment_Removed,
  Company_Employment_Role_Added,
  Company_Employment_Role_Removed,
} from '../eventTypes'

const companyViewModel = {
  Init: () => ({}),

  [Company_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, name, active } = payload
    return { id: aggregateId, authorId, createdOn: new Date(timestamp), name, active }
  },

  [Company_Renamed]: (state: any, event: any) => {
    const { payload } = event
    const { name } = payload
    return { ...state, name }
  },

  [Company_Activity_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { active } = payload
    return { ...state, active }
  },

  [Company_Superagent_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { superagent } = payload
    return { ...state, superagent }
  },

  [Company_Employment_Created]: (state: any, event: any) => {
    const { payload } = event
    const { userId } = payload
    const result = { ...state }
    if (!hasValue(result.employments)) {
      result.employments = []
    }
    if (hasValue(userId)) {
      result.employments.push({ userId })
    }
    return result
  },

  [Company_Employment_Removed]: (state: any, event: any) => {
    const { payload } = event
    const { userId } = payload
    const result = { ...state }
    if (hasValue(result.employments) && hasValue(userId)) {
      result.employments = result.employments.filter((employment: any) => {
        return employment.userId !== userId
      })
    }
    return result
  },

  [Company_Employment_Role_Added]: (state: any, event: any) => {
    const { payload } = event
    const { userId, roleId } = payload
    const result = { ...state }
    if (!hasValue(result.employments)) {
      result.employments = []
    }
    let employment = result.employments.find((employment: any) => employment.userId === userId)
    if (!hasValue(employment)) {
      employment = { userId }
      result.employments.push(employment)
    }
    employment[roleId] = true
    return result
  },

  [Company_Employment_Role_Removed]: (state: any, event: any) => {
    const { payload } = event
    const { userId, roleId } = payload
    const result = { ...state }
    if (!hasValue(result.employments)) {
      result.employments = []
    }
    const employment = result.employments.find((employment: any) => employment.userId === userId)
    if (hasValue(employment)) {
      employment[roleId] = false
    }
    return result
  },
}

export default companyViewModel
