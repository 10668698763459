import { hasValue } from '../../utils/utils'
import { ticketStates } from '../../common/ticketStates'

export const accessRights = {
  tickets: {
    editing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        if (ticket.state === ticketStates.completed.id || ticket.state === ticketStates.confirmed.id) {
          result = (hasValue(userRole.superagent) && userRole.superagent) || (hasValue(userRole.superadmin) && userRole.superadmin)
        } else {
          result = true
        }
      }
      return result
    },
    subjectEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result =
          (userRole.agent && userRole.companyId === ticket.agentId) ||
          (userRole.client && userRole.companyId === ticket.clientId) ||
          userRole.superadmin
      }
      return hasValue(result) && result
    },
    clientEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.agent && userRole.companyId === ticket.agentId) || userRole.superadmin
      }
      return hasValue(result) && result
    },
    contactEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.agent && userRole.companyId === ticket.agentId) || userRole.superadmin
      }
      return hasValue(result) && result
    },
    agentEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.agent && userRole.companySuperagent) || userRole.superadmin
      }
      return hasValue(result) && result
    },
    engineerEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.superagent && userRole.companyId === ticket.agentId) || userRole.superadmin
      }
      return hasValue(result) && result
    },
    slaEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.agent && userRole.companyId === ticket.agentId) || userRole.superadmin
      }
      return hasValue(result) && result
    },
    typeEditing: (userRole: any, ticket: any) => {
      let result = false
      if (hasValue(userRole) && hasValue(ticket)) {
        result = (userRole.agent && userRole.companyId === ticket.agentId) || userRole.superadmin
      }
      return hasValue(result) && result
    },
  },
}
