import React, { PureComponent } from 'react'

import { LoginPanel } from '../../ui'
import { View } from '../../ui/view/View'

export default class LoginView extends PureComponent {
  constructor(props) {
    super(props)
    this.formRef = null
    this.state = { email: '', password: '' }
  }

  getFormRef = (formRef) => {
    this.formRef = formRef
  }

  onEmailChange = (value) => {
    this.setState({ email: value })
  }

  onPasswordChange = (value) => {
    this.setState({ password: value })
  }

  onLoginButtonClick = () => {
    this.formRef.submit()
  }

  getAlternativeLogins = () => {
    const { l } = this.props
    return [
      { url: '/api/auth/google/login', text: l.t('LoginView.LoginWithGoogle') },
      { url: '/magic-link-login', text: l.t('LoginView.LoginWithMagicLink') },
    ]
  }

  render() {
    const { errorText, l } = this.props
    const { email, password } = this.state
    return (
      <React.Fragment>
        <form method={'GET'} ref={this.getFormRef}>
          <View>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <LoginPanel
                title={l.t('LoginView.Title')}
                emailTitle={l.t('LoginView.Email')}
                passwordTitle={l.t('LoginView.Password')}
                loginTitle={l.t('LoginView.Login')}
                orTitle={l.t('LoginView.Or')}
                email={email}
                password={password}
                errorText={errorText}
                formAction={'/api/auth/local/login'}
                alternativeLogins={this.getAlternativeLogins()}
                onEmailChange={this.onEmailChange}
                onPasswordChange={this.onPasswordChange}
                onLoginButtonClick={this.onLoginButtonClick}
              />
            </div>
          </View>
        </form>
      </React.Fragment>
    )
  }
}
