import React, { useState, useRef } from 'react'

import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Row } from '../../ui'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'

const MagicLinkRegisterView = props => {
  const { l } = props
  const [name, setName] = useState('')
  const [checked, setChecked] = useState(false)

  const formRef = useRef(null)

  const onNameChange = value => {
    setName(value)
  }

  const onButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  return (
    <View>
      <ViewHeader text={l.t('MagicLinkRegisterView.Title')} />
      <Row style={{ alignItems: 'flex-start' }}>
        <form action={'/api/auth/email/register'} method={'POST'} ref={formRef}>
          <TextEditor
            caption={l.t('MagicLinkRegisterView.Name')}
            name={'name'}
            value={name}
            placeholder={l.t('MagicLinkRegisterView.NamePlaceholder')}
            autoFocus={true}
            onChange={onNameChange}
          />
          <BooleanEditor value={checked} onChange={setChecked} caption={l.t('MagicLinkRegisterView.AgreementConfirmation')} />
          <Button
            variant={'small'}
            kind={buttonKind.primary}
            title={l.t('MagicLinkRegisterView.Login')}
            type={'submit'}
            onClick={onButtonClick}
            disabled={!checked}
          />
        </form>
      </Row>
    </View>
  )
}

export default MagicLinkRegisterView
