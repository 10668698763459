import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { usePermissionJwt, useClientQuery } from '../hooks'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'

const pageSizeKey = 'CompanyCfgItemsView.PageSize'

export class CompanyCfgItemsView extends PureComponent {
  onCreateButtonClick = () => {
    const { companyId, history } = this.props
    history.push(`/company/${companyId}/cfgItemCreating`)
  }

  onActivityTitleGet = cfgItem => {
    const { l } = this.props
    return cfgItem.active ? l.t('CfgItems.Actions.Pause') : l.t('CfgItems.Actions.Resume')
  }

  onActivityButtonClick = async cfgItem => {
    const { cfgItemPause, cfgItemResume, l } = this.props
    if (cfgItem.active) {
      if (confirm(l.t('CfgItems.Actions.DeactivateConfirmation'))) {
        await executeCommand(cfgItemPause, { cfgItemId: cfgItem.id })
      }
    } else {
      if (confirm(l.t('CfgItems.Actions.ActivateConfirmation'))) {
        await executeCommand(cfgItemResume, { cfgItemId: cfgItem.id })
      }
    }
  }

  onRemoveButtonClick = async cfgItem => {
    const { cfgItemRemove } = this.props
    if (confirm(`Do you really want to remove the "${cfgItem.name}" configuration item?`)) {
      await executeCommand(cfgItemRemove, { cfgItemId: cfgItem.id })
    }
  }

  renderButtons = () => {
    const { l } = this.props
    return (
      <ButtonsRow>
        <Button id={'createCfgItem'} variant={'small'} title={l.t('CfgItems.Actions.Create')} onClick={this.onCreateButtonClick} />
      </ButtonsRow>
    )
  }

  renderPager = () => {
    const { userRole, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, companyId, l } = this.props
    return (
      <Pager
        id={'companyCfgItemsPager'}
        resolverName={'cfgItemsForCompanyCount'}
        resolverArgs={{ userRoleString: JSON.stringify(userRole), companyId }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { companyId, cfgItems, visible, l } = this.props
    return visible ? (
      <View>
        <ViewHeader text={l.t('CfgItems.CfgItems')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          data={cfgItems}
          columnSettings={[
            { title: l.t('CfgItems.Name'), fieldName: 'name', width: '55%', important: true },
            { title: l.t('CfgItems.Active'), fieldName: 'active', type: 'boolean', width: '15%', important: true },
            {
              title: '',
              buttonTitle: this.onActivityTitleGet,
              type: 'button',
              onClick: this.onActivityButtonClick,
              width: 'auto',
            },
            {
              title: '',
              buttonTitle: l.t('CfgItems.Actions.Remove'),
              type: 'button',
              onClick: this.onRemoveButtonClick,
              width: 'auto',
            },
          ]}
          onGetItemUrl={cfgItem => `/company/${companyId}/cfgItem/${cfgItem.id}`}
        />
        {this.renderPager()}
      </View>
    ) : null
  }
}

const CompanyCfgItemsViewContainer = props => {
  const { userRole, companyId } = props
  const resolverName = 'cfgItemsForCompany'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const permissionJwt = usePermissionJwt('cfgItemCommandsPermission', { companyId })
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    companyId,
    sortingFieldName: 'name',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  const cfgItemPause = useCommandBuilder(({ cfgItemId }) => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'pause', payload: { permissionJwt } }
  })
  const cfgItemResume = useCommandBuilder(({ cfgItemId }) => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'resume', payload: { permissionJwt } }
  })
  const cfgItemRemove = useCommandBuilder(({ cfgItemId }) => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'remove', payload: { permissionJwt } }
  })

  return (
    <CompanyCfgItemsView
      {...props}
      cfgItems={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
      cfgItemPause={cfgItemPause}
      cfgItemResume={cfgItemResume}
      cfgItemRemove={cfgItemRemove}
    />
  )
}

export default CompanyCfgItemsViewContainer
