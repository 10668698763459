import React, { useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../utils/utils'
import { Pager } from '../ui'
import { useClientQuery } from './hooks'

const defaultPageSizeKey = 'PageSize'
const defaultPageSize = 10

export const readPageSizeFromStorage = (key = defaultPageSizeKey) => {
  const result = parseInt(window.localStorage.getItem(key))
  return hasValue(result) && !isNaN(result) ? result : defaultPageSize
}

export const writePageSizeToStorage = (pageSize, key = defaultPageSizeKey) => {
  window.localStorage.setItem(key, pageSize)
}

const PagerContainer = props => {
  const { id, resolverName, resolverArgs = {}, currentPageIndex, pageSize = defaultPageSize, l, onCurrentPageIndexChange, onPageSizeChange } = props
  const client = useClient()

  const calculatePageCount = (objectCount, pageSize) => {
    let result = Math.ceil(objectCount / pageSize)
    if (result <= 0) {
      result = 1
    }
    return result
  }

  const objectCount = useClientQuery(client, 0, 'Saturn', resolverName, resolverArgs)

  useEffect(() => {
    if (hasValue(onCurrentPageIndexChange)) {
      const pageCount = calculatePageCount(objectCount, pageSize)
      if (currentPageIndex > pageCount - 1) {
        onCurrentPageIndexChange(pageCount - 1)
      }
    }
  }, [objectCount, pageSize, currentPageIndex])

  const onPagerCurrentPageIndexChange = useCallback(newCurrentPageIndex => {
    if (hasValue(onCurrentPageIndexChange)) {
      onCurrentPageIndexChange(newCurrentPageIndex)
    }
  }, [])
  const onPagerPageSizeChange = useCallback(
    newPageSize => {
      if (hasValue(onPageSizeChange)) {
        const pageCount = calculatePageCount(objectCount, newPageSize)
        const correctedCurrentPageIndex = currentPageIndex > pageCount - 1 ? pageCount - 1 : null
        onPageSizeChange(newPageSize, correctedCurrentPageIndex)
      }
    },
    [objectCount, currentPageIndex]
  )

  return (
    <Pager
      id={id}
      currentPageIndex={currentPageIndex}
      pageCount={calculatePageCount(objectCount, pageSize)}
      pageSize={pageSize}
      previousLabel={l.t('Pager.Previous')}
      nextLabel={l.t('Pager.Next')}
      pageSizeLabel={l.t('Pager.PageSize')}
      onCurrentPageIndexChange={onPagerCurrentPageIndexChange}
      onPageSizeChange={onPagerPageSizeChange}
    />
  )
}

export default PagerContainer
