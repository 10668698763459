import React, { PureComponent, Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useViewModel, useClient, useCommand, useCommandBuilder } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'
import { Panel, PanelColumn } from '../../ui'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { TextField } from '../../ui/textField/TextField'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { ChangingButtons } from '../../ui/buttons/ChangingButtons'
import { useClientQuery } from '../hooks'

export class SlaView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { changing: false }
  }

  onChangeButtonClick = () => {
    const { sla } = this.props
    this.setState({
      changing: true,
      service: sla.service,
      termFrom: sla.termFrom,
      termTo: sla.termTo,
    })
  }

  onSaveButtonClick = async () => {
    const { sla, slaUpdate } = this.props
    const { service, termFrom, termTo } = this.state
    const changes = {}
    if (service !== sla.service) {
      changes.service = service
    }
    if (termFrom !== sla.termFrom) {
      changes.termFrom = termFrom
    }
    if (termTo !== sla.termTo) {
      changes.termTo = termTo
    }
    await executeCommand(slaUpdate, changes)
    this.setState({ changing: false })
  }

  onCancelButtonClick = () => {
    this.setState({ changing: false })
  }

  onSubjectChange = async value => {
    const { slaUpdate } = this.props
    await executeCommand(slaUpdate, { subject: value })
  }

  onServiceChange = async value => {
    this.setState({ service: value })
  }

  onTermFromChange = async value => {
    this.setState({ termFrom: value })
  }

  onTermToChange = async value => {
    this.setState({ termTo: value })
  }

  onActiveChange = async value => {
    const { slaActivate, slaDeactivate } = this.props
    if (value) {
      if (confirm(`Do you want to activate the SLA?`)) {
        await executeCommand(slaActivate, {})
      }
    } else {
      if (confirm(`Do you want to deactivate the SLA?`)) {
        await executeCommand(slaDeactivate, {})
      }
    }
  }

  render() {
    const { sla: propsSla, l } = this.props
    const { changing, service, termFrom, termTo } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Slas.Sla')} />
        <MobileIndent>
          <AdvancedText
            id={'subject'}
            caption={l.t('Slas.Subject')}
            value={propsSla.subject}
            allowChanging={true}
            style={{ marginBottom: '8px' }}
            l={l}
            onChange={this.onSubjectChange}
          />
        </MobileIndent>
        <Panel>
          <PanelColumn>
            <TextField id={'createdOn'} caption={l.t('Slas.CreatedOn')} value={getLocalDateTimeString(propsSla.createdOn)} />
            <TextField id={'agentName'} caption={l.t('Slas.Agent')} value={propsSla.agentName} />
            <TextField id={'clientName'} caption={l.t('Slas.Client')} value={propsSla.clientName} />
            <BooleanEditor id={'active'} caption={l.t('Slas.Active')} value={propsSla.active} onChange={this.onActiveChange} />
          </PanelColumn>
          <PanelColumn>
            {changing ? (
              <Fragment>
                <TextEditor id={'service'} caption={l.t('Slas.Service')} value={service} autoFocus={true} onChange={this.onServiceChange} />
                <TextEditor id={'termFrom'} type={'date'} caption={l.t('Slas.From')} value={termFrom} onChange={this.onTermFromChange} />
                <TextEditor id={'termTo'} type={'date'} caption={l.t('Slas.To')} value={termTo} onChange={this.onTermToChange} />
              </Fragment>
            ) : (
              <Fragment>
                <TextField id={'service'} caption={l.t('Slas.Service')} value={propsSla.service} />
                <TextField id={'termFrom'} caption={l.t('Slas.From')} value={getLocalDateTimeString(propsSla.termFrom)} />
                <TextField id={'termTo'} caption={l.t('Slas.To')} value={getLocalDateTimeString(propsSla.termTo)} />
              </Fragment>
            )}
            <ChangingButtons
              changing={changing}
              l={l}
              onChangeButtonClick={this.onChangeButtonClick}
              onSaveButtonClick={this.onSaveButtonClick}
              onCancelButtonClick={this.onCancelButtonClick}
            />
          </PanelColumn>
        </Panel>
      </View>
    )
  }
}

const SlaViewContainer = props => {
  const { userRole } = props
  const viewModelName = 'SlaVm'
  const resolverName = 'slaById'
  const { slaId } = useParams()
  const userRoleString = JSON.stringify(userRole)

  // Read model.
  const client = useClient()
  const object1 = useClientQuery(client, {}, 'Saturn', resolverName, { userRoleString, slaId })

  // View model.
  const [object2, setObject2] = useState({})
  const { connect, dispose } = useViewModel(viewModelName, [slaId], setObject2)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])

  const slaUpdate = useCommandBuilder(changes => {
    return { aggregateName: 'Sla', aggregateId: slaId, type: 'update', payload: changes }
  })
  const slaActivate = useCommand({ aggregateName: 'Sla', aggregateId: slaId, type: 'activate' })
  const slaDeactivate = useCommand({ aggregateName: 'Sla', aggregateId: slaId, type: 'deactivate' })
  return (
    <SlaView
      {...props}
      slaId={slaId}
      sla={{ ...object1, ...object2 }}
      slaUpdate={slaUpdate}
      slaActivate={slaActivate}
      slaDeactivate={slaDeactivate}
    />
  )
}

export default SlaViewContainer
