import React, { PureComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'
import { useClientQuery } from '../hooks'

export class GroupView extends PureComponent {
  onNameChange = async value => {
    const { groupRename, groupId } = this.props
    await executeCommand(groupRename, { groupId, name: value })
  }

  render() {
    const { group, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('Groups.Group')} />
        <MobileIndent>
          <AdvancedText
            id={'name'}
            caption={l.t('Groups.Name')}
            value={group.name}
            allowChanging={true}
            style={{ marginBottom: '8px' }}
            l={l}
            onChange={this.onNameChange}
          />
        </MobileIndent>
      </View>
    )
  }
}

const GroupViewContainer = props => {
  const resolverName = 'groupById'
  const { groupId } = useParams()

  // Read model.
  const client = useClient()
  const object = useClientQuery(client, {}, 'Saturn', resolverName, { groupId })

  const groupRename = useCommandBuilder(({ groupId, name }) => {
    return { aggregateName: 'Company', aggregateId: object.companyId, type: 'renameGroup', payload: { groupId, name } }
  })
  return <GroupView {...props} groupId={groupId} group={object} groupRename={groupRename} />
}

export default GroupViewContainer
