import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import LookupEditor from '../lookup/LookupEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'

export class SlaCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { subject: '', agentId: null, clienId: null, service: '', termFrom: null, termTo: null, active: false }
  }

  onSubjectChange = value => {
    this.setState({ subject: value })
  }

  onAgentIdChange = value => {
    this.setState({ agentId: value })
  }

  onClientIdChange = value => {
    this.setState({ clientId: value })
  }

  onServiceChange = value => {
    this.setState({ service: value })
  }

  onTermFromChange = value => {
    this.setState({ termFrom: value })
  }

  onTermToChange = value => {
    this.setState({ termTo: value })
  }

  onActiveChange = value => {
    this.setState({ active: value })
  }

  onCreateButtonClick = async () => {
    const { history, slaCreate } = this.props
    const { subject, agentId, clientId, service, termFrom, termTo, active } = this.state
    const slaId = v4()
    await executeCommand(slaCreate, { slaId, subject, agentId, clientId, service, termFrom, termTo, active })
    history.goBack()
  }

  render() {
    const { l } = this.props
    const { subject, agentId, clientId, service, termFrom, termTo, active } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Slas.Sla')} />
        <Panel>
          <PanelColumn>
            <TextEditor id={'subject'} caption={l.t('Slas.Subject')} value={subject} autoFocus={true} onChange={this.onSubjectChange} />
            <LookupEditor
              id={'agentId'}
              caption={'Agent'}
              resolverName={'simpleCompanies'}
              valueFieldName={'id'}
              textFieldName={'name'}
              value={agentId}
              onChange={this.onAgentIdChange}
            />
            <LookupEditor
              id={'clientId'}
              caption={l.t('Slas.Client')}
              resolverName={'simpleCompanies'}
              valueFieldName={'id'}
              textFieldName={'name'}
              value={clientId}
              onChange={this.onClientIdChange}
            />
            <BooleanEditor id={'active'} caption={l.t('Slas.Active')} value={active} onChange={this.onActiveChange} />
          </PanelColumn>
          <PanelColumn>
            <TextEditor id={'service'} caption={l.t('Slas.Service')} value={service} onChange={this.onServiceChange} />
            <TextEditor id={'termFrom'} type={'date'} caption={l.t('Slas.From')} value={termFrom} onChange={this.onTermFromChange} />
            <TextEditor id={'termTo'} type={'date'} caption={l.t('Slas.To')} value={termTo} onChange={this.onTermToChange} />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button kind={buttonKind.primary} variant={'small'} title={l.t('Slas.Actions.Create')} onClick={this.onCreateButtonClick} />
        </Row>
      </View>
    )
  }
}

const SlaCreatingViewContainer = props => {
  const slaCreate = useCommandBuilder(({ slaId, subject, agentId, clientId, service, termFrom, termTo, active }) => {
    return {
      aggregateName: 'Sla',
      aggregateId: slaId,
      type: 'create',
      payload: { subject, agentId, clientId, service, termFrom, termTo, active },
    }
  })
  return <SlaCreatingView {...props} slaCreate={slaCreate} />
}

export default SlaCreatingViewContainer
