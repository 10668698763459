import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Label, Panel, PanelColumn, Row } from '../../ui'

export class TicketTypeCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { name: '', color: 'rgb(125, 125, 125)' }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onColorChange = value => {
    this.setState({ color: value })
  }

  onCreateButtonClick = async () => {
    const { history, ticketTypeCreate } = this.props
    const { name, color } = this.state
    const ticketTypeId = v4()
    await executeCommand(ticketTypeCreate, { ticketTypeId, name, color })
    history.goBack()
  }

  render() {
    const { l } = this.props
    const { name, color } = this.state
    return (
      <View>
        <ViewHeader text={l.t('TicketTypes.TicketType')} />
        <Panel>
          <PanelColumn>
            <TextEditor id={'name'} caption={l.t('TicketTypes.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
            <TextEditor id={'color'} caption={l.t('TicketTypes.Color')} value={color} style={{ flex: 1 }} onChange={this.onColorChange} />
            <Label id={'label'} color={color}>
              {hasValue(name) && name !== '' ? name : l.t('TicketTypes.TicketType')}
            </Label>
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button kind={buttonKind.primary} variant={'small'} title={l.t('TicketTypes.Actions.Create')} onClick={this.onCreateButtonClick} />
        </Row>
      </View>
    )
  }
}

const TicketTypeCreatingViewContainer = props => {
  const ticketTypeCreate = useCommandBuilder(({ ticketTypeId, name, color }) => {
    return {
      aggregateName: 'TicketType',
      aggregateId: ticketTypeId,
      type: 'create',
      payload: { name, color },
    }
  })
  return <TicketTypeCreatingView {...props} ticketTypeCreate={ticketTypeCreate} />
}

export default TicketTypeCreatingViewContainer
