export const employmentRoles = {
  agent: {
    id: 'agent',
    name: 'Agent',
  },
  superagent: {
    id: 'superagent',
    name: 'Superagent',
  },
  client: {
    id: 'client',
    name: 'Client',
  },
  superclient: {
    id: 'superclient',
    name: 'Superclient',
  },
}
