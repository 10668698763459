import jsonwebtoken from 'jsonwebtoken'
import React from 'react'
import { render } from 'react-dom'
import { createBrowserHistory } from 'history'
import { createResolveStore, ResolveReduxProvider } from '@resolve-js/redux'
import { devToolsEnhancer } from 'redux-devtools-extension'

import { hasValue } from '../utils/utils'
import { getCookieValue } from './utils'
import { App } from './components/App'

const entryPoint = (props) => {
  const { rootPath, jwtCookie } = props

  const redux = { enhancers: [devToolsEnhancer()] }
  const history = createBrowserHistory({ basename: rootPath })

  let jwt = {}
  try {
    const jwtFromCookie = getCookieValue(jwtCookie.name)
    if (hasValue(jwtFromCookie)) {
      jwt = jwtFromCookie
    }
  } catch (e) {}

  const store = createResolveStore(props, { redux })

  const appContainer = document.createElement('div')
  appContainer.className = 'app-container'
  document.body.appendChild(appContainer)

  render(
    <ResolveReduxProvider context={props} store={store}>
      <App history={history} jwtUser={jsonwebtoken.decode(jwt)} />
    </ResolveReduxProvider>,
    appContainer
  )
}

export default entryPoint
