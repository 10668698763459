import React, { Fragment, PureComponent, useCallback, useState } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { combineConditionsByAnd, getLocalDateTimeString, hasValue, isNull } from '../../../utils/utils'
import {
  findCondition,
  findSorting,
  getCompaniesSortings,
  getCompanyActiveFilters,
  getFiltersPanelItem,
  readValueFromStorage,
  writeValueToStorage,
} from '../../utils'
import { MobileIndent, View } from '../../ui/view/View'
import { FiltersPanel, NewList } from '../../ui'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'CompaniesView.PageSize'
const activeFilterIdKey = 'CompaniesView.ActiveFilterID'
const sortingIdKey = 'CompaniesView.SortingID'

const activeFilters = getCompanyActiveFilters()
const sortings = getCompaniesSortings()

export const getCompaniesConditionString = (jwtUserId, activeFilterId) => {
  const conditionParts = []
  conditionParts.push(findCondition(activeFilters, activeFilterId, null, null, null))
  return JSON.stringify(combineConditionsByAnd(conditionParts))
}

export class CompaniesList extends PureComponent {
  constructor(props) {
    super(props)
    this.timeoutId = null
    this.state = { filterText: null }
  }

  onFilterTextInputChange = value => {
    this.setState({ filterText: value })
  }

  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/companyCreating')
  }

  renderFiltersPanel = () => {
    const { jwtUser, activeFilterId, sortingId, l, onActiveFilterChange, onSortingChange } = this.props
    const items = []
    // Active filter.
    items.push(getFiltersPanelItem(activeFilters, activeFilterId, null, null, 'Companies', 'Activity', l, onActiveFilterChange))
    // Sorting.
    items.push(getFiltersPanelItem(sortings, sortingId, null, null, 'Companies', 'Sorting', l, onSortingChange))
    return items.length > 0 ? (
      <MobileIndent>
        <FiltersPanel items={items} mb={3} />
      </MobileIndent>
    ) : null
  }

  renderPager = () => {
    const { jwtUser, userRole, filterText, activeFilterId, currentPageIndex, pageSize, l, onCurrentPageIndexChange, onPageSizeChange } = this.props
    return (
      <Pager
        id={'companiesPager'}
        resolverName={'companiesCount'}
        resolverArgs={{
          userRoleString: JSON.stringify(userRole),
          conditionString: getCompaniesConditionString(jwtUser.id, activeFilterId),
          filterText,
        }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  getActiveFilterMenuItems = () => {
    const { activeFilterId, onActiveFilterChange, l } = this.props
    const result = []
    activeFilters.forEach(filter => {
      result.push({
        text: l.t('Companies.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === activeFilterId || (isNull(activeFilterId) && filter.default),
        onClick: () => onActiveFilterChange(filter.id),
      })
    })
    return result
  }

  getSortingMenuItems = () => {
    const { sortingId, onSortingChange, l } = this.props
    const result = []
    sortings.forEach(sorting => {
      result.push({
        text: l.t('Companies.Toolbar.' + sorting.text.replace(' ', '')),
        selected: sorting.id === sortingId || (isNull(sortingId) && sorting.default),
        onClick: () => onSortingChange(sorting.id),
      })
    })
    return result
  }

  getToolbarSettings = () => {
    const { l } = this.props
    return {
      menus: [
        {
          id: 'activityFilter',
          title: l.t('Companies.Toolbar.Activity'),
          dropdownTitle: l.t('Companies.Toolbar.FilterByActive'),
          dropdownFilter: l.t('Companies.Toolbar.Filter'),
          items: this.getActiveFilterMenuItems(),
        },
        {
          id: 'sorting',
          title: l.t('Companies.Toolbar.Sorting'),
          dropdownTitle: l.t('Companies.Toolbar.SortBy'),
          dropdownFilter: l.t('Companies.Toolbar.Filter'),
          items: this.getSortingMenuItems(),
        },
      ],
    }
  }

  getItems = () => {
    const { companies, l } = this.props
    return Array.isArray(companies)
      ? companies.map(company => {
          const labels = []
          if (company.active) {
            labels.push({ id: 'active', text: l.t('Companies.Active') })
          }
          return {
            subject: company.name,
            url: `/company/${company.id}`,
            labels: labels,
            creationInfo: {
              authorName: company.authorName,
              authorUrl: `/user/${company.authorId}`,
              details: l.t('Companies.CreatedOn').replace('{datetime}', getLocalDateTimeString(company.createdOn)),
            },
          }
        })
      : null
  }

  render() {
    return (
      <Fragment>
        {this.renderFiltersPanel()}
        <NewList toolbarSettings={this.getToolbarSettings()} items={this.getItems()} />
        {this.renderPager()}
      </Fragment>
    )
  }
}

const CompaniesListContainer = props => {
  const resolverName = 'companies'
  const { jwtUser, userRole, filterText } = props
  const [activeFilterId, setActiveFilterId] = useState(readValueFromStorage(activeFilterIdKey))
  const [sortingId, setSortingId] = useState(readValueFromStorage(sortingIdKey))
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const sorting = findSorting(sortings, sortingId)
  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    conditionString: getCompaniesConditionString(jwtUser.id, activeFilterId),
    filterText,
    sortingFieldName: sorting.fieldName,
    sortingDirection: sorting.direction,
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onActiveFilterChange = useCallback(value => {
    writeValueToStorage(activeFilterIdKey, value)
    setActiveFilterId(value)
  }, [])
  const onSortingChange = useCallback(value => {
    writeValueToStorage(sortingIdKey, value)
    setSortingId(value)
  }, [])

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <CompaniesList
      {...props}
      companies={objects}
      activeFilterId={activeFilterId}
      sortingId={sortingId}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onActiveFilterChange={onActiveFilterChange}
      onSortingChange={onSortingChange}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default CompaniesListContainer
