import React from 'react'
import { Text as LibText } from '@primer/react'

export const Text = props => {
  const { id, color, fontSize, fontWeight, ml, children } = props
  return (
    <LibText id={id} sx={{ color, fontSize, fontWeight, ml }}>
      {children}
    </LibText>
  )
}
