import React from 'react'
import { Box, ButtonInvisible, Text } from '@primer/react'
import { XIcon } from '@primer/octicons-react'
import { hasValue } from '../utils'

const ClearButton = props => {
  const { onClick, children } = props
  return (
    <ButtonInvisible
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'fg.muted',
        bg: 'transparent',
        '> div': {
          color: 'fg.onEmphasis',
          bg: 'fg.muted',
        },
        '&:hover': {
          color: 'accent.fg',
          bg: 'transparent',
          '> div': {
            color: 'fg.onEmphasis',
            bg: 'accent.fg',
          },
        },
        px: 0,
        py: 0,
        ml: 0,
        mr: 4,
        mb: 1,
      }}
      children={children}
      onClick={onClick}
    />
  )
}

export const FiltersPanel = props => {
  const { items, mb } = props
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mb: mb }}>
      {Array.isArray(items)
        ? items.map((item, index) => {
            if (hasValue(item)) {
              return (
                <ClearButton key={index} onClick={item.onClick}>
                  <Box
                    sx={{
                      display: 'flex',
                      size: 20,
                      borderRadius: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <XIcon size={16} />
                  </Box>
                  <Text sx={{ ml: 1 }}>{item.text}</Text>
                </ClearButton>
              )
            }
          })
        : null}
    </Box>
  )
}
