import React, { createRef, PureComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useClient, useCommand, useCommandBuilder, useViewModel } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue } from '../../../../utils/utils'
import { ticketActivityTypes } from '../../../../common/ticketActivityTypes'
import { executeCommand } from '../../../utils'
import { ticketStates } from '../../../../common/ticketStates'
import { accessRights } from '../../accessRights'
import { TicketActions } from './TicketActions'
import { ActivitiesList, Box, Button, ButtonsRow, Label, Link, Text } from '../../../ui'
import { View, ViewHeader, MobileIndent } from '../../../ui/view/View'
import { AdvancedTextInput } from '../../../ui/textInput/AdvancedTextInput'
import { TicketFields } from './TicketFields'
import { TicketReportsList } from './TicketReportsList'
import { useClientQuery } from '../../hooks'

export class TicketView extends PureComponent {
  constructor(props) {
    super(props)
    this.actionsRef = createRef()
    this.state = { subjectChanging: false }
  }

  onEditSubjectButtonClick = () => {
    this.setState({ subjectChanging: true })
  }

  onSubjectTextInputSave = async (value, changed) => {
    const { ticketChangeSubject } = this.props
    if (changed) {
      await executeCommand(ticketChangeSubject, value)
    }
    this.setState({ subjectChanging: false })
  }

  onSubjectTextInputCancel = () => {
    this.setState({ subjectChanging: false })
  }

  onActivitiesListItemButtonClick = activityItem => {
    const lines = activityItem.description.split('\n')
    const quotedDescription = '> ' + lines.join('\n> ') + '\n'
    this.actionsRef.current.executeReportAction(quotedDescription)
  }

  renderCreationInfo = (authorId, authorName, createdOn, ticketActivitiesCount) => {
    const { l } = this.props
    return (
      <Box display={'block'}>
        <Link url={`/user/${authorId}`} color={'fg.muted'} fontSize={1} fontWeight={'bold'}>
          {authorName}
        </Link>
        <Text id={'details'} color={'fg.muted'} fontSize={1} fontWeight={'normal'} ml={1}>
          {l.t('Tickets.CreatedOn').replace('{datetime}', getLocalDateTimeString(createdOn))}
        </Text>
        <Text color={'fg.muted'} fontSize={1} fontWeight={'normal'} ml={1}>
          {'· ' + l.t('Tickets.CommentsCount').replace('{count}', ticketActivitiesCount)}
        </Text>
      </Box>
    )
  }

  renderHeaderContent = () => {
    const {
      userRole,
      ticketId,
      ticket,
      history,
      ticketActivities,
      ticketRefuse,
      ticketReturn,
      ticketStart,
      ticketPause,
      ticketResume,
      ticketCreateReport,
      ticketConfirm,
      l,
    } = this.props
    const { subjectChanging } = this.state
    return (
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {subjectChanging ? (
          <AdvancedTextInput
            id={'subject'}
            value={ticket.subject}
            l={l}
            onSave={this.onSubjectTextInputSave}
            onCancel={this.onSubjectTextInputCancel}
          />
        ) : (
          <Box
            display={'flex'}
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent={'space-between'}
          >
            <span id={'subject'}>{ticket.subject}</span>
            <ButtonsRow>
              {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.subjectEditing(userRole, ticket) ? (
                <Button id={'edit'} variant={'small'} title={l.t('Tickets.Actions.Edit')} onClick={this.onEditSubjectButtonClick} />
              ) : null}
              <TicketActions
                ref={this.actionsRef}
                userRole={userRole}
                ticketId={ticketId}
                ticket={ticket}
                history={history}
                ticketRefuse={ticketRefuse}
                ticketReturn={ticketReturn}
                ticketStart={ticketStart}
                ticketPause={ticketPause}
                ticketResume={ticketResume}
                ticketCreateReport={ticketCreateReport}
                ticketConfirm={ticketConfirm}
                l={l}
              />
            </ButtonsRow>
          </Box>
        )}
        <Box display={'flex'} flexDirection={'row'} alignItems={'base-line'} flexWrap={'wrap'} mt={2}>
          <Label id={'state'} variant={'xl'} color={hasValue(ticket.state) ? ticketStates[ticket.state].color : null} mr={2}>
            {hasValue(ticket.state) ? l.t('Tickets.States.' + ticketStates[ticket.state].name.replace(' ', '')) : null}
          </Label>
          {this.renderCreationInfo(ticket.authorId, ticket.authorName, ticket.createdOn, hasValue(ticketActivities) ? ticketActivities.length : 0)}
        </Box>
      </Box>
    )
  }

  render() {
    const {
      userRole,
      ticketId,
      ticket,
      ticketActivities,
      ticketChangeClient,
      ticketChangeContact,
      ticketChangeAgent,
      ticketAssign,
      ticketChangeSla,
      ticketChangeType,
      l,
      languageId,
    } = this.props
    return (
      <View>
        <ViewHeader content={this.renderHeaderContent()} />
        <MobileIndent>
          <Box display={'flex'} flexDirection={['column', 'column', 'row']}>
            <Box display={'flex'} flexDirection={'column'} width={['100%', '100%', '75%']}>
              {hasValue(ticketActivities) ? (
                <ActivitiesList items={ticketActivities} languageId={languageId} onItemButtonClick={this.onActivitiesListItemButtonClick} />
              ) : null}
            </Box>
            <Box display={'flex'} flexDirection={'column'} width={['100%', '100%', '25%']} ml={[0, 0, 4]} mt={[4, 4, 0]}>
              <TicketFields
                userRole={userRole}
                ticket={ticket}
                ticketChangeClient={ticketChangeClient}
                ticketChangeContact={ticketChangeContact}
                ticketChangeAgent={ticketChangeAgent}
                ticketAssign={ticketAssign}
                ticketChangeSla={ticketChangeSla}
                ticketChangeType={ticketChangeType}
                l={l}
                languageId={languageId}
              />
              <TicketReportsList ticketActivities={ticketActivities} mt={4} l={l} languageId={languageId} />
            </Box>
          </Box>
        </MobileIndent>
      </View>
    )
  }
}

const TicketViewContainer = props => {
  const { userRole, l, languageId } = props
  const ticketResolverName = 'ticketById'
  const ticketViewModelName = 'TicketVm'
  const ticketActivitiesResolverName = 'ticketActivities'
  const { ticketId } = useParams()
  const userRoleString = JSON.stringify(userRole)

  // Ticket read model.
  const client1 = useClient()
  const ticketReadModel = useClientQuery(client1, {}, 'Saturn', ticketResolverName, { userRoleString, ticketId })

  // Ticket view model.
  const [ticketViewModel, setTicketViewModel] = useState({})
  const { connect, dispose } = useViewModel(ticketViewModelName, [ticketId], setTicketViewModel, { userRoleString })
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [userRoleString])

  // Ticket activities read model.
  const [objects3, setObjects3] = useState([])
  const client2 = useClient()
  useEffect(() => {
    client2.query(
      {
        name: 'Saturn',
        resolver: ticketActivitiesResolverName,
        args: { userRoleString, ticketId, sortingDirection: 1 },
      },
      (error, result) => {
        if (hasValue(result)) {
          let ticketActivities = []
          if (Array.isArray(result.data)) {
            ticketActivities = result.data.map(obj => {
              const allowButton =
                obj.type === ticketActivityTypes.commented.id &&
                ticketViewModel.state !== ticketStates.completed.id &&
                ticketViewModel.state !== ticketStates.confirmed.id &&
                hasValue(userRole) &&
                (userRole.superadmin || (userRole.agent && userRole.companyId === ticketViewModel.agentId))
              return {
                ...obj,
                authorUrl: `/user/${obj.authorId}`,
                details:
                  l.t('Tickets.Activities.' + obj.type).replace('{data}', obj.data) +
                  ' ' +
                  l.t('Tickets.Activities.On') +
                  getLocalDateTimeString(obj.modifiedOn),
                button: allowButton ? { title: l.t('Tickets.Activities.Answer') } : null,
              }
            })
          }
          setObjects3(ticketActivities)
        }
      }
    )
  }, [userRoleString, ticketId, languageId, ticketViewModel])

  // Commands.
  const ticketChangeSubject = useCommandBuilder(subject => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeSubject', payload: { subject } }
  })
  const ticketChangeClient = useCommandBuilder(clientId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeClient', payload: { clientId } }
  })
  const ticketChangeContact = useCommandBuilder(contactId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeContact', payload: { contactId } }
  })
  const ticketChangeAgent = useCommandBuilder(agentId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeAgent', payload: { agentId } }
  })
  const ticketRefuse = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'refuse' })
  const ticketReturn = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'return' })
  const ticketAssign = useCommandBuilder(engineerId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'assign', payload: { engineerId } }
  })
  const ticketChangeSla = useCommandBuilder(slaId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeSla', payload: { slaId } }
  })
  const ticketChangeType = useCommandBuilder(typeId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeType', payload: { typeId } }
  })
  const ticketAccept = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'accept' })
  const ticketStart = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'start' })
  const ticketPause = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'pause' })
  const ticketResume = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'resume' })
  const ticketCreateReport = useCommandBuilder(({ description, spentTime, visibility }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'createReport',
      payload: { description, spentTime, visibility },
    }
  })
  const ticketConfirm = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'confirm' })

  return (
    <TicketView
      {...props}
      ticketId={ticketId}
      ticket={{ ...ticketReadModel, ...ticketViewModel }}
      ticketActivities={objects3}
      ticketChangeSubject={ticketChangeSubject}
      ticketChangeClient={ticketChangeClient}
      ticketChangeContact={ticketChangeContact}
      ticketChangeAgent={ticketChangeAgent}
      ticketRefuse={ticketRefuse}
      ticketReturn={ticketReturn}
      ticketAssign={ticketAssign}
      ticketChangeSla={ticketChangeSla}
      ticketChangeType={ticketChangeType}
      ticketAccept={ticketAccept}
      ticketStart={ticketStart}
      ticketPause={ticketPause}
      ticketResume={ticketResume}
      ticketCreateReport={ticketCreateReport}
      ticketConfirm={ticketConfirm}
    />
  )
}

export default TicketViewContainer
