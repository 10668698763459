import { Service_Created, Service_Renamed } from '../eventTypes'

const serviceViewModel = {
  Init: () => ({}),

  [Service_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, name } = payload
    return { id: aggregateId, authorId, createdOn: new Date(timestamp ?? 0), name }
  },

  [Service_Renamed]: (state: any, event: any) => {
    const { payload } = event
    const { name } = payload
    return { ...state, name }
  },
}

export default serviceViewModel
