import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { hasValue } from '../../../utils/utils'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { Button, buttonKind, Row } from '../../ui'
import { employmentRoles } from '../../../common/employmentRoles'

const PopupShadow = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  max-width: 100%;

  align-items: center;
  justify-content: center;

  background-color: rgba(25, 42, 62, 0.5);

  z-index: 1000;
`

const PopupPanel = styled.div`
  width: 450px;
  border-radius: 4px;

  background-color: rgb(255, 255, 255);
`

export class EmploymentRolesPopup extends PureComponent {
  raiseOnCloseButtonClick = () => {
    const { onCloseButtonClick } = this.props
    if (hasValue(onCloseButtonClick)) {
      onCloseButtonClick()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress)
  }

  onKeyPress = e => {
    if (e.keyCode === 27) {
      this.raiseOnCloseButtonClick()
    }
  }

  onPopupShadowClick = () => {
    this.raiseOnCloseButtonClick()
  }

  onPopupPanelClick = e => {
    e.stopPropagation()
  }

  onDoneButtonClick = () => {
    this.raiseOnCloseButtonClick()
  }

  renderRoleEditor = (roleId, indent, autoFocus) => {
    const { employment, l, onChange } = this.props
    return (
      <BooleanEditor
        id={'roles.' + roleId}
        caption={l.t('Employments.Roles.' + employmentRoles[roleId].name)}
        value={employment[roleId]}
        style={{ marginTop: `${indent}px` }}
        autoFocus={autoFocus}
        onChange={value => {
          if (hasValue(onChange)) {
            onChange(employment, roleId, value)
          }
        }}
      />
    )
  }

  render() {
    const { employment, l } = this.props
    const indent = 25
    return (
      <PopupShadow onClick={this.onPopupShadowClick}>
        <PopupPanel onClick={this.onPopupPanelClick}>
          <Row style={{ padding: '24px', fontSize: '16px', fontWeight: '500', borderBottom: '1px solid rgb(237, 241, 247)' }}>
            {`${employment.userName} ${l.t('Employments.Roles.Roles')}.`}
          </Row>
          <div style={{ padding: '24px' }}>
            {this.renderRoleEditor(employmentRoles.agent.id, 0, true)}
            {this.renderRoleEditor(employmentRoles.superagent.id, indent)}
            {this.renderRoleEditor(employmentRoles.client.id, indent)}
            {this.renderRoleEditor(employmentRoles.superclient.id, indent)}
          </div>
          <Row style={{ padding: '24px', borderTop: '1px solid rgb(237, 241, 247)', justifyContent: 'end' }}>
            <Button id={'done'} variant={'small'} kind={buttonKind.primary} title={l.t('Employments.Roles.Done')} onClick={this.onDoneButtonClick} />
          </Row>
        </PopupPanel>
      </PopupShadow>
    )
  }
}
