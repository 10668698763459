import { pick } from '../../utils/utils'
import { Sla_Created, Sla_Updated, Sla_Activity_Changed } from '../eventTypes'

const slaViewModel = {
  Init: () => ({}),

  [Sla_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, subject, agentId, clientId, service, termFrom, termTo, active } = payload
    return {
      id: aggregateId,
      authorId,
      createdOn: new Date(timestamp ?? 0),
      subject,
      agentId,
      clientId,
      service,
      termFrom,
      termTo,
      active,
    }
  },

  [Sla_Updated]: (state: any, event: any) => {
    const { payload } = event
    const changes = pick(payload, ['subject', 'service', 'termFrom', 'termTo'])
    return { ...state, ...changes }
  },

  [Sla_Activity_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { active } = payload
    return { ...state, active }
  },
}

export default slaViewModel
