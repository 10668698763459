import React, { PureComponent } from 'react'
import { v4 } from 'uuid'
import { useParams } from 'react-router'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { usePermissionJwt } from '../hooks'
import { View, ViewHeader } from '../../ui/view/View'
import { ObjectField } from '../ObjectField'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class UserCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { name: '', email: '' }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onEmailChange = value => {
    this.setState({ email: value })
  }

  onCreateButtonClick = async () => {
    const { history, userCreate, companyCreateEmployment } = this.props
    const { name, email } = this.state
    const userId = v4()
    await executeCommand(userCreate, { userId, name, email })
    if (hasValue(companyCreateEmployment)) {
      await executeCommand(companyCreateEmployment, userId)
    }
    history.goBack()
  }

  render() {
    const { companyId, l } = this.props
    const { name, email } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Users.User')} />
        <Panel>
          <PanelColumn>
            {hasValue(companyId) ? (
              <ObjectField
                id={'companyName'}
                caption={l.t('Users.Company')}
                resolverName={'simpleCompanyById'}
                argsFieldName={'companyId'}
                argsFieldValue={companyId}
              />
            ) : null}
            <TextEditor id={'name'} caption={l.t('Users.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
            <TextEditor id={'email'} caption={l.t('Users.Email')} value={email} onChange={this.onEmailChange} />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button kind={buttonKind.primary} variant={'small'} title={l.t('Users.Actions.Create')} onClick={this.onCreateButtonClick} />
        </Row>
      </View>
    )
  }
}

const UserCreatingViewContainer = props => {
  const { companyId } = useParams()
  const permissionJwt = usePermissionJwt('companyEmploymentCommandsPermission', { companyId })
  const userCreate = useCommandBuilder(({ userId, name, email }) => {
    return {
      aggregateName: 'User',
      aggregateId: userId,
      type: 'create',
      payload: { name, email },
    }
  })
  const companyCreateEmployment = hasValue(companyId)
    ? useCommandBuilder(userId => {
        return {
          aggregateName: 'Company',
          aggregateId: companyId,
          type: 'createEmployment',
          payload: { userId, permissionJwt },
        }
      })
    : null
  return <UserCreatingView {...props} companyId={companyId} userCreate={userCreate} companyCreateEmployment={companyCreateEmployment} />
}

export default UserCreatingViewContainer
