import styled from 'styled-components'

export const PlainText = styled.div`
  font-size: small;
  font-weight: normal;
  color: rgb(25, 42, 62);
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`
PlainText.displayName = 'PlainText'
