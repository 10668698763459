import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, buttonKind, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'TicketTypesView.PageSize'

export class TicketTypesView extends PureComponent {
  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/ticketTypeCreating')
  }

  renderButtons = () => {
    const { userRole, l } = this.props
    return hasValue(userRole) && userRole.superadmin ? (
      <ButtonsRow>
        <Button
          id={'create'}
          kind={buttonKind.primary}
          variant={'small'}
          title={l.t('TicketTypes.Actions.Create')}
          onClick={this.onCreateButtonClick}
        />
      </ButtonsRow>
    ) : null
  }

  renderPager = () => {
    const { currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, l } = this.props
    return (
      <Pager
        id={'ticketTypesPager'}
        resolverName={'ticketTypesCount'}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { ticketTypes, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('TicketTypes.TicketTypes')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          columnSettings={[
            { title: l.t('TicketTypes.Name'), fieldName: 'name', width: '85%', important: true },
            { title: l.t('TicketTypes.CreatedOn'), fieldName: 'createdOn', type: 'datetime', width: '15%' },
          ]}
          data={ticketTypes}
          onGetItemUrl={ticketType => `/ticketType/${ticketType.id}`}
        />
        {this.renderPager()}
      </View>
    )
  }
}

const TicketTypesViewContainer = props => {
  const resolverName = 'ticketTypes'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))

  const client = useClient()
  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    sortingFieldName: 'name',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <TicketTypesView
      {...props}
      ticketTypes={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default TicketTypesViewContainer
