import React, { PureComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, ButtonInvisible, Link, SelectMenu, Text } from '@primer/react'
import { PencilIcon, XIcon } from '@primer/octicons-react'

import { hasValue } from '../utils'

class LookupFieldOpenButton extends PureComponent {
  render() {
    const { children } = this.props
    return (
      <ButtonInvisible
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'fg.muted',
          bg: 'transparent',
          ':hover': {
            color: 'accent.fg',
            bg: 'transparent',
          },
          height: '16px',
          padding: 0,
          mb: 2,
        }}
        as={'summary'}
        children={children}
      />
    )
  }
}

export class LookupField extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { filterText: '' }
  }

  findTextByValue = (items, value) => {
    const item = Array.isArray(items) ? items.find(item => item.value === value) : null
    return hasValue(item) ? item.text : null
  }

  onFilterChange = e => {
    this.setState({ filterText: e.target.value })
  }

  onItemClick = value => {
    const { onChange } = this.props
    if (onChange instanceof Function) {
      onChange(value)
    }
  }

  renderItems = () => {
    const { items, allowClear, value } = this.props
    const { filterText } = this.state
    const result = []
    if (Array.isArray(items)) {
      if (allowClear) {
        result.push(
          <SelectMenu.Item key={null} as={'button'} onClick={() => this.onItemClick(null)}>
            <Box sx={{ position: 'absolute' }}>
              <XIcon size={16} />
            </Box>
            <Text sx={{ fontSize: [1, 0, 0] }}>{'Clear'}</Text>
          </SelectMenu.Item>
        )
      }
      items.forEach((item, index) => {
        if (
          !hasValue(filterText) ||
          filterText === '' ||
          (hasValue(item.text) && item.text.toLowerCase().includes(filterText.toLowerCase()))
        ) {
          result.push(
            <SelectMenu.Item
              key={index}
              id={item.value}
              selected={item.value === value}
              as={'button'}
              onClick={() => this.onItemClick(item.value)}
            >
              <Text sx={{ fontSize: [1, 0, 0] }}>{item.text}</Text>
            </SelectMenu.Item>
          )
        }
      })
    }
    return result
  }

  render() {
    const { id, title, items, value, url, width, mt, mb } = this.props
    const { filterText } = this.state
    const text = this.findTextByValue(items, value)
    return (
      <Box
        id={id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          justifyContent: 'flex-end',
          width,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          borderBottomColor: 'border.muted',
          pb: 3,
          mt,
          mb,
        }}
      >
        <SelectMenu>
          <LookupFieldOpenButton>
            <Text sx={{ fontSize: 0, fontWeight: 'bold' }}>{title}</Text>
            <PencilIcon size={16} />
          </LookupFieldOpenButton>
          <SelectMenu.Modal width={['100%', 'max-content', 'max-content']} align={'right'}>
            <SelectMenu.Header>{`Select ${title}`}</SelectMenu.Header>
            <SelectMenu.Filter
              placeholder={'Filter'}
              value={filterText}
              aria-label={'Filter'}
              onChange={this.onFilterChange}
            />
            <SelectMenu.List>{this.renderItems()}</SelectMenu.List>
          </SelectMenu.Modal>
        </SelectMenu>
        {hasValue(text) && hasValue(url) ? (
          <Link
            id={'text'}
            to={url}
            muted={true}
            sx={{ color: 'fg.default', fontSize: 0, fontWeight: 'bold' }}
            as={RouterLink}
          >
            {text}
          </Link>
        ) : (
          <Text sx={{ color: 'fg.muted', fontSize: 0 }}>{hasValue(text) ? text : 'Not assigned'}</Text>
        )}
      </Box>
    )
  }
}
