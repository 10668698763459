import Cookies from 'js-cookie'

import { getLocalDateTimeString, hasValue, ifHasValue, isNull } from '../utils/utils'
import { employmentRoles } from '../common/employmentRoles'
import { ticketStates } from '../common/ticketStates'

const languageIdKey = 'LanguageID'
const userRoleIdKey = 'UserRoleID'
const themeColorSettingIdKey = 'ThemeColorSettingID'

export const readValueFromStorage = key => {
  const value = window.localStorage.getItem(key)
  return hasValue(value) && value !== 'null' ? value : null
}

export const writeValueToStorage = (key, id) => {
  window.localStorage.setItem(key, id)
}

export const readLanguageIdFromStorage = defaultValue => {
  const value = window.localStorage.getItem(languageIdKey)
  return hasValue(value) ? value : defaultValue
}

export const writeLanguageIdToStorage = id => {
  window.localStorage.setItem(languageIdKey, id)
}

export const readUserRoleIdFromStorage = () => {
  const value = window.localStorage.getItem(userRoleIdKey)
  return hasValue(value) ? value : null
}

export const writeUserRoleIdToStorage = id => {
  window.localStorage.setItem(userRoleIdKey, id)
}

export const readThemeColorSettingIdFromStorage = defaultValue => {
  const value = window.localStorage.getItem(themeColorSettingIdKey)
  return hasValue(value) ? value : defaultValue
}

export const writeThemeColorSettingIdToStorage = id => {
  window.localStorage.setItem(themeColorSettingIdKey, id)
}

export const getUserRoles = user => {
  const result = []
  if (hasValue(user)) {
    if (user.superadmin) {
      result.push({
        superadmin: true,
        value: 'superadmin',
        text: 'Superadmin',
      })
    }
    ifHasValue(user.employments, []).forEach(employment => {
      if (employment.agent || employment.superagent) {
        result.push({
          companyId: employment.companyId,
          companyName: employment.companyName,
          companySuperagent: employment.companySuperagent,
          agent: true,
          superagent: employment.superagent,
          value: `${employment.companyId}-${employmentRoles.agent.id}`,
          text: `${employment.companyName} - ${employment.superagent ? employmentRoles.superagent.name : employmentRoles.agent.name}`,
        })
      }
      if (employment.client || employment.superclient) {
        result.push({
          companyId: employment.companyId,
          companyName: employment.companyName,
          client: true,
          superclient: employment.superclient,
          value: `${employment.companyId}-${employmentRoles.client.id}`,
          text: `${employment.companyName} - ${employment.superclient ? employmentRoles.superclient.name : employmentRoles.client.name}`,
        })
      }
    })
  }
  return result
}

export const getTicketsListItems = (tickets, ticketTypes, l) => {
  return Array.isArray(tickets)
    ? tickets.map(ticket => {
        const ticketType = Array.isArray(ticketTypes) ? ticketTypes.find(type => type.id === ticket.typeId) : null
        return {
          subject: ticket.subject,
          url: `/ticket/${ticket.id}`,
          labels: [
            {
              id: 'state',
              text: l.t('Tickets.States.' + ticketStates[ticket.state].name.replace(' ', '')),
              color: ticketStates[ticket.state].color,
            },
            {
              id: 'type',
              text: ticket.typeName,
              color: hasValue(ticketType) ? ticketType.color : null,
            },
          ],
          creationInfo: {
            authorName: ticket.authorName,
            authorUrl: `/user/${ticket.authorId}`,
            details: l.t('Tickets.CreatedOn').replace('{datetime}', getLocalDateTimeString(ticket.createdOn)),
          },
          assagnee: { id: 'engineerName', text: ticket.engineerName, url: `/user/${ticket.engineerId}` },
          comments: { count: ticket.activityCount, url: '#' },
        }
      })
    : []
}

export const getTicketReports = ticketActivities => {
  const authorsData = {}
  ticketActivities.forEach(ticketActivity => {
    const spentTime = Number.parseFloat(ticketActivity.spentTime)
    if (!Number.isNaN(spentTime)) {
      let authorData = authorsData[ticketActivity.authorId]
      if (isNull(authorData)) {
        authorData = { authorName: ticketActivity.authorName, spentTime: spentTime }
        authorsData[ticketActivity.authorId] = authorData
      } else {
        authorData.spentTime = authorData.spentTime + spentTime
      }
    }
  })
  const result = []
  Object.keys(authorsData).forEach(authorId => {
    if (hasValue(authorId) && authorId !== 'undefined' && authorId !== 'null' && authorId !== '') {
      const authorData = authorsData[authorId]
      result.push({
        authorId,
        authorUrl: `/user/${authorId}`,
        authorName: authorData.authorName,
        spentTime: authorData.spentTime,
      })
    }
  })
  return result
}

export const executeCommand = async (command, arg) => {
  try {
    await command(arg)
  } catch (e) {
    alert(e.message)
    throw e
  }
}

export const getCookieValue = name => {
  return Cookies.get(name)
}

export const companyEmploymentsTabId = 'employments'
export const companyCfgItemsTabId = 'cfgItems'
export const companySlasTabId = 'slas'
export const companyGroupsTabId = 'groups'

export const getBackLinkProps = location => {
  const userIndex = location.indexOf('/user/')
  const userCreatingIndex = location.indexOf('/userCreating')

  const companyIndex = location.indexOf('/company/')
  const companyCreatingIndex = location.indexOf('/companyCreating')

  const groupIndex = location.indexOf('/group/')
  const groupCreatingIndex = location.indexOf('/groupCreating')

  const slaIndex = location.indexOf('/sla/')
  const slaCreatingIndex = location.indexOf('/slaCreating')

  const cfgItemIndex = location.indexOf('/cfgItem/')
  const cfgItemCreatingIndex = location.indexOf('/cfgItemCreating')

  const ticketIndex = location.indexOf('/ticket/')
  const ticketCreatingIndex = location.indexOf('/ticketCreating')
  const ticketAcceptingIndex = location.indexOf('/accepting')
  const commentCreatingIndex = location.indexOf('/commentCreating')
  const feedbackSubmittingIndex = location.indexOf('/feedbackSubmitting')

  const ticketTypeIndex = location.indexOf('/ticketType/')
  const ticketTypeCreatingIndex = location.indexOf('/ticketTypeCreating')

  const serviceIndex = location.indexOf('/service/')
  const serviceCreatingIndex = location.indexOf('/serviceCreating')

  let result = {
    iconVisible: true,
  }
  if ((userIndex >= 0 || userCreatingIndex >= 0) && companyIndex < 0) {
    result.url = '/users'
    result.text = 'Users'
  } else if (userIndex >= 0 && userIndex < companyIndex) {
    result.url = location.substring(userIndex, companyIndex)
    result.text = 'User'
  } else if (companyIndex >= 0 && companyIndex < userCreatingIndex) {
    result.url = location.substring(companyIndex, userCreatingIndex) + '/tab/' + companyEmploymentsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < userIndex) {
    result.url = location.substring(companyIndex, userIndex) + '/tab/' + companyEmploymentsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < cfgItemCreatingIndex) {
    result.url = location.substring(companyIndex, cfgItemCreatingIndex) + '/tab/' + companyCfgItemsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < cfgItemIndex) {
    result.url = location.substring(companyIndex, cfgItemIndex) + '/tab/' + companyCfgItemsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < slaIndex) {
    result.url = location.substring(companyIndex, slaIndex) + '/tab/' + companySlasTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < groupCreatingIndex) {
    result.url = location.substring(companyIndex, groupCreatingIndex) + '/tab/' + companyGroupsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 && companyIndex < groupIndex) {
    result.url = location.substring(companyIndex, groupIndex) + '/tab/' + companyGroupsTabId
    result.text = 'Company'
  } else if (companyIndex >= 0 || companyCreatingIndex >= 0) {
    result.url = '/companies'
    result.text = 'Companies'
  } else if (slaIndex >= 0 || slaCreatingIndex >= 0) {
    result.url = '/slas'
    result.text = 'SLAs'
  } else if ((cfgItemIndex >= 0 || cfgItemCreatingIndex >= 0) && companyIndex < 0) {
    result.url = '/cfgItems'
    result.text = 'Cfg Items'
  } else if (ticketIndex >= 0 && ticketIndex < feedbackSubmittingIndex) {
    result.url = location.substring(ticketIndex, feedbackSubmittingIndex)
    result.text = 'Ticket'
  } else if (ticketIndex >= 0 && ticketIndex < commentCreatingIndex) {
    result.url = location.substring(ticketIndex, commentCreatingIndex)
    result.text = 'Ticket'
  } else if (ticketIndex >= 0 && ticketIndex < ticketAcceptingIndex) {
    result.url = location.substring(ticketIndex, ticketAcceptingIndex)
    result.text = 'Ticket'
  } else if (ticketIndex >= 0 || ticketCreatingIndex >= 0) {
    result.url = '/tickets'
    result.text = 'Tickets'
  } else if (ticketTypeIndex >= 0 || ticketTypeCreatingIndex >= 0) {
    result.url = '/ticketTypes'
    result.text = 'Ticket Types'
  } else if (serviceIndex >= 0 || serviceCreatingIndex >= 0) {
    result.url = '/services'
    result.text = 'Services'
  } else {
    result.url = '/'
    result.text = 'Saturn'
    result.iconVisible = false
  }
  return result
}

// Filters and sortings for companies.

export const getCompanyActiveFilters = () => {
  const getActive = () => {
    return { active: true }
  }
  const getInactive = () => {
    return { active: false }
  }
  const getAll = () => {
    return null
  }
  return [
    { id: 'active', text: 'Active', default: true, getCondition: getActive },
    { id: 'inactive', text: 'Inactive', getCondition: getInactive },
    { id: 'all', text: 'All', getCondition: getAll },
  ]
}

export const getCompaniesSortings = () => {
  return [
    { id: 'ab', text: 'AB', fieldName: 'name', direction: 1, default: true },
    { id: 'ba', text: 'BA', fieldName: 'name', direction: -1 },
    { id: 'newest', text: 'Newest', fieldName: 'createdOn', direction: -1 },
    { id: 'oldest', text: 'Oldest', fieldName: 'createdOn', direction: 1 },
  ]
}

// Filters and sortings for tickets.

export const getTicketAuthorFilters = () => {
  const getAll = () => {
    return null
  }
  const getEmptyAuthor = () => {
    return { authorId: null }
  }
  const getNotEmptyAuthor = () => {
    return { authorId: { $ne: null } }
  }
  const getCreatedByMe = currentUserId => {
    return { authorId: currentUserId }
  }
  return [
    { id: 'all', text: 'All', default: true, getCondition: getAll },
    { id: 'empty', text: 'Empty', getCondition: getEmptyAuthor },
    { id: 'notEmpty', text: 'NotEmpty', getCondition: getNotEmptyAuthor },
    { id: 'createdByMe', text: 'CreatedByMe', getCondition: getCreatedByMe },
  ]
}

export const getTicketEngineerFilters = () => {
  const getAll = () => {
    return null
  }
  const getEmptyEngineer = () => {
    return { engineerId: null }
  }
  const getNotEmptyEngineer = () => {
    return { engineerId: { $ne: null } }
  }
  const getAssignedToMe = currentUserId => {
    return { engineerId: currentUserId }
  }
  return [
    { id: 'all', text: 'All', default: true, getCondition: getAll },
    { id: 'empty', text: 'Empty', getCondition: getEmptyEngineer },
    { id: 'notEmpty', text: 'NotEmpty', getCondition: getNotEmptyEngineer },
    { id: 'assignedToMe', text: 'AssignedToMe', getCondition: getAssignedToMe },
  ]
}

export const getTicketCreatedOnFilters = () => {
  const oneDay = 24 * 60 * 60 * 1000
  const now = new Date()
  const todayBegin = new Date(now.setHours(0, 0, 0, 0))
  const todayEnd = new Date(now.setHours(24, 0, 0, 0))
  const yesterdayBegin = new Date(todayBegin.getTime() - oneDay)
  const yesterdayEnd = new Date(todayEnd.getTime() - oneDay)
  const getAll = () => {
    return null
  }
  const getTodayCreated = () => {
    return { $and: [{ createdOn: { $gte: todayBegin } }, { createdOn: { $lt: todayEnd } }] }
  }
  const getYesterdayCreated = () => {
    return { $and: [{ createdOn: { $gte: yesterdayBegin } }, { createdOn: { $lt: yesterdayEnd } }] }
  }
  return [
    { id: 'all', text: 'All', default: true, getCondition: getAll },
    { id: 'today', text: 'Today', getCondition: getTodayCreated },
    { id: 'yesterday', text: 'Yesterday', getCondition: getYesterdayCreated },
  ]
}

export const getTicketStateFilters = () => {
  const getAll = () => {
    return null
  }
  const getClassification = () => {
    return { $and: [{ state: ticketStates.new.id }, { engineerId: { $ne: null } }] }
  }
  const getCurrent = () => {
    return {
      $and: [{ state: { $ne: ticketStates.completed.id } }, { state: { $ne: ticketStates.confirmed.id } }],
    }
  }
  return [
    { id: 'all', text: 'All', default: true, getCondition: getAll },
    { id: 'classification', text: 'Classification', getCondition: getClassification },
    { id: 'current', text: 'Current', getCondition: getCurrent },
  ]
}

export const getTicketTypeFilters = () => {
  const getAll = () => {
    return null
  }
  const getEmptyType = () => {
    return { typeId: null }
  }
  const getNotEmptyType = () => {
    return { typeId: { $ne: null } }
  }
  return [
    { id: 'all', text: 'All', default: true, getCondition: getAll },
    { id: 'empty', text: 'Empty', getCondition: getEmptyType },
    { id: 'notEmpty', text: 'NotEmpty', getCondition: getNotEmptyType },
  ]
}

export const getTicketsSortings = () => {
  return [
    { id: 'newest', text: 'Newest', fieldName: 'createdOn', direction: -1, default: true },
    { id: 'oldest', text: 'Oldest', fieldName: 'createdOn', direction: 1 },
  ]
}

// Common functions for filters ans sortings.

export const findCondition = (filters, filterId, objIdKey, fieldName, currentUserId) => {
  let result = null
  if (isNull(filterId)) {
    result = filters.find(filter => filter.default).getCondition(currentUserId)
  } else if (hasValue(objIdKey) && filterId.startsWith(objIdKey)) {
    result = { [fieldName]: filterId.replace(objIdKey, '') }
  } else {
    const filter = filters.find(filter => filter.id === filterId)
    if (hasValue(filter)) {
      result = filter.getCondition(currentUserId)
    }
  }
  return result
}

export const findSorting = (sortings, sortingId) => {
  let sorting = null
  if (isNull(sortingId)) {
    sorting = sortings.find(sorting => sorting.default)
  } else {
    sorting = sortings.find(sorting => sorting.id === sortingId)
  }
  if (isNull(sorting)) {
    sorting = {}
  }
  return sorting
}

export const getFiltersPanelItem = (items, itemId, objects, objIdKey, listName, itemTemplateName, l, onClick) => {
  let result = null
  if (hasValue(itemId)) {
    let text = l.t(listName + '.FiltersPanel.Unknown')
    const filter = items.find(filter => filter.id === itemId)
    if (hasValue(filter)) {
      text = l.t(listName + '.Toolbar.' + filter.text.replace(' ', ''))
    } else if (hasValue(objIdKey) && Array.isArray(objects) && itemId.startsWith(objIdKey)) {
      const objId = itemId.replace(objIdKey, '')
      const obj = objects.find(obj => obj.id === objId)
      if (hasValue(obj)) {
        text = obj.name
      }
    }
    result = {
      text: l.t(listName + '.FiltersPanel.' + itemTemplateName).replace('{text}', text),
      onClick: () => onClick(null),
    }
  }
  return result
}
