import React, { PureComponent, useCallback } from 'react'

import { useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { ticketStates } from '../../../common/ticketStates'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, buttonKind, ButtonsRow, FilterTextInput } from '../../ui'
import TicketsList from '../lists/TicketsList'

export class TicketsView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { subjectChanging: false, filterText: null }
  }

  onFilterTextInputChange = value => {
    this.setState({ filterText: value })
  }

  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/ticketCreating')
  }

  onTakeButtonClick = async () => {
    const { onTakeButtonClick } = this.props
    onTakeButtonClick()
  }

  renderButtons = () => {
    const { userRole, l } = this.props
    const allowAgentCommands = hasValue(userRole) && (userRole.superadmin || userRole.agent)
    return (
      <ButtonsRow>
        <FilterTextInput placeholder={l.t('Tickets.Actions.FilterPlaceholder')} onChange={this.onFilterTextInputChange} />
        <Button
          id={'create'}
          kind={hasValue(userRole) && userRole.client ? buttonKind.primary : buttonKind.simple}
          variant={'small'}
          title={l.t('Tickets.Actions.Create')}
          onClick={this.onCreateButtonClick}
        />
        {allowAgentCommands ? (
          <Button
            kind={hasValue(userRole) && userRole.agent ? buttonKind.primary : buttonKind.simple}
            variant={'small'}
            title={l.t('Tickets.Actions.Take')}
            onClick={this.onTakeButtonClick}
          />
        ) : null}
      </ButtonsRow>
    )
  }

  render() {
    const { jwtUser, userRole, l, languageId } = this.props
    const { filterText } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Tickets.Tickets')} buttons={this.renderButtons()} />
        <TicketsList jwtUser={jwtUser} userRole={userRole} filterText={filterText} keysPrefix={'TicketsView.'} l={l} languageId={languageId} />
      </View>
    )
  }
}

const TicketsViewContainer = props => {
  const { userRole, history } = props
  const resolverName = 'tickets'
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const ticketTake = useCommandBuilder(({ ticketId }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'take',
      payload: {},
    }
  })

  const onTakeButtonClick = useCallback(value => {
    client.query(
      {
        name: 'Saturn',
        resolver: resolverName,
        args: {
          userRoleString,
          conditionString: JSON.stringify({ $and: [{ state: ticketStates.new.id }, { engineerId: null }] }),
          sortingFieldName: 'createdOn',
          sortingDirection: 1,
          firstRecordIndex: 0,
          recordCount: 1,
        },
      },
      (error, result) => {
        if (hasValue(result) && Array.isArray(result.data) && result.data.length === 1) {
          const ticketId = result.data[0].id
          ticketTake({ ticketId })
          history.push(`/ticket/${ticketId}`)
        } else {
          alert('There are no any tickets available for classification.')
        }
      }
    )
  }, [])

  return <TicketsView {...props} onTakeButtonClick={onTakeButtonClick} />
}

export default TicketsViewContainer
