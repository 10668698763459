import React, { PureComponent, useCallback, useEffect, useState } from 'react'

import { useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { usePermissionJwt, useClientQuery } from '../hooks'
import { employmentRoles } from '../../../common/employmentRoles'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import { CreateEmploymentButton } from './CreateEmploymentButton'
import { EmploymentRolesPopup } from './EmploymentRolesPopup'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'

const pageSizeKey = 'CompanyEmploymentsView.PageSize'

export class CompanyEmploymentsView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { rolesPopupVisible: false, employmentUserId: null }
    this.actualEmployments = {}
  }

  setActualEmploymentRole = (userId, roleId, value) => {
    let actualEmployment = this.actualEmployments[userId]
    if (!hasValue(actualEmployment)) {
      actualEmployment = {}
      this.actualEmployments[userId] = actualEmployment
    }
    actualEmployment[roleId] = value
  }

  actualizeEmployment = employment => {
    const actualEmployment = this.actualEmployments[employment.userId]
    return { ...employment, ...actualEmployment }
  }

  renderPager = () => {
    const { userRole, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, companyId, l } = this.props
    return (
      <Pager
        id={'companyEmploymentsViewPager'}
        resolverName={'employmentsForCompanyCount'}
        resolverArgs={{ userRoleString: JSON.stringify(userRole), companyId }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  onCreateUserAndEmploymentButtonClick = () => {
    const { companyId, history } = this.props
    history.push(`/company/${companyId}/userCreating`)
  }

  renderButtons = () => {
    const { companyCreateEmployment, l } = this.props
    return (
      <ButtonsRow>
        <CreateEmploymentButton
          style={{ marginRight: '16px' }}
          l={l}
          onClick={async userId => {
            await executeCommand(companyCreateEmployment, userId)
          }}
        />
        <Button
          id={'createUserAndEmployment'}
          variant={'small'}
          title={l.t('Employments.Actions.Create')}
          onClick={this.onCreateUserAndEmploymentButtonClick}
        />
      </ButtonsRow>
    )
  }

  getLocalizedRoleName = name => {
    const { l } = this.props
    return l.t('Employments.Roles.' + name)
  }

  onRolesButtonTitleGet = employment => {
    const roleNames = []
    const actualEmployment = this.actualizeEmployment(employment)
    if (actualEmployment.agent) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.agent.name))
    }
    if (actualEmployment.superagent) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.superagent.name))
    }
    if (actualEmployment.client) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.client.name))
    }
    if (actualEmployment.superclient) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.superclient.name))
    }
    return roleNames.join(', ')
  }

  onRolesButtonClick = employment => {
    if (hasValue(employment)) {
      this.setState({ rolesPopupVisible: true, employmentUserId: employment.userId })
    }
  }

  onRolesPopupCloseButtonClick = () => {
    this.setState({ rolesPopupVisible: false, employmentUserId: null })
  }

  onRolesPopupChange = (employment, roleId, value) => {
    const { companyAddEmploymentRole, companyRemoveEmploymentRole } = this.props
    if (value) {
      executeCommand(companyAddEmploymentRole, { userId: employment.userId, roleId })
      this.setActualEmploymentRole(employment.userId, roleId, true)
    } else {
      executeCommand(companyRemoveEmploymentRole, { userId: employment.userId, roleId })
      this.setActualEmploymentRole(employment.userId, roleId, false)
    }
  }

  onRemoveButtonClick = async employment => {
    const { companyRemoveEmployment } = this.props
    if (confirm(`Do you really want to remove the "${employment.userName}" employment?`)) {
      await executeCommand(companyRemoveEmployment, employment.userId)
    }
  }

  render() {
    const { userRole, companyId, employments, companyAddEmploymentRole, companyRemoveEmploymentRole, visible, l } = this.props
    const { rolesPopupVisible, employmentUserId } = this.state
    const isUserViewAllowed =
      hasValue(userRole) &&
      (userRole.superadmin || (userRole.companyId === companyId && userRole.superagent) || (userRole.companyId === companyId && userRole.superclient))
    return visible ? (
      <View>
        <ViewHeader text={l.t('Employments.Employments')} buttons={this.renderButtons()} />
        <List
          id={'employments.list'}
          keyFieldName={'id'}
          columnSettings={[
            { title: l.t('Employments.Name'), fieldName: 'userName', width: '30%', important: true },
            { title: l.t('Employments.Email'), fieldName: 'userEmail', width: '25%', important: true },
            {
              title: l.t('Employments.Roles.Roles'),
              buttonTitle: this.onRolesButtonTitleGet,
              type: 'button',
              id: 'roles',
              onClick: this.onRolesButtonClick,
              width: '30%',
            },
            {
              title: '',
              buttonTitle: l.t('Employments.Actions.Remove'),
              type: 'button',
              onClick: this.onRemoveButtonClick,
              width: '15%',
            },
          ]}
          data={employments}
          onGetItemUrl={employment => {
            return isUserViewAllowed ? `/company/${companyId}/user/${employment.userId}` : null
          }}
        />
        {this.renderPager()}
        {rolesPopupVisible ? (
          <EmploymentRolesPopup
            companyId={companyId}
            employment={this.actualizeEmployment(employments.find(employment => employment.userId === employmentUserId))}
            onChange={this.onRolesPopupChange}
            companyAddEmploymentRole={companyAddEmploymentRole}
            companyRemoveEmploymentRole={companyRemoveEmploymentRole}
            l={l}
            onCloseButtonClick={this.onRolesPopupCloseButtonClick}
          />
        ) : null}
      </View>
    ) : null
  }
}

const CompanyEmploymentsViewContainer = props => {
  const { userRole, companyId } = props
  const resolverName = 'employmentsForCompany'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const permissionJwt = usePermissionJwt('companyEmploymentCommandsPermission', { companyId })
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    companyId,
    sortingFieldName: 'userName',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  const companyCreateEmployment = useCommandBuilder(userId => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'createEmployment', payload: { userId, permissionJwt } }
  })
  const companyRemoveEmployment = useCommandBuilder(userId => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'removeEmployment', payload: { userId, permissionJwt } }
  })
  const companyAddEmploymentRole = useCommandBuilder(({ userId, roleId }) => {
    return {
      aggregateName: 'Company',
      aggregateId: companyId,
      type: 'addEmploymentRole',
      payload: { userId, roleId, permissionJwt },
    }
  })
  const companyRemoveEmploymentRole = useCommandBuilder(({ userId, roleId }) => {
    return {
      aggregateName: 'Company',
      aggregateId: companyId,
      type: 'removeEmploymentRole',
      payload: { userId, roleId, permissionJwt },
    }
  })

  return (
    <CompanyEmploymentsView
      {...props}
      employments={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
      companyCreateEmployment={companyCreateEmployment}
      companyRemoveEmployment={companyRemoveEmployment}
      companyAddEmploymentRole={companyAddEmploymentRole}
      companyRemoveEmploymentRole={companyRemoveEmploymentRole}
    />
  )
}

export default CompanyEmploymentsViewContainer
