import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Pagehead } from '..'

import { hasValue } from '../../../utils/utils'

export const MobileIndent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 544px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export class ViewHeader extends PureComponent {
  render() {
    const { content, text, buttons } = this.props
    return (
      <MobileIndent>
        <Pagehead>
          {hasValue(content) ? content : null}
          {text}
          {hasValue(buttons) ? buttons : null}
        </Pagehead>
      </MobileIndent>
    )
  }
}

export const View = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  align-items: center;
  > * {
    width: 100%;
    max-width: 1216px;
  }
`
