import React, { PureComponent } from 'react'

import { hasValue } from '../../../utils/utils'
import { TextInput } from '..'

export class FilterTextInput extends PureComponent {
  constructor(props) {
    super(props)
    this.timeoutId = null
    this.state = { text: null }
  }

  raiseOnChange = () => {
    const { onChange } = this.props
    const { text } = this.state
    if (onChange instanceof Function) {
      onChange(text)
    }
  }

  onTextInputChange = value => {
    this.setState({ text: value })
    if (hasValue(this.timeoutId)) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
    this.timeoutId = setTimeout(this.raiseOnChange, 500)
  }

  render() {
    const { placeholder } = this.props
    const { text } = this.state
    return <TextInput id={'filterText'} placeholder={placeholder} value={text} width={'350px'} onChange={this.onTextInputChange} />
  }
}
