import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Text, useTheme } from '@primer/react'
import { PencilIcon } from '@primer/octicons-react'

import { hasValue } from '../utils'

export const TextField = props => {
  const { id, title, text, url, width, mt, mb } = props
  const { theme } = useTheme()
  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'flex-end',
        width,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: 'border.muted',
        pb: 3,
        mt,
        mb,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'fg.muted',
          height: '16px',
        }}
      >
        <Text sx={{ fontSize: 0, fontWeight: 'bold' }}>{title}</Text>
        <PencilIcon size={16} fill={theme.colors.avatar.stackFade} />
      </Box>
      {hasValue(text) && hasValue(url) ? (
        <Link
          id={'text'}
          to={url}
          muted={true}
          sx={{ color: 'fg.default', fontSize: 0, fontWeight: 'bold', mt: 2 }}
          as={RouterLink}
        >
          {text}
        </Link>
      ) : (
        <Text sx={{ fontSize: 0, color: 'fg.muted', mt: 2 }}>{hasValue(text) ? text : 'Not assigned'}</Text>
      )}
    </Box>
  )
}
