import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { getLocalDateString, getLocalDateTimeString, hasValue, getValue } from '../../../utils/utils'
import { Row } from '../row/Row'

const TitleRow = styled(Row)`
  height: 32px;
  font-size: small;
  font-weight: bold;
  color: rgb(101, 121, 141);
  background-color: rgb(255, 255, 255);
  align-items: center;
  padding: 0 16px;
  margin-bottom: 1px;
  @media (max-width: 768px) {
    display: none;
  }
`

const ItemStyle = css`
  height: 32px;
  min-height: 32px;
  font-size: small;
  color: rgb(25, 42, 62);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 1px;
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 25;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    height: auto;
    padding: 8px 16px;
  }
`

const ItemLink = styled(Link)`
  ${ItemStyle};
`
ItemLink.displayName = 'ItemLink'

const ItemSimple = styled(Row)`
  ${ItemStyle};
`

const ItemField = styled.div`
  display: flex;
  width: ${props => props.width};
  flex-direction: row;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    width: 100%;
    height: 24px;
    display: ${props => (props.important ? 'visible' : 'none')};
  }
`

const ItemFieldTitle = styled.div`
  min-width: 125px;
  font-weight: bold;
  display: none;
  @media (max-width: 768px) {
    display: block;
    text-overflow: ellipsis;
  }
`

const StyledButton = styled.button`
  width: auto;
  height: 24px;
  background-color: rgb(235, 235, 235);
  border-radius: 4px;
  justify-content: center;
  padding: 0 16px 0 16px;
  font-size: small;
`
StyledButton.displayName = 'StyledButton'

export class List extends PureComponent {
  getValue = (obj, columnSetting) => {
    return hasValue(columnSetting.onValueGet) ? columnSetting.onValueGet(obj) : getValue(obj, columnSetting.fieldName)
  }

  renderText = (id, text) => {
    return (
      <div
        id={id}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'inline',
          overflow: 'hidden',
        }}
      >
        {text}
      </div>
    )
  }

  renderTextField = (obj, columnSetting) => {
    const value = this.getValue(obj, columnSetting)
    return this.renderText(columnSetting.fieldName, value)
  }

  renderBooleanField = (obj, columnSetting) => {
    const value = this.getValue(obj, columnSetting)
    return <input id={columnSetting.fieldName} type={'checkbox'} checked={value} readOnly={true} />
  }

  renderDateTimeField = (obj, columnSetting) => {
    const value = getLocalDateTimeString(this.getValue(obj, columnSetting))
    return this.renderText(columnSetting.fieldName, value)
  }

  renderDateField = (obj, columnSetting) => {
    const value = getLocalDateString(this.getValue(obj, columnSetting))
    return this.renderText(columnSetting.fieldName, value)
  }

  renderImageField = (obj, columnSetting) => {
    const value = this.getValue(obj, columnSetting)
    return <img id={columnSetting.fieldName} alt={''} src={value} width={'24px'} height={'24px'} />
  }

  renderButtonField = (obj, columnSetting) => {
    const buttonTitle = columnSetting.buttonTitle instanceof Function ? columnSetting.buttonTitle(obj) : columnSetting.buttonTitle
    const content = (
      <StyledButton
        id={columnSetting.id}
        type={'button'}
        onClick={e => {
          e.preventDefault()
          columnSetting.onClick(obj)
        }}
      >
        {buttonTitle}
      </StyledButton>
    )
    return content
  }

  renderTitle = () => {
    const { columnSettings } = this.props
    return (
      <TitleRow key={0}>
        {columnSettings.map((columnSetting, index) => {
          return (
            <div key={index} style={{ width: columnSetting.width, flexDirection: 'row' }}>
              {columnSetting.title}
            </div>
          )
        })}
      </TitleRow>
    )
  }

  renderItemFields = (obj, columnSettings) => {
    return columnSettings.map((columnSetting, index) => {
      let fieldContent = null
      if (!hasValue(columnSetting.type) || columnSetting.type === 'text') {
        fieldContent = this.renderTextField(obj, columnSetting)
      } else if (columnSetting.type === 'boolean') {
        fieldContent = this.renderBooleanField(obj, columnSetting)
      } else if (columnSetting.type === 'datetime') {
        fieldContent = this.renderDateTimeField(obj, columnSetting)
      } else if (columnSetting.type === 'date') {
        fieldContent = this.renderDateField(obj, columnSetting)
      } else if (columnSetting.type === 'image') {
        fieldContent = this.renderImageField(obj, columnSetting)
      } else if (columnSetting.type === 'button') {
        fieldContent = this.renderButtonField(obj, columnSetting)
      }
      return (
        <ItemField key={index} width={columnSetting.width} important={columnSetting.important}>
          <ItemFieldTitle>{columnSetting.title}</ItemFieldTitle>
          {fieldContent}
        </ItemField>
      )
    })
  }

  renderItemRow = (obj, index) => {
    const { keyFieldName, columnSettings, onGetItemUrl } = this.props
    const url = hasValue(onGetItemUrl) ? onGetItemUrl(obj) : null
    let key = obj[keyFieldName]
    if (!hasValue(key)) {
      key = index
    }
    return hasValue(url) ? (
      <ItemLink key={key} to={url}>
        {this.renderItemFields(obj, columnSettings)}
      </ItemLink>
    ) : (
      <ItemSimple key={key}>{this.renderItemFields(obj, columnSettings)}</ItemSimple>
    )
  }

  render() {
    const { data } = this.props
    return (
      <div>
        {this.renderTitle()}
        {hasValue(data)
          ? data.map((obj, index) => {
              return this.renderItemRow(obj, index)
            })
          : null}
      </div>
    )
  }
}
