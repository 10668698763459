import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, ButtonsRow } from '../../ui'
import { TextInput } from '../../ui/textInput/TextInput'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'CfgItemsView.PageSize'

export class CfgItemsView extends PureComponent {
  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/cfgItemCreating')
  }

  renderButtons = () => {
    const { filterText, onFilterTextChange, l } = this.props
    return (
      <ButtonsRow>
        <TextInput
          id={'filterText'}
          value={filterText}
          placeholder={'Search for a configuration item...'}
          width={'300px'}
          onChange={onFilterTextChange}
        />
        <Button id={'create'} variant={'small'} title={l.t('CfgItems.Actions.Create')} onClick={this.onCreateButtonClick} />
      </ButtonsRow>
    )
  }

  renderPager = () => {
    const { userRole, filterText, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, l } = this.props
    return (
      <Pager
        id={'cfgItemsPager'}
        resolverName={'cfgItemsCount'}
        resolverArgs={{
          userRoleString: JSON.stringify(userRole),
          conditionString: JSON.stringify(hasValue(filterText) && filterText !== '' ? { name: filterText } : null),
        }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { cfgItems, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('CfgItems.CfgItems')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          data={cfgItems}
          columnSettings={[
            { title: l.t('CfgItems.Name'), fieldName: 'name', width: '35%', important: true },
            { title: l.t('CfgItems.Company'), fieldName: 'companyName', width: '35%', important: true },
            { title: l.t('CfgItems.Active'), fieldName: 'active', type: 'boolean', width: '15%' },
            { title: l.t('CfgItems.CreatedOn'), fieldName: 'createdOn', type: 'datetime', width: '15%' },
          ]}
          onGetItemUrl={cfgItem => `/cfgItem/${cfgItem.id}`}
        />
        {this.renderPager()}
      </View>
    )
  }
}

const CfgItemsViewContainer = props => {
  const resolverName = 'cfgItems'
  const { userRole } = props
  const [filterText, setFilterText] = useState(null)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    conditionString: JSON.stringify(hasValue(filterText) && filterText !== '' ? { name: filterText } : null),
    sortingFieldName: 'name',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onFilterTextChange = useCallback(value => {
    setFilterText(value)
  }, [])
  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <CfgItemsView
      {...props}
      cfgItems={objects}
      filterText={filterText}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onFilterTextChange={onFilterTextChange}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default CfgItemsViewContainer
