import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class ServiceCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { name: '' }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onCreateButtonClick = async () => {
    const { history, serviceCreate } = this.props
    const { name } = this.state
    const serviceId = v4()
    await executeCommand(serviceCreate, { serviceId, name })
    history.goBack()
  }

  render() {
    const { name, l } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Services.Service')} />
        <Panel>
          <PanelColumn>
            <TextEditor id={'name'} caption={l.t('Services.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button kind={buttonKind.primary} variant={'small'} title={l.t('Services.Actions.Create')} onClick={this.onCreateButtonClick} />
        </Row>
      </View>
    )
  }
}

const ServiceCreatingViewContainer = props => {
  const serviceCreate = useCommandBuilder(({ serviceId, name }) => {
    return { aggregateName: 'Service', aggregateId: serviceId, type: 'create', payload: { name } }
  })
  return <ServiceCreatingView {...props} serviceCreate={serviceCreate} />
}

export default ServiceCreatingViewContainer
