import React, { PureComponent } from 'react'

import { getTicketReports } from '../../../utils'
import { Box, Link, Text } from '../../../ui'

class TicketReportsListItem extends PureComponent {
  render() {
    const { ticketReport } = this.props
    return (
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={2}>
        <Link id={'authorName'} url={ticketReport.authorUrl} fontSize={0} fontWeight={'bold'} color={'fg.default'}>
          {ticketReport.authorName}
        </Link>
        <Text id={'spentTime'} fontSize={0} color={'fg.default'}>
          {ticketReport.spentTime}
        </Text>
      </Box>
    )
  }
}

class TicketReportsListTotalItem extends PureComponent {
  render() {
    const { title, value } = this.props
    return (
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={2}>
        <Text fontSize={0} fontWeight={'bold'} color={'fg.default'}>
          {title}
        </Text>
        <Text id={'spentTime'} fontSize={0} color={'fg.default'}>
          {value}
        </Text>
      </Box>
    )
  }
}

export class TicketReportsList extends PureComponent {
  render() {
    const { ticketActivities, mt, l } = this.props
    const ticketReports = getTicketReports(ticketActivities)
    let totalSpentTime = 0
    ticketReports.forEach(ticketReport => {
      totalSpentTime = totalSpentTime + ticketReport.spentTime
    })
    return (
      <Box
        id={'reports'}
        display={'flex'}
        flexDirection={'column'}
        borderBottomStyle={'solid'}
        borderBottomWidth={1}
        borderBottomColor={'border.muted'}
        pb={3}
        mt={mt}
      >
        <Text fontSize={0} fontWeight={'bold'} color={'fg.muted'}>
          {l.t('Tickets.WorkReports')}
        </Text>
        {ticketReports.map((ticketReport, index) => {
          return <TicketReportsListItem key={index} ticketReport={ticketReport} />
        })}
        <TicketReportsListTotalItem
          key={ticketReports.length}
          title={l.t('Tickets.WorkReportsTotal')}
          value={totalSpentTime}
        />
      </Box>
    )
  }
}
