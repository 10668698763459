import React, { PureComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useViewModel, useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'

export class ServiceView extends PureComponent {
  onNameChange = async value => {
    const { serviceRename } = this.props
    await executeCommand(serviceRename, value)
  }

  render() {
    const { service, l } = this.props
    return (
      <View>
        <ViewHeader text={'Service'} />
        <MobileIndent>
          <AdvancedText id={'name'} caption={l.t('Services.Name')} value={service.name} allowChanging={true} l={l} onChange={this.onNameChange} />
        </MobileIndent>
      </View>
    )
  }
}

const ServiceViewContainer = props => {
  const viewModelName = 'ServiceVm'
  const [object, setObject] = useState({})
  const { serviceId } = useParams()
  const { connect, dispose } = useViewModel(viewModelName, [serviceId], setObject)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])
  const serviceRename = useCommandBuilder(name => {
    return { aggregateName: 'Service', aggregateId: serviceId, type: 'rename', payload: { name } }
  })
  return <ServiceView {...props} serviceId={serviceId} service={object} serviceRename={serviceRename} />
}

export default ServiceViewContainer
