import React, { PureComponent } from 'react'

import { Row } from '../row/Row'
import { LookupInput } from '../lookupInput/LookupInput'

export class LookupEditor extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { id, caption, items, addEmptyItem, value, autoFocus, readOnly, style, labelStyle, onChange } = this.props
    return (
      <Row style={{ justifyContent: 'space-between', ...style }}>
        <label htmlFor={id} style={{ width: '150px', fontSize: 'small', fontWeight: 'bold', color: 'rgb(101, 121, 141)', ...labelStyle }}>
          {caption}
        </label>
        <LookupInput
          id={id}
          items={items}
          addEmptyItem={addEmptyItem}
          value={value}
          autoFocus={autoFocus}
          readOnly={readOnly}
          style={{ flex: 1 }}
          onChange={onChange}
        />
      </Row>
    )
  }
}
