import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, buttonKind, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'SlasView.PageSize'

export class SlasView extends PureComponent {
  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/slaCreating')
  }

  renderButtons = () => {
    const { l } = this.props
    return (
      <ButtonsRow>
        <Button id={'create'} kind={buttonKind.primary} variant={'small'} title={l.t('Slas.Actions.Create')} onClick={this.onCreateButtonClick} />
      </ButtonsRow>
    )
  }

  renderPager = () => {
    const { userRole, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, l } = this.props
    return (
      <Pager
        id={'slasPager'}
        resolverName={'slasCount'}
        resolverArgs={{ userRoleString: JSON.stringify(userRole) }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { slas, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('Slas.Slas')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          data={slas}
          columnSettings={[
            { title: l.t('Slas.Subject'), fieldName: 'subject', width: '15%', important: true },
            { title: l.t('Slas.Agent'), fieldName: 'agentName', width: '15%', important: true },
            { title: l.t('Slas.Client'), fieldName: 'clientName', width: '15%', important: true },
            { title: l.t('Slas.Service'), fieldName: 'service', width: '15%' },
            { title: l.t('Slas.From'), fieldName: 'termFrom', type: 'date', width: '10%' },
            { title: l.t('Slas.To'), fieldName: 'termTo', type: 'date', width: '10%' },
            { title: l.t('Slas.Active'), fieldName: 'active', type: 'boolean', width: '10%' },
            { title: l.t('Slas.CreatedOn'), fieldName: 'createdOn', type: 'datetime', width: '10%' },
          ]}
          onGetItemUrl={sla => `/sla/${sla.id}`}
        />
        {this.renderPager()}
      </View>
    )
  }
}

const SlasViewContainer = props => {
  const resolverName = 'slas'
  const { userRole } = props
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const userRoleString = JSON.stringify(userRole)

  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    sortingFieldName: 'clientName',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <SlasView
      {...props}
      slas={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default SlasViewContainer
