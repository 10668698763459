import React, { PureComponent } from 'react'
import { v4 } from 'uuid'
import { useParams } from 'react-router'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { ObjectField } from '../ObjectField'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class GroupCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { name: '', active: true }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onCreateButtonClick = async () => {
    const { history, groupCreate } = this.props
    const { name } = this.state
    const groupId = v4()
    await executeCommand(groupCreate, { groupId, name })
    history.goBack()
  }

  render() {
    const { companyId, l } = this.props
    const { name } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Groups.Group')} />
        <Panel>
          <PanelColumn>
            <ObjectField
              id={'companyName'}
              caption={l.t('Companies.Company')}
              resolverName={'simpleCompanyById'}
              argsFieldName={'companyId'}
              argsFieldValue={companyId}
            />
            <TextEditor id={'name'} caption={l.t('Groups.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button
            id={'createGroup'}
            kind={buttonKind.primary}
            variant={'small'}
            title={l.t('Groups.Actions.Create')}
            onClick={this.onCreateButtonClick}
          />
        </Row>
      </View>
    )
  }
}

const GroupCreatingViewContainer = props => {
  const { companyId } = useParams()
  const groupCreate = useCommandBuilder(({ groupId, name }) => {
    return {
      aggregateName: 'Company',
      aggregateId: companyId,
      type: 'createGroup',
      payload: { groupId, name },
    }
  })
  return <GroupCreatingView {...props} companyId={companyId} groupCreate={groupCreate} />
}

export default GroupCreatingViewContainer
