import React, { PureComponent } from 'react'
import { v4 } from 'uuid'
import { useParams } from 'react-router'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { usePermissionJwt } from '../hooks'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { ObjectField } from '../ObjectField'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class CfgItemCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { name: '', active: true }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onActiveChange = value => {
    this.setState({ active: value })
  }

  onCreateButtonClick = async () => {
    const { history, cfgItemCreate } = this.props
    const { name, active } = this.state
    const cfgItemId = v4()
    await executeCommand(cfgItemCreate, { cfgItemId, name, active })
    history.goBack()
  }

  render() {
    const { companyId, l } = this.props
    const { name, active } = this.state
    return (
      <View>
        <ViewHeader text={l.t('CfgItems.CfgItem')} />
        <Panel>
          <PanelColumn>
            <ObjectField
              id={'companyName'}
              caption={l.t('CfgItems.Company')}
              resolverName={'simpleCompanyById'}
              argsFieldName={'companyId'}
              argsFieldValue={companyId}
            />
            <TextEditor id={'name'} caption={l.t('CfgItems.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
            <BooleanEditor id={'active'} caption={l.t('CfgItems.Active')} value={active} onChange={this.onActiveChange} />
          </PanelColumn>
        </Panel>
        <MobileIndent>
          <Row style={{ marginTop: '16px' }}>
            <Button
              id={'createCfgItem'}
              kind={buttonKind.primary}
              variant={'small'}
              title={l.t('CfgItems.Actions.Create')}
              onClick={this.onCreateButtonClick}
            />
          </Row>
        </MobileIndent>
      </View>
    )
  }
}

const CfgItemCreatingViewContainer = props => {
  const { companyId } = useParams()
  const permissionJwt = usePermissionJwt('cfgItemCommandsPermission', { companyId })
  const cfgItemCreate = useCommandBuilder(({ cfgItemId, name, active }) => {
    return {
      aggregateName: 'CfgItem',
      aggregateId: cfgItemId,
      type: 'create',
      payload: { companyId, name, active, permissionJwt },
    }
  })
  return <CfgItemCreatingView {...props} companyId={companyId} cfgItemCreate={cfgItemCreate} />
}

export default CfgItemCreatingViewContainer
