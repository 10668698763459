import React, { useState, useEffect } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../utils/utils'
import { TextField } from '../ui/textField/TextField'

export const ObjectField = (props) => {
  const { id, caption, resolverName, argsFieldName, argsFieldValue, displayFieldName = 'name', onDisplayValueGet } = props
  const [displayValue, setDisplayValue] = useState(null)
  const client = useClient()
  useEffect(() => {
    if (hasValue(argsFieldValue)) {
      const args = {
        [argsFieldName]: argsFieldValue,
      }
      client.query({ name: 'Saturn', resolver: resolverName, args }, (error, result) => {
        if (hasValue(result) && hasValue(result.data)) {
          const obj = result.data
          setDisplayValue(hasValue(onDisplayValueGet) ? onDisplayValueGet(obj) : obj[displayFieldName])
        } else {
          setDisplayValue(null)
        }
      })
    }
  }, [argsFieldValue])
  return <TextField id={id} caption={caption} value={displayValue} />
}
