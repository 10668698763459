import React, { Fragment, PureComponent } from 'react'

import { hasValue } from '../../../../utils/utils'
import { executeCommand } from '../../../utils'
import { ticketStates } from '../../../../common/ticketStates'
import { Button, buttonKind } from '../../../ui'
import { ticketCommentCreatingMode } from '../ticketCommentCreatingMode'
import { TicketReportCreatingForm } from './TicketReportCreatingForm'

export class TicketActions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isReportDialogVisible: false, reportDescription: '' }
    this.clarifyAction = {
      id: 'clarify',
      title: 'Clarify',
      onExecute: this.onClarifyActionExecute,
    }
    this.commentAction = {
      id: 'comment',
      title: 'Comment',
      onExecute: this.onCommentActionExecute,
    }
    this.refuseAction = {
      id: 'refuse',
      title: 'Refuse',
      onExecute: this.onRefuseActionExecute,
    }
    this.returnAction = {
      id: 'return',
      title: 'Return',
      onExecute: this.onReturnActionExecute,
    }
    this.acceptAction = {
      id: 'accept',
      title: 'Accept',
      onExecute: this.onAcceptActionExecute,
    }
    this.startAction = {
      id: 'start',
      title: 'Start',
      onExecute: this.onStartActionExecute,
    }
    this.pauseAction = {
      id: 'pause',
      title: 'Pause',
      onExecute: this.onPauseActionExecute,
    }
    this.resumeAction = {
      id: 'resume',
      title: 'Resume',
      onExecute: this.onResumeActionExecute,
    }
    this.createReportAction = {
      id: 'createReport',
      title: 'Create Report',
      onExecute: this.onCreateReportActionExecute,
    }
    this.completeAction = {
      id: 'complete',
      title: 'Complete',
      onExecute: this.onCompleteActionExecute,
    }
    this.rejectAction = {
      id: 'reject',
      title: 'Reject',
      onExecute: this.onRejectActionExecute,
    }
    this.confirmAction = {
      id: 'confirm',
      title: 'Confirm',
      onExecute: this.onConfirmActionExecute,
    }
    this.submitFeedbackAction = {
      id: 'submitFeedback',
      title: 'Submit Feedback',
      onExecute: this.onSubmitFeedbackActionExecute,
    }
  }

  onClarifyActionExecute = async () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/commentCreating/${ticketCommentCreatingMode.clarify}`)
  }

  onCommentActionExecute = () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/commentCreating/${ticketCommentCreatingMode.comment}`)
  }

  onRefuseActionExecute = async () => {
    const { ticketRefuse, l } = this.props
    if (confirm(l.t('Tickets.Actions.RefuseConfirmation'))) {
      await executeCommand(ticketRefuse, {})
    }
  }

  onReturnActionExecute = async () => {
    const { ticketReturn, l } = this.props
    if (confirm(l.t('Tickets.Actions.ReturnConfirmation'))) {
      await executeCommand(ticketReturn, {})
    }
  }

  onAcceptActionExecute = async () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/accepting`)
  }

  onStartActionExecute = async () => {
    const { ticketStart, l } = this.props
    if (confirm(l.t('Tickets.Actions.StartConfirmation'))) {
      await executeCommand(ticketStart, {})
    }
  }

  onPauseActionExecute = async () => {
    const { ticketPause, l } = this.props
    if (confirm(l.t('Tickets.Actions.PauseConfirmation'))) {
      await executeCommand(ticketPause, {})
    }
  }

  onResumeActionExecute = async () => {
    const { ticketResume, l } = this.props
    if (confirm(l.t('Tickets.Actions.ResumeConfirmation'))) {
      await executeCommand(ticketResume, {})
    }
  }

  onCreateReportActionExecute = () => {
    this.setState({ isReportDialogVisible: true })
  }

  onReportCreatingFormButtonClick = async ({ description, spentTime, visibility }) => {
    const { ticketCreateReport } = this.props
    await executeCommand(ticketCreateReport, { description, spentTime, visibility })
    this.setState({ isReportDialogVisible: false })
  }

  onCompleteActionExecute = () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/commentCreating/${ticketCommentCreatingMode.complete}`)
  }

  onRejectActionExecute = () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/commentCreating/${ticketCommentCreatingMode.reject}`)
  }

  onConfirmActionExecute = async () => {
    const { ticketConfirm, l } = this.props
    if (confirm(l.t('Tickets.Actions.ConfirmConfirmation'))) {
      await executeCommand(ticketConfirm, {})
    }
  }

  onSubmitFeedbackActionExecute = () => {
    const { ticketId, history } = this.props
    history.push(`/ticket/${ticketId}/feedbackSubmitting`)
  }

  getActionsSettings = () => {
    const { userRole, ticket } = this.props
    const isAgentCommandsAllowed = hasValue(userRole) && (userRole.superadmin || (userRole.agent && userRole.companyId === ticket.agentId))
    const isClientCommandsAllowed = hasValue(userRole) && (userRole.superadmin || (userRole.client && userRole.companyId === ticket.clientId))
    const result = {
      simpleActions: [],
      primaryActions: [],
    }
    if (ticket.state === ticketStates.new.id) {
      if (isAgentCommandsAllowed) {
        result.simpleActions.push(this.clarifyAction)
        result.simpleActions.push(this.refuseAction)
        result.primaryActions.push(this.acceptAction)
        result.simpleActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.clarification.id) {
      if (isAgentCommandsAllowed) {
        result.simpleActions.push(this.refuseAction)
        result.primaryActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.refused.id) {
      if (isAgentCommandsAllowed) {
        result.primaryActions.push(this.returnAction)
        result.simpleActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.accepted.id) {
      if (isAgentCommandsAllowed) {
        result.simpleActions.push(this.clarifyAction)
        result.simpleActions.push(this.refuseAction)
        result.primaryActions.push(this.startAction)
        result.simpleActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.rejected.id) {
      if (isAgentCommandsAllowed) {
        result.simpleActions.push(this.clarifyAction)
        result.simpleActions.push(this.refuseAction)
        result.primaryActions.push(this.startAction)
        result.simpleActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.inProgress.id) {
      if (isAgentCommandsAllowed) {
        result.simpleActions.push(this.clarifyAction)
        result.simpleActions.push(this.refuseAction)
        result.simpleActions.push(this.pauseAction)
        result.primaryActions.push(this.createReportAction)
        result.simpleActions.push(this.completeAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.paused.id) {
      if (isAgentCommandsAllowed) {
        result.primaryActions.push(this.resumeAction)
        result.simpleActions.push(this.createReportAction)
      }
      if (isClientCommandsAllowed) {
        result.primaryActions.push(this.commentAction)
      }
    } else if (ticket.state === ticketStates.completed.id) {
      if (isClientCommandsAllowed) {
        result.simpleActions.push(this.commentAction)
        result.simpleActions.push(this.rejectAction)
        result.primaryActions.push(this.confirmAction)
      }
    } else if (ticket.state === ticketStates.confirmed.id) {
      if (isClientCommandsAllowed) {
        if (ticket.feedbackSubmitted) {
          result.primaryActions.push(this.commentAction)
        } else {
          result.primaryActions.push(this.submitFeedbackAction)
        }
      }
    }
    return result
  }

  executeReportAction = description => {
    this.setState({ isReportDialogVisible: true, reportDescription: description })
  }

  render() {
    const { l } = this.props
    const { reportDescription, isReportDialogVisible } = this.state
    const actionsSettings = this.getActionsSettings()
    return (
      <Fragment>
        {Array.isArray(actionsSettings.simpleActions)
          ? actionsSettings.simpleActions.map((action, index) => (
              <Button
                key={index}
                id={action.id}
                variant={'small'}
                kind={buttonKind.simple}
                title={l.t('Tickets.Actions.' + action.title.replace(' ', ''))}
                onClick={action.onExecute}
              />
            ))
          : null}
        {Array.isArray(actionsSettings.primaryActions)
          ? actionsSettings.primaryActions.map((action, index) => (
              <Button
                key={index}
                id={action.id}
                variant={'small'}
                kind={buttonKind.primary}
                title={l.t('Tickets.Actions.' + action.title.replace(' ', ''))}
                onClick={action.onExecute}
              />
            ))
          : null}
        {isReportDialogVisible ? (
          <TicketReportCreatingForm
            l={l}
            description={reportDescription}
            onButtonClick={this.onReportCreatingFormButtonClick}
            onDismiss={() => this.setState({ isReportDialogVisible: false })}
          />
        ) : null}
      </Fragment>
    )
  }
}
