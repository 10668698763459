import React, { PureComponent } from 'react'

import { hasValue } from '../../../utils/utils'

const nullValue = 'Null_Value'

export class LookupInput extends PureComponent {
  onChange = (e) => {
    const { items, onChange } = this.props
    if (hasValue(onChange)) {
      const value = e.target.value === nullValue ? null : e.target.value
      const item = items.find((item) => item.value === value)
      onChange(value, hasValue(item) ? item.text : null)
    }
  }

  renderItem = (key, value, text) => {
    return (
      <option key={key} value={value}>
        {text}
      </option>
    )
  }

  render() {
    const { id, items, addEmptyItem, value, autoFocus, readOnly, style } = this.props
    return (
      <select
        id={id}
        value={hasValue(value) ? value : nullValue}
        autoFocus={autoFocus}
        disabled={readOnly}
        multiple={false}
        style={{
          height: '24px',
          fontSize: 'small',
          ...style,
        }}
        onChange={this.onChange}
      >
        {addEmptyItem ? this.renderItem('', nullValue, null) : null}
        {items.map((item) => {
          return this.renderItem(item.value, item.value, item.text)
        })}
      </select>
    )
  }
}
