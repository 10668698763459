import React, { PureComponent, useState, useEffect } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import { useClientQuery } from '../hooks'

export class ServicesView extends PureComponent {
  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/serviceCreating')
  }

  renderButtons = () => {
    const { l } = this.props
    return (
      <ButtonsRow>
        <Button id={'create'} variant={'small'} title={l.t('Services.Actions.Create')} onClick={this.onCreateButtonClick} />
      </ButtonsRow>
    )
  }

  render() {
    const { services, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('Services.Services')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          columnSettings={[{ title: l.t('Services.Name'), fieldName: 'name', width: '25%' }]}
          data={services}
          onGetItemUrl={service => `/service/${service.id}`}
        />
      </View>
    )
  }
}

const ServicesViewContainer = props => {
  const resolverName = 'services'
  const client = useClient()
  const objects = useClientQuery(client, [], 'Saturn', resolverName, {})

  return <ServicesView {...props} services={objects} />
}

export default ServicesViewContainer
