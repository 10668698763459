import React from 'react'
import { Helmet as LibHelmet } from 'react-helmet'

import { useStaticResolver } from '@resolve-js/react-hooks'

export const Helmet = (props) => {
  const resolveStatic = useStaticResolver()
  const stylesLink = { rel: 'stylesheet', href: resolveStatic('/styles.css') }
  const faviconLink = { rel: 'icon', type: 'image/png', href: resolveStatic('/favicon.ico') }
  const meta = { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  return <LibHelmet title={'Saturn'} link={[stylesLink, faviconLink]} meta={[meta]} />
}
