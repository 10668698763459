import React, { PureComponent } from 'react'
import { useParams } from 'react-router'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue, trim } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { ticketActivityVisibilities } from '../../../common/ticketActivityVisibilities'
import { ticketCommentCreatingMode } from './ticketCommentCreatingMode'
import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { LookupEditor } from '../../ui/lookupEditor/LookupEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class TicketCommentCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      description: '',
      spentTime: 1,
      visibility: ticketActivityVisibilities.external.id,
    }
  }

  onDescriptionChange = value => {
    this.setState({ description: value })
  }

  onSpentTimeChange = value => {
    this.setState({ spentTime: value })
  }

  onVisibilityChange = value => {
    this.setState({ visibility: value })
  }

  onButtonClick = async () => {
    const { mode, history, ticketClarify, ticketComment, ticketComplete, ticketReject } = this.props
    const { description, spentTime, visibility } = this.state
    if (mode === ticketCommentCreatingMode.clarify) {
      await executeCommand(ticketClarify, { description })
      history.goBack()
    } else if (mode === ticketCommentCreatingMode.comment) {
      await executeCommand(ticketComment, { description })
      history.goBack()
    } else if (mode === ticketCommentCreatingMode.complete) {
      if (hasValue(description) && trim(description) !== '') {
        await executeCommand(ticketComplete, { description, spentTime, visibility })
        history.goBack()
      }
    } else if (mode === ticketCommentCreatingMode.reject) {
      await executeCommand(ticketReject, description)
      history.goBack()
    }
  }

  render() {
    const { mode, l } = this.props
    const { description, spentTime, visibility } = this.state
    const visibilityItems = Object.keys(ticketActivityVisibilities).map(key => {
      return { value: ticketActivityVisibilities[key].id, text: l.t('Tickets.' + ticketActivityVisibilities[key].name) }
    })
    let viewHeaderText = ''
    let buttonTitle = ''
    if (mode === ticketCommentCreatingMode.clarify) {
      viewHeaderText = l.t('Tickets.Actions.Clarify')
      buttonTitle = l.t('Tickets.Actions.Clarify')
    } else if (mode === ticketCommentCreatingMode.comment) {
      viewHeaderText = l.t('Tickets.Actions.Comment')
      buttonTitle = l.t('Tickets.Actions.Comment')
    } else if (mode === ticketCommentCreatingMode.complete) {
      viewHeaderText = l.t('Tickets.Actions.Complete')
      buttonTitle = l.t('Tickets.Actions.Complete')
    } else if (mode === ticketCommentCreatingMode.reject) {
      viewHeaderText = l.t('Tickets.Actions.Reject')
      buttonTitle = l.t('Tickets.Actions.Reject')
    }
    return (
      <View>
        <ViewHeader text={viewHeaderText} />
        <Panel>
          <PanelColumn>
            <TextEditor
              id={'description'}
              caption={l.t('Tickets.CommentDescription')}
              multiLines={true}
              autoFocus={true}
              value={description}
              onChange={this.onDescriptionChange}
            />
            {mode === ticketCommentCreatingMode.complete ? (
              <TextEditor id={'spentTime'} caption={l.t('Tickets.SpentTime')} type={'number'} value={spentTime} onChange={this.onSpentTimeChange} />
            ) : null}
            {mode === ticketCommentCreatingMode.complete ? (
              <LookupEditor
                id={'visibility'}
                caption={l.t('Tickets.Visibility')}
                items={visibilityItems}
                value={visibility}
                onChange={this.onVisibilityChange}
              />
            ) : null}
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button variant={'small'} kind={buttonKind.primary} title={buttonTitle} onClick={this.onButtonClick} />
        </Row>
      </View>
    )
  }
}

const TicketCommentCreatingViewContainer = props => {
  const { ticketId, mode } = useParams()
  const ticketClarify = useCommandBuilder(({ description }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'clarify',
      payload: { description },
    }
  })
  const ticketComment = useCommandBuilder(({ description }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'comment',
      payload: { description },
    }
  })
  const ticketComplete = useCommandBuilder(({ description, spentTime, visibility }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'complete',
      payload: { description, spentTime, visibility },
    }
  })
  const ticketReject = useCommandBuilder(description => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'reject',
      payload: { description },
    }
  })
  return (
    <TicketCommentCreatingView
      {...props}
      ticketId={ticketId}
      mode={mode}
      ticketClarify={ticketClarify}
      ticketComment={ticketComment}
      ticketComplete={ticketComplete}
      ticketReject={ticketReject}
    />
  )
}

export default TicketCommentCreatingViewContainer
