import React, { PureComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useCommand, useCommandBuilder, useViewModel } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { ticketActivityVisibilities } from '../../../common/ticketActivityVisibilities'
import { View, ViewHeader } from '../../ui/view/View'
import ConnectedLookupEditor from '../lookup/LookupEditor'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class TicketAcceptingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { description: '', spentTime: 1 }
  }

  onSlaChange = async value => {
    const { ticketChangeSla } = this.props
    await executeCommand(ticketChangeSla, value)
  }

  onTypeChange = async value => {
    const { ticketChangeType } = this.props
    await executeCommand(ticketChangeType, value)
  }

  onDescriptionChange = value => {
    this.setState({ description: value })
  }

  onSpentTimeChange = value => {
    this.setState({ spentTime: value })
  }

  onAcceptButtonClick = async () => {
    const { history, ticketAccept, ticketCreateReport } = this.props
    const { description, spentTime } = this.state
    await executeCommand(ticketAccept, {})
    await executeCommand(ticketCreateReport, { description, spentTime })
    history.goBack()
  }

  render() {
    const { jwtUser, userRole, ticket, l } = this.props
    const { description, spentTime } = this.state
    const isAcceptEnabled =
      hasValue(ticket) && hasValue(ticket.slaId) && hasValue(ticket.typeId) && hasValue(description) && description.trim() > '' && spentTime > 0
    return (
      <View>
        {/* <ViewHeader text={l.t('Tickets.Ticket')} /> */}
        <ViewHeader text={hasValue(ticket) ? ticket.subject : ''} />
        <Panel>
          <PanelColumn>
            <ConnectedLookupEditor
              id={'slaId'}
              caption={l.t('Tickets.Sla')}
              resolverName={'simpleSlas'}
              resolverArgs={{ userRoleString: JSON.stringify(userRole), agentId: ticket.agentId, clientId: ticket.clientId }}
              valueFieldName={'id'}
              textFieldName={'service'}
              autoFocus={true}
              value={ticket.slaId}
              onChange={this.onSlaChange}
            />
            <ConnectedLookupEditor
              id={'typeId'}
              caption={l.t('Tickets.Type')}
              resolverName={'ticketTypes'}
              resolverArgs={{}}
              valueFieldName={'id'}
              textFieldName={'name'}
              value={ticket.typeId}
              onChange={this.onTypeChange}
            />
          </PanelColumn>
          <PanelColumn>
            <TextEditor
              id={'description'}
              caption={l.t('Tickets.CommentDescription')}
              multiLines={true}
              value={description}
              onChange={this.onDescriptionChange}
            />
            <TextEditor id={'spentTime'} caption={l.t('Tickets.SpentTime')} type={'number'} value={spentTime} onChange={this.onSpentTimeChange} />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button
            kind={buttonKind.primary}
            variant={'small'}
            title={l.t('Tickets.Actions.Accept')}
            disabled={!isAcceptEnabled}
            onClick={this.onAcceptButtonClick}
          />
        </Row>
      </View>
    )
  }
}

const TicketAcceptingViewContainer = props => {
  const { userRole } = props
  const ticketViewModelName = 'TicketVm'
  const { ticketId } = useParams()
  const userRoleString = JSON.stringify(userRole)

  // Ticket view model.
  const [ticket, setTicket] = useState({})
  const { connect, dispose } = useViewModel(ticketViewModelName, [ticketId], setTicket, { userRoleString })
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [userRoleString])

  // Commands.
  const ticketChangeSla = useCommandBuilder(slaId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeSla', payload: { slaId } }
  })
  const ticketChangeType = useCommandBuilder(typeId => {
    return { aggregateName: 'Ticket', aggregateId: ticketId, type: 'changeType', payload: { typeId } }
  })
  const ticketAccept = useCommand({ aggregateName: 'Ticket', aggregateId: ticketId, type: 'accept' })
  const ticketCreateReport = useCommandBuilder(({ description, spentTime }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'createReport',
      payload: { description, spentTime, visibility: ticketActivityVisibilities.internal.id },
    }
  })

  return (
    <TicketAcceptingView
      {...props}
      ticketId={ticketId}
      ticket={ticket}
      ticketChangeSla={ticketChangeSla}
      ticketChangeType={ticketChangeType}
      ticketAccept={ticketAccept}
      ticketCreateReport={ticketCreateReport}
    />
  )
}

export default TicketAcceptingViewContainer
