import React, { PureComponent, useState, useEffect, useCallback, Fragment } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { combineConditionsByAnd, hasValue, isNull } from '../../../utils/utils'
import {
  findCondition,
  findSorting,
  getFiltersPanelItem,
  getTicketAuthorFilters,
  getTicketCreatedOnFilters,
  getTicketEngineerFilters,
  getTicketsListItems,
  getTicketsSortings,
  getTicketStateFilters,
  getTicketTypeFilters,
  readValueFromStorage,
  writeValueToStorage,
} from '../../utils'
import { ticketStates } from '../../../common/ticketStates'
import { MobileIndent } from '../../ui/view/View'
import { FiltersPanel, NewList } from '../../ui'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'PageSize'
const authorFilterIdKey = 'AuthorFilterID'
const engineerFilterIdKey = 'EngineerFilterID'
const createdOnFilterIdKey = 'CreatedOnFilterID'
const stateFilterIdKey = 'StateFilterID'
const typeFilterIdKey = 'TypeFilterID'
const sortingIdKey = 'SortingID'

const authorFilters = getTicketAuthorFilters()
const authorIdKey = 'AuthorId:'
const engineerFilters = getTicketEngineerFilters()
const engineerIdKey = 'EngineerId:'
const createdOnFilters = getTicketCreatedOnFilters()
const stateFilters = getTicketStateFilters()
const stateIdKey = 'StateId:'
const typeFilters = getTicketTypeFilters()
const typeIdKey = 'TypeId:'
const sortings = getTicketsSortings()

export const getTicketsConditionString = (jwtUserId, condition, authorFilterId, engineerFilterId, createdOnFilterId, stateFilterId, typeFilterId) => {
  const conditionParts = []
  conditionParts.push(condition)
  conditionParts.push(findCondition(authorFilters, authorFilterId, authorIdKey, 'authorId', jwtUserId))
  conditionParts.push(findCondition(engineerFilters, engineerFilterId, engineerIdKey, 'engineerId', jwtUserId))
  conditionParts.push(findCondition(createdOnFilters, createdOnFilterId, null, null, null))
  conditionParts.push(findCondition(stateFilters, stateFilterId, stateIdKey, 'state', null))
  conditionParts.push(findCondition(typeFilters, typeFilterId, typeIdKey, 'typeId', null))
  return JSON.stringify(combineConditionsByAnd(conditionParts))
}

export class TicketsList extends PureComponent {
  renderFiltersPanel = () => {
    const {
      jwtUser,
      authorFilterId,
      engineerFilterId,
      createdOnFilterId,
      stateFilterId,
      typeFilterId,
      sortingId,
      authors,
      engineers,
      ticketStatesLocalized,
      ticketTypes,
      l,
      onAuthorFilterChange,
      onEngineerFilterChange,
      onCreatedOnFilterChange,
      onStateFilterChange,
      onTypeFilterChange,
      onSortingChange,
    } = this.props
    const items = []
    // Author filter.
    items.push(getFiltersPanelItem(authorFilters, authorFilterId, authors, authorIdKey, 'Tickets', 'Author', l, onAuthorFilterChange))
    // Engineer filter.
    items.push(getFiltersPanelItem(engineerFilters, engineerFilterId, engineers, engineerIdKey, 'Tickets', 'Engineer', l, onEngineerFilterChange))
    // CreatedOn filter.
    items.push(getFiltersPanelItem(createdOnFilters, createdOnFilterId, null, null, 'Tickets', 'CreatedOn', l, onCreatedOnFilterChange))
    // State filter.
    items.push(getFiltersPanelItem(stateFilters, stateFilterId, ticketStatesLocalized, stateIdKey, 'Tickets', 'State', l, onStateFilterChange))
    // Type filter.
    items.push(getFiltersPanelItem(typeFilters, typeFilterId, ticketTypes, typeIdKey, 'Tickets', 'Type', l, onTypeFilterChange))
    // Sorting.
    items.push(getFiltersPanelItem(sortings, sortingId, null, null, 'Tickets', 'Sorting', l, onSortingChange))
    return items.length > 0 ? (
      <MobileIndent>
        <FiltersPanel items={items} mb={3} />
      </MobileIndent>
    ) : null
  }

  renderPager = () => {
    const {
      jwtUser,
      userRole,
      condition,
      filterText,
      authorFilterId,
      engineerFilterId,
      createdOnFilterId,
      stateFilterId,
      typeFilterId,
      currentPageIndex,
      pageSize,
      l,
      onCurrentPageIndexChange,
      onPageSizeChange,
    } = this.props
    return (
      <Pager
        id={'ticketsPager'}
        resolverName={'ticketsCount'}
        resolverArgs={{
          userRoleString: JSON.stringify(userRole),
          conditionString: getTicketsConditionString(
            jwtUser.id,
            condition,
            authorFilterId,
            engineerFilterId,
            createdOnFilterId,
            stateFilterId,
            typeFilterId
          ),
          filterText,
        }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  getAuthorFilterMenuItems = () => {
    const { authorFilterId, authors, l, onAuthorFilterChange } = this.props
    const result = []
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Popular') })
    authorFilters.forEach(filter => {
      result.push({
        text: l.t('Tickets.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === authorFilterId || (isNull(authorFilterId) && filter.default),
        onClick: () => onAuthorFilterChange(filter.id),
      })
    })
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Authors') })
    if (Array.isArray(authors)) {
      const authorId = hasValue(authorFilterId) && authorFilterId.startsWith(authorIdKey) ? authorFilterId.replace(authorIdKey, '') : null
      authors.forEach(author => {
        result.push({
          text: author.name,
          selected: author.id === authorId,
          onClick: () => onAuthorFilterChange(authorIdKey + author.id),
        })
      })
    }
    return result
  }

  getEngineerFilterMenuItems = () => {
    const { engineerFilterId, engineers, l, onEngineerFilterChange } = this.props
    const result = []
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Popular') })
    engineerFilters.forEach(filter => {
      result.push({
        text: l.t('Tickets.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === engineerFilterId || (isNull(engineerFilterId) && filter.default),
        onClick: () => onEngineerFilterChange(filter.id),
      })
    })
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Engineers') })
    if (Array.isArray(engineers)) {
      const engineerId = hasValue(engineerFilterId) && engineerFilterId.startsWith(engineerIdKey) ? engineerFilterId.replace(engineerIdKey, '') : null
      engineers.forEach(engineer => {
        result.push({
          text: engineer.name,
          selected: engineer.id === engineerId,
          onClick: () => onEngineerFilterChange(engineerIdKey + engineer.id),
        })
      })
    }
    return result
  }

  getCreatedOnFilterMenuItems = () => {
    const { createdOnFilterId, l, onCreatedOnFilterChange } = this.props
    const result = []
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Popular') })
    createdOnFilters.forEach(filter => {
      result.push({
        text: l.t('Tickets.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === createdOnFilterId || (isNull(createdOnFilterId) && filter.default),
        onClick: () => onCreatedOnFilterChange(filter.id),
      })
    })
    return result
  }

  getStateFilterMenuItems = () => {
    const { stateFilterId, ticketStatesLocalized, l, onStateFilterChange } = this.props
    const result = []
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Popular') })
    stateFilters.forEach(filter => {
      result.push({
        text: l.t('Tickets.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === stateFilterId || (isNull(stateFilterId) && filter.default),
        onClick: () => onStateFilterChange(filter.id),
      })
    })
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.States') })

    const stateId = hasValue(stateFilterId) && stateFilterId.startsWith(stateIdKey) ? stateFilterId.replace(stateIdKey, '') : null
    if (Array.isArray(ticketStatesLocalized)) {
      ticketStatesLocalized.forEach(ticketState => {
        result.push({
          text: ticketState.name,
          selected: ticketState.id === stateId,
          onClick: () => onStateFilterChange(stateIdKey + ticketState.id),
        })
      })
    }
    return result
  }

  getTypeFilterMenuItems = () => {
    const { typeFilterId, ticketTypes, l, onTypeFilterChange } = this.props
    const result = []
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Popular') })
    typeFilters.forEach(filter => {
      result.push({
        text: l.t('Tickets.Toolbar.' + filter.text.replace(' ', '')),
        selected: filter.id === typeFilterId || (isNull(typeFilterId) && filter.default),
        onClick: () => onTypeFilterChange(filter.id),
      })
    })
    result.push({ groupHeader: true, text: l.t('Tickets.Toolbar.Types') })
    if (Array.isArray(ticketTypes)) {
      const typeId = hasValue(typeFilterId) && typeFilterId.startsWith(typeIdKey) ? typeFilterId.replace(typeIdKey, '') : null
      ticketTypes.forEach(ticketType => {
        result.push({
          text: ticketType.name,
          selected: ticketType.id === typeId,
          onClick: () => onTypeFilterChange(typeIdKey + ticketType.id),
        })
      })
    }
    return result
  }

  getSortingMenuItems = () => {
    const { sortingId, l, onSortingChange } = this.props
    const result = []
    sortings.forEach(sorting => {
      result.push({
        selected: sorting.id === sortingId || (isNull(sortingId) && sorting.default),
        text: l.t('Tickets.Toolbar.' + sorting.text.replace(' ', '')),
        onClick: () => onSortingChange(sorting.id),
      })
    })
    return result
  }

  getToolbarSettings = () => {
    const { l } = this.props
    return {
      menus: [
        {
          id: 'authorFilter',
          title: l.t('Tickets.Toolbar.Author'),
          dropdownTitle: l.t('Tickets.Toolbar.FilterByAuthor'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getAuthorFilterMenuItems(),
        },
        {
          id: 'engineerFilter',
          title: l.t('Tickets.Toolbar.Engineer'),
          dropdownTitle: l.t('Tickets.Toolbar.FilterByEngineer'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getEngineerFilterMenuItems(),
        },
        {
          id: 'createdOnFilter',
          title: l.t('Tickets.Toolbar.CreatedOn'),
          dropdownTitle: l.t('Tickets.Toolbar.FilterByCreatedOn'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getCreatedOnFilterMenuItems(),
        },
        {
          id: 'stateFilter',
          title: l.t('Tickets.Toolbar.State'),
          dropdownTitle: l.t('Tickets.Toolbar.FilterByState'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getStateFilterMenuItems(),
        },
        {
          id: 'typeFilter',
          title: l.t('Tickets.Toolbar.Type'),
          dropdownTitle: l.t('Tickets.Toolbar.FilterByType'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getTypeFilterMenuItems(),
        },
        {
          id: 'sorting',
          title: l.t('Tickets.Toolbar.Sorting'),
          dropdownTitle: l.t('Tickets.Toolbar.SortBy'),
          dropdownFilter: l.t('Tickets.Toolbar.Filter'),
          items: this.getSortingMenuItems(),
        },
      ],
    }
  }

  render() {
    const { tickets, ticketTypes, l } = this.props
    return (
      <Fragment>
        {this.renderFiltersPanel()}
        <NewList toolbarSettings={this.getToolbarSettings()} items={getTicketsListItems(tickets, ticketTypes, l)} />
        {this.renderPager()}
      </Fragment>
    )
  }
}

const TicketsListContainer = props => {
  const { jwtUser, userRole, condition, filterText, keysPrefix, l, languageId } = props
  const resolverName = 'tickets'
  const [ticketStatesLocalized, setTicketStatesLocalized] = useState([])

  const [authorFilterId, setFilterAuthorId] = useState(readValueFromStorage(keysPrefix + authorFilterIdKey))
  const [engineerFilterId, setFilterEngineerId] = useState(readValueFromStorage(keysPrefix + engineerFilterIdKey))
  const [createdOnFilterId, setCreatedOnFilterId] = useState(readValueFromStorage(keysPrefix + createdOnFilterIdKey))
  const [stateFilterId, setStateFilterId] = useState(readValueFromStorage(keysPrefix + stateFilterIdKey))
  const [typeFilterId, setTypeFilterId] = useState(readValueFromStorage(keysPrefix + typeFilterIdKey))
  const [sortingId, setSortingId] = useState(readValueFromStorage(keysPrefix + sortingIdKey))
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(keysPrefix + pageSizeKey))
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const sorting = findSorting(sortings, sortingId)
  const tickets = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    conditionString: getTicketsConditionString(
      jwtUser.id,
      condition,
      authorFilterId,
      engineerFilterId,
      createdOnFilterId,
      stateFilterId,
      typeFilterId
    ),
    filterText,
    sortingFieldName: sorting.fieldName,
    sortingDirection: sorting.direction,
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const authors = useClientQuery(client, [], 'Saturn', 'usersFromTickets', {
    userRoleString,
    conditionString: hasValue(condition) ? JSON.stringify(condition) : undefined,
    keyFieldName: 'authorId',
    nameFieldName: 'authorName',
  })

  const engineers = useClientQuery(client, [], 'Saturn', 'usersFromTickets', {
    userRoleString,
    conditionString: hasValue(condition) ? JSON.stringify(condition) : undefined,
    keyFieldName: 'engineerId',
    nameFieldName: 'engineerName',
  })

  useEffect(() => {
    const ticketStatesLocalized = []
    Object.keys(ticketStates).forEach(key => {
      const ticketState = ticketStates[key]
      ticketStatesLocalized.push({
        id: ticketState.id,
        name: l.t('Tickets.States.' + ticketState.name.replace(' ', '')),
      })
    })
    setTicketStatesLocalized(ticketStatesLocalized)
  }, [languageId])

  const ticketTypes = useClientQuery(client, [], 'Saturn', 'ticketTypes', {
    sortingFieldName: 'name',
  })

  const onCreatedOnFilterChange = useCallback(value => {
    writeValueToStorage(keysPrefix + createdOnFilterIdKey, value)
    setCreatedOnFilterId(value)
  }, [])
  const onStateFilterChange = useCallback(value => {
    writeValueToStorage(keysPrefix + stateFilterIdKey, value)
    setStateFilterId(value)
  }, [])
  const onTypeFilterChange = useCallback(value => {
    writeValueToStorage(keysPrefix + typeFilterIdKey, value)
    setTypeFilterId(value)
  }, [])
  const onSortingChange = useCallback(value => {
    writeValueToStorage(keysPrefix + sortingIdKey, value)
    setSortingId(value)
  }, [])
  const onAuthorFilterChange = useCallback(value => {
    writeValueToStorage(keysPrefix + authorFilterIdKey, value)
    setFilterAuthorId(value)
  }, [])
  const onEngineerFilterChange = useCallback(value => {
    writeValueToStorage(keysPrefix + engineerFilterIdKey, value)
    setFilterEngineerId(value)
  }, [])

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, keysPrefix + pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <TicketsList
      {...props}
      tickets={tickets}
      authors={authors}
      engineers={engineers}
      ticketStatesLocalized={ticketStatesLocalized}
      ticketTypes={ticketTypes}
      authorFilterId={authorFilterId}
      engineerFilterId={engineerFilterId}
      createdOnFilterId={createdOnFilterId}
      stateFilterId={stateFilterId}
      typeFilterId={typeFilterId}
      sortingId={sortingId}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onAuthorFilterChange={onAuthorFilterChange}
      onEngineerFilterChange={onEngineerFilterChange}
      onCreatedOnFilterChange={onCreatedOnFilterChange}
      onStateFilterChange={onStateFilterChange}
      onTypeFilterChange={onTypeFilterChange}
      onSortingChange={onSortingChange}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default TicketsListContainer
