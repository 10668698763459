import React, { PureComponent } from 'react'
import { Dialog as LibDialog } from '@primer/react'

export class Dialog extends PureComponent {
  render() {
    const { title, width = '750px', children, onDismiss } = this.props
    return (
      <LibDialog isOpen={true} sx={{ width }} onDismiss={onDismiss}>
        <LibDialog.Header>{title}</LibDialog.Header>
        {children}
      </LibDialog>
    )
  }
}
