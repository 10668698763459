import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { hasValue } from '../../../utils/utils'
import { TextInput } from '../textInput/TextInput'
import { Row } from '../row/Row'
import { ChangeButton, SaveCancelButtons } from '../buttons/ChangingButtons'

const Text = styled.div`
  display: flex;
  font-size: x-large;
  font-weight: normal;
`
Text.displayName = 'Text'

export class AdvancedText extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      changing: false,
    }
  }

  onChangeButtonClick = () => {
    const { value } = this.props
    this.setState({ changing: true, value: value })
  }

  onTextEditorChange = value => {
    this.setState({ value: value })
  }

  onSaveButtonClick = async () => {
    const { value: propsValue, onChange } = this.props
    const { value: stateValue } = this.state
    if (hasValue(onChange) && stateValue !== propsValue) {
      onChange(stateValue)
    }
    this.setState({ changing: false })
  }

  onCancelButtonClick = () => {
    this.setState({ changing: false })
  }

  render() {
    const { id, value: propsValue, allowChanging, style, l } = this.props
    const { changing, value: stateValue } = this.state
    return changing ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', ...style }}>
        <TextInput id={id} value={stateValue} autoFocus={true} width={'100%'} onChange={this.onTextEditorChange} />
        <SaveCancelButtons
          id={id}
          style={{ marginTop: '4px' }}
          l={l}
          onSaveButtonClick={this.onSaveButtonClick}
          onCancelButtonClick={this.onCancelButtonClick}
        />
      </div>
    ) : (
      <Row style={style}>
        <Text id={id}>{propsValue}</Text>
        {allowChanging ? <ChangeButton id={id} size={35} style={{ marginLeft: '8px' }} onClick={this.onChangeButtonClick} /> : null}
      </Row>
    )
  }
}
