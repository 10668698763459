import React, { PureComponent, useState, useEffect } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { employmentRoles } from '../../../common/employmentRoles'
import { View, ViewHeader } from '../../ui/view/View'
import { List } from '../../ui/list/List'
import { useClientQuery } from '../hooks'

export class UserEmploymentsView extends PureComponent {
  constructor(props) {
    super(props)
  }

  getLocalizedRoleName = name => {
    const { l } = this.props
    return l.t('Employments.Roles.' + name)
  }

  onRolesButtonTitleGet = employment => {
    const roleNames = []
    if (employment.agent) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.agent.name))
    }
    if (employment.superagent) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.superagent.name))
    }
    if (employment.client) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.client.name))
    }
    if (employment.superclient) {
      roleNames.push(this.getLocalizedRoleName(employmentRoles.superclient.name))
    }
    return roleNames.join(', ')
  }

  render() {
    const { userId, employments, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('Employments.Employments')} />
        <List
          keyFieldName={'id'}
          columnSettings={[
            { title: l.t('Employments.Company'), fieldName: 'companyName', width: '25%', important: true },
            {
              title: l.t('Employments.Roles.Roles'),
              buttonTitle: this.onRolesButtonTitleGet,
              type: 'button',
              id: 'roles',
              width: '30%',
            },
          ]}
          data={employments}
          onGetItemUrl={employment => {
            return `/user/${userId}/company/${employment.companyId}`
          }}
        />
      </View>
    )
  }
}

const UserEmploymentsViewContainer = props => {
  const { userRole, userId } = props
  const resolverName = 'employmentsForUser'
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, { userRoleString, userId })

  return <UserEmploymentsView {...props} employments={objects} />
}

export default UserEmploymentsViewContainer
