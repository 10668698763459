import React from 'react'
import { Box, Pagehead as LibPagehead } from '@primer/react'

export const Pagehead = props => {
  const { paddingX, marginX, children } = props
  return (
    <LibPagehead
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        alignItems: ['flex-start', 'flex-start', 'center'],
        fontSize: 3,
        fontWeight: 'bold',
        px: paddingX,
        mx: marginX,
      }}
      as={Box}
      children={children}
    />
  )
}
