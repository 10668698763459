import React, { useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { LookupField } from '../../ui'

const LookupFieldContainer = (props) => {
  const { resolverName, resolverArgs = {}, valueFieldName, textFieldName } = props
  const { id, title, allowClear, value, url, mb, onChange } = props
  const [items, setItems] = useState([])
  const client = useClient()
  useEffect(() => {
    client.query({ name: 'Saturn', resolver: resolverName, args: resolverArgs }, (error, result) => {
      if (hasValue(result) && Array.isArray(result.data)) {
        setItems(
          result.data.map((obj) => {
            return {
              value: obj[valueFieldName],
              text: obj[textFieldName],
            }
          })
        )
      } else {
        setItems([])
      }
    })
  }, [...Object.values(resolverArgs)])
  return (
    <LookupField
      id={id}
      title={title}
      items={items}
      allowClear={allowClear}
      value={value}
      url={url}
      mb={mb}
      onChange={onChange}
    />
  )
}

export default LookupFieldContainer
