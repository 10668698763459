import React, { PureComponent } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router'
import styled from 'styled-components'
import { HomeIcon } from '@primer/octicons-react'

import { hasValue } from '../../utils/utils'
import {
  getBackLinkProps,
  getUserRoles,
  readLanguageIdFromStorage,
  writeLanguageIdToStorage,
  readUserRoleIdFromStorage,
  writeUserRoleIdToStorage,
  readThemeColorSettingIdFromStorage,
  writeThemeColorSettingIdToStorage,
} from '../utils'
import { Helmet } from './Helmet'
import { Header, HeaderLink, RootContainer, ThemeProvider, themeColorSettings } from '../ui'
import l from './localizer'

import LoginView from './login/LoginView'
import MagicLinkLoginView from './login/MagicLinkLoginView'
import MagicLinkRegisterView from './login/MagicLinkRegisterView'

import UsersView from '../components/users/UsersView'
import UserView from '../components/users/UserView'
import UserCreatingView from '../components/users/UserCreatingView'

import CompaniesView from '../components/companies/CompaniesView'
import CompanyView from '../components/companies/CompanyView'
import CompanyCreatingView from './companies/CompanyCreatingView'

import GroupView from './groups/GroupView'
import GroupCreatingView from './groups/GroupCreatingView'

import SlasView from '../components/slas/SlasView'
import SlaView from '../components/slas/SlaView'
import SlaCreatingView from '../components/slas/SlaCreatingView'

import CfgItemsView from '../components/cfgItems/CfgItemsView'
import CfgItemView from '../components/cfgItems/CfgItemView'
import CfgItemCreatingView from './cfgItems/CfgItemCreatingView'

import TicketsView from '../components/tickets/TicketsView'
import TicketView from '../components/tickets/ticketView/TicketView'
import TicketCreatingView from '../components/tickets/TicketCreatingView'
import TicketCommentCreatingView from '../components/tickets/TicketCommentCreatingView'
import TicketAcceptingView from '../components/tickets/TicketAcceptingView'
import TicketFeedbackSubmittingView from '../components/tickets/TicketFeedbackSubmittingView'

import TicketTypesView from '../components/ticketTypes/TicketTypesView'
import TicketTypeView from '../components/ticketTypes/TicketTypeView'
import TicketTypeCreatingView from '../components/ticketTypes/TicketTypeCreatingView'

import ServicesView from '../components/services/ServicesView'
import ServiceView from '../components/services/ServiceView'
import ServiceCreatingView from './services/ServiceCreatingView'
import { LanguageSelector } from './LanguageSelector'

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 544px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

export class App extends PureComponent {
  constructor(props) {
    super(props)
    const { history, jwtUser } = props
    this.userRoles = getUserRoles(jwtUser)
    this.state = {
      location: hasValue(history) && hasValue(history.location) ? history.location.pathname : '',
      languageId: this.getLanguageId(),
      userRoleId: this.getUserRoleId(),
      themeColorSettingId: this.getThemeColorSettingId(),
    }
    this.historyUnlisten = null
    l.changeLanguage(this.state.languageId)
  }

  componentDidMount() {
    const { history } = this.props
    if (hasValue(history)) {
      this.historyUnlisten = history.listen(this.onHistoryChanged)
    }
  }

  componentWillUnmount() {
    if (hasValue(this.historyUnlisten)) {
      this.historyUnlisten()
    }
  }

  onHistoryChanged = args => {
    this.setState({ location: args.pathname })
  }

  renderLoginRoute = () => {
    const { jwtUser } = this.props
    const { languageId } = this.state
    return (
      <Route
        key={'login'}
        path={'/login'}
        render={props => {
          const params = new URLSearchParams(hasValue(props.location) ? props.location.search : '')
          const errorText = params.get('error')
          // The languageId passing forces the Component redrawing after the language changing.
          return hasValue(jwtUser) && hasValue(jwtUser.id) ? <Redirect to={'/'} /> : <LoginView errorText={errorText} languageId={languageId} l={l} />
        }}
      />
    )
  }

  renderMagicLinkLoginRoute = () => {
    const { jwtUser } = this.props
    return (
      <Route
        key={'magicLinkLogin'}
        path={'/magic-link-login'}
        render={props => {
          return hasValue(jwtUser) && hasValue(jwtUser.id) ? <Redirect to={'/'} /> : <MagicLinkLoginView l={l} />
        }}
      />
    )
  }

  renderMagicLinkRegisterRoute = () => {
    return <Route key={'magicLinkRegister'} path={'/magic-link-register'} render={props => <MagicLinkRegisterView l={l} />} />
  }

  renderRedirect = (key, path, redirectTo, exact = false) => {
    return (
      <Route
        key={key}
        path={path}
        exact={exact}
        render={props => {
          return <Redirect to={redirectTo} />
        }}
      />
    )
  }

  getUserRole = () => {
    const { userRoleId } = this.state
    return this.userRoles.find(userRole => userRole.value === userRoleId)
  }

  renderPrivateRoute = (key, path, component, exact = false) => {
    const { jwtUser, history } = this.props
    const { languageId } = this.state
    const Component = component
    return (
      <Route
        key={key}
        path={path}
        exact={exact}
        render={props => {
          return hasValue(jwtUser) && hasValue(jwtUser.id) ? (
            // The languageId passing forces the Component redrawing after the language changing.
            <Component jwtUser={jwtUser} userRole={this.getUserRole()} history={history} l={l} languageId={languageId} />
          ) : (
            <Redirect to={'/login'} />
          )
        }}
      />
    )
  }

  renderRoutes = () => {
    return (
      <Switch>
        {this.renderLoginRoute()}
        {this.renderMagicLinkLoginRoute()}
        {this.renderMagicLinkRegisterRoute()}
        {this.renderRedirect('root', '/', '/tickets', true)}

        {this.renderPrivateRoute('users', '/users', UsersView)}
        {this.renderPrivateRoute('user', '/user/:userId', UserView, true)}
        {this.renderPrivateRoute('userCreating', '/userCreating', UserCreatingView)}
        {this.renderPrivateRoute('user-company', '/user/:userId/company/:companyId', CompanyView)}

        {this.renderPrivateRoute('companies', '/companies', CompaniesView)}
        {this.renderPrivateRoute('company', '/company/:companyId', CompanyView, true)}
        {this.renderPrivateRoute('company-tab', '/company/:companyId/tab/:tab', CompanyView)}
        {this.renderPrivateRoute('companyCreating', '/companyCreating', CompanyCreatingView)}
        {this.renderPrivateRoute('company-user', '/company/:companyId/user/:userId', UserView)}
        {this.renderPrivateRoute('company-userCreating', '/company/:companyId/userCreating', UserCreatingView)}
        {this.renderPrivateRoute('company-cfgItem', '/company/:companyId/cfgItem/:cfgItemId', CfgItemView)}
        {this.renderPrivateRoute('company-cfgItemCreating', '/company/:companyId/cfgItemCreating', CfgItemCreatingView)}
        {this.renderPrivateRoute('company-sla', '/company/:companyId/sla/:slaId', SlaView)}
        {this.renderPrivateRoute('company-group', '/company/:companyId/group/:groupId', GroupView)}
        {this.renderPrivateRoute('company-groupCreating', '/company/:companyId/groupCreating', GroupCreatingView)}

        {this.renderPrivateRoute('slas', '/slas', SlasView)}
        {this.renderPrivateRoute('sla', '/sla/:slaId', SlaView)}
        {this.renderPrivateRoute('slaCreating', '/slaCreating', SlaCreatingView)}

        {this.renderPrivateRoute('cfgItems', '/cfgItems', CfgItemsView)}
        {this.renderPrivateRoute('cfgItem', '/cfgItem/:cfgItemId', CfgItemView)}
        {this.renderPrivateRoute('cfgItemCreating', '/cfgItemCreating', CfgItemCreatingView)}

        {this.renderPrivateRoute('tickets', '/tickets', TicketsView)}
        {this.renderPrivateRoute('ticket', '/ticket/:ticketId', TicketView, true)}
        {this.renderPrivateRoute('ticketCreating', '/ticketCreating', TicketCreatingView)}
        {this.renderPrivateRoute('ticket-commentCreating', '/ticket/:ticketId/commentCreating/:mode', TicketCommentCreatingView)}
        {this.renderPrivateRoute('ticket-accepting', '/ticket/:ticketId/accepting', TicketAcceptingView)}
        {this.renderPrivateRoute('ticket-feedbackSubmitting', '/ticket/:ticketId/feedbackSubmitting', TicketFeedbackSubmittingView, true)}

        {this.renderPrivateRoute('ticketTypes', '/ticketTypes', TicketTypesView)}
        {this.renderPrivateRoute('ticketType', '/ticketType/:ticketTypeId', TicketTypeView)}
        {this.renderPrivateRoute('ticketTypeCreating', '/ticketTypeCreating', TicketTypeCreatingView)}

        {this.renderPrivateRoute('services', '/services', ServicesView)}
        {this.renderPrivateRoute('service', '/service/:serviceId', ServiceView)}
        {this.renderPrivateRoute('serviceCreating', '/serviceCreating', ServiceCreatingView)}
      </Switch>
    )
  }

  getLanguageId = () => {
    let result = readLanguageIdFromStorage(l.languages[0])
    return result
  }

  getUserRoleId = () => {
    let result = readUserRoleIdFromStorage()
    if (!this.userRoles.some(userRole => userRole.value === result)) {
      result = null
    }
    if (!hasValue(result)) {
      result = this.userRoles.length > 0 ? this.userRoles[0].value : null
    }
    return result
  }

  getThemeColorSettingId = () => {
    let result = readThemeColorSettingIdFromStorage(themeColorSettings[0].id)
    return result
  }

  getLanguages = () => {
    const result = []
    Object.keys(l.options.resources).forEach(key => {
      result.push({
        id: key,
        name: l.options.resources[key].name,
      })
    })
    return result
  }

  onUserRoleChange = value => {
    writeUserRoleIdToStorage(value)
    this.setState({ userRoleId: value })
  }

  onThemeColorSettingChange = value => {
    writeThemeColorSettingIdToStorage(value)
    this.setState({ themeColorSettingId: value })
  }

  onLanguageChange = value => {
    writeLanguageIdToStorage(value)
    l.changeLanguage(value)
    this.setState({ languageId: value })
  }

  renderLogo() {
    const { location } = this.state
    const { url } = getBackLinkProps(location)
    return (
      <HeaderLink id={'backLink'} url={url}>
        <HomeIcon size={24} />
      </HeaderLink>
    )
  }

  getLinksMenuItems() {
    const userRole = this.getUserRole()
    const result = [
      { id: 'tickets', text: l.t('Header.Tickets'), url: '/tickets' },
      { id: 'companies', text: l.t('Header.Companies'), url: '/companies' },
      { id: 'slas', text: l.t('Header.Slas'), url: '/slas' },
      { id: 'cfgItems', text: l.t('Header.CfgItems'), url: '/cfgItems' },
    ]
    if (hasValue(userRole) && userRole.superadmin) {
      result.push({ id: 'ticketTypes', text: l.t('Header.TicketTypes'), url: '/ticketTypes' })
      result.push({ id: 'users', text: l.t('Header.Users'), url: '/users' })
    }
    return result
  }

  renderAvatar = () => {
    const { jwtUser } = this.props
    let result = null
    if (hasValue(jwtUser) && hasValue(jwtUser.id) && hasValue(jwtUser.avatar) && jwtUser.avatar !== '') {
      result = <img alt={jwtUser.name} src={jwtUser.avatar} style={{ borderRadius: '50%' }} width={'24px'} height={'24px'} />
    }
    return result
  }

  getUserMenuItems = () => {
    const { jwtUser } = this.props
    const { languageId, userRoleId, themeColorSettingId } = this.state
    const result = []
    result.push({ text: l.t('UserMenu.Role'), groupHeader: true })
    this.userRoles.forEach(userRole => {
      result.push({
        text: userRole.text,
        selected: userRole.value === userRoleId,
        onClick: () => {
          this.onUserRoleChange(userRole.value)
        },
      })
    })
    result.push({ text: l.t('UserMenu.Theme'), groupHeader: true })
    themeColorSettings.forEach(themeColorSetting => {
      result.push({
        text: themeColorSetting.name,
        selected: themeColorSettingId === themeColorSetting.id,
        onClick: () => {
          this.onThemeColorSettingChange(themeColorSetting.id)
        },
      })
    })
    result.push({ text: l.t('UserMenu.Language'), groupHeader: true })
    this.getLanguages().forEach(language => {
      result.push({
        text: language.name,
        selected: languageId === language.id,
        onClick: () => {
          this.onLanguageChange(language.id)
        },
      })
    })
    result.push({ text: l.t('UserMenu.User'), groupHeader: true })
    if (hasValue(jwtUser) && hasValue(jwtUser.id)) {
      result.push({ text: l.t('UserMenu.Settings'), url: `/user/${jwtUser.id}` })
      result.push({ text: l.t('UserMenu.LogOut'), url: '/api/auth/logout' })
    } else {
      result.push({ text: l.t('UserMenu.LogIn'), url: '/login' })
    }
    return result
  }

  render() {
    const { history, jwtUser } = this.props
    const { themeColorSettingId } = this.state
    const logo = this.renderLogo()
    const linksMenuItems = this.getLinksMenuItems()
    const avatar = this.renderAvatar()
    const userMenuItems = this.getUserMenuItems()
    return (
      <ThemeProvider colorSettingId={themeColorSettingId}>
        <Helmet />
        <Router history={history}>
          <RootContainer>
            {hasValue(jwtUser) ? (
              <Header
                logo={logo}
                linksMenuItems={linksMenuItems}
                avatar={avatar}
                userName={hasValue(jwtUser) ? l.t('SignedIn').replace('{userName}', jwtUser.name) : null}
                userMenuItems={userMenuItems}
              />
            ) : (
              <LanguageSelector languages={this.getLanguages()} onChange={this.onLanguageChange} />
            )}
            <ViewContainer>{this.renderRoutes()}</ViewContainer>
          </RootContainer>
        </Router>
      </ThemeProvider>
    )
  }
}
