import React, { PureComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useClient, useCommand, useCommandBuilder, useViewModel } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue, ifHasValue } from '../../../utils/utils'
import { executeCommand, companyEmploymentsTabId, companyCfgItemsTabId, companySlasTabId, companyGroupsTabId } from '../../utils'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedTextInput } from '../../ui/textInput/AdvancedTextInput'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { Box, Button, ButtonsRow, Label, Link, Panel, PanelColumn, Row, Text } from '../../ui'
import CompanyEmploymentsView from './CompanyEmploymentsView'
import CompanyCfgItemsView from './CompanyCfgItemsView'
import CompanySlasView from './CompanySlasView'
import CompanyGroupsView from './CompanyGroupsView'
import { useClientQuery } from '../hooks'

export class CompanyView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { nameChanging: false }
  }

  onEditNameButtonClick = () => {
    this.setState({ nameChanging: true })
  }

  onNameTextInputSave = async (value, changed) => {
    const { commands } = this.props
    if (changed) {
      await executeCommand(commands.companyRename, value)
    }
    this.setState({ nameChanging: false })
  }

  onNameTextInputCancel = () => {
    this.setState({ nameChanging: false })
  }

  onActivateButtonClick = async () => {
    const { commands } = this.props
    if (confirm('Do you want to activate the company?')) {
      await executeCommand(commands.companyActivate, {})
    }
  }

  onDeactivateButtonClick = async () => {
    const { commands } = this.props
    if (confirm('Do you want to deactivate the company?')) {
      await executeCommand(commands.companyDeactivate, {})
    }
  }

  onSuperagentChange = async value => {
    const { commands } = this.props
    if (value) {
      if (confirm('Do you want to set the superagent role for the company?')) {
        await executeCommand(commands.companySetSuperagent, {})
      }
    } else {
      if (confirm('Do you want to clear the superagent role for the company?')) {
        await executeCommand(commands.companyClearSuperagent, {})
      }
    }
  }

  renderCreationInfo = (authorId, authorName, createdOn) => {
    const { l } = this.props
    return (
      <Box display={'block'}>
        <Link url={`/user/${authorId}`} color={'fg.muted'} fontSize={1} fontWeight={'bold'}>
          {authorName}
        </Link>
        <Text id={'details'} color={'fg.muted'} fontSize={1} fontWeight={'normal'} ml={1}>
          {l.t('Companies.CreatedOn').replace('{datetime}', getLocalDateTimeString(createdOn))}
        </Text>
      </Box>
    )
  }

  renderHeaderContent = () => {
    const { userRole, companyId, company, l } = this.props
    const { nameChanging } = this.state
    const allowChanging = hasValue(userRole) && ((userRole.companyId === companyId && userRole.superclient) || userRole.superadmin)
    return (
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {nameChanging ? (
          <AdvancedTextInput id={'name'} value={company.name} l={l} onSave={this.onNameTextInputSave} onCancel={this.onNameTextInputCancel} />
        ) : (
          <Box
            display={'flex'}
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent={'space-between'}
          >
            <span id={'name'}>{company.name}</span>
            <ButtonsRow>
              {allowChanging ? (
                <Button id={'edit'} variant={'small'} title={l.t('Companies.Actions.Edit')} onClick={this.onEditNameButtonClick} />
              ) : null}
              {allowChanging && !company.active ? (
                <Button id={'activate'} variant={'small'} title={l.t('Companies.Actions.Activate')} onClick={this.onActivateButtonClick} />
              ) : null}
              {allowChanging && company.active ? (
                <Button id={'deactivate'} variant={'small'} title={l.t('Companies.Actions.Deactivate')} onClick={this.onDeactivateButtonClick} />
              ) : null}
            </ButtonsRow>
          </Box>
        )}
        <Box display={'flex'} flexDirection={'row'} alignItems={'base-line'} flexWrap={'wrap'} mt={2}>
          {company.active ? (
            <Label id={'active'} variant={'xl'} mr={2}>
              {l.t('Companies.Active')}
            </Label>
          ) : null}
          {this.renderCreationInfo(company.authorId, company.authorName, company.createdOn)}
        </Box>
      </Box>
    )
  }

  renderTabButton = (tabId, title) => {
    const { companyId, selectedTabId, history } = this.props
    return (
      <Button
        id={tabId}
        variant={'small'}
        title={title}
        style={{
          fontWeight: 'bold',
          color: ifHasValue(selectedTabId, companyEmploymentsTabId) === tabId ? 'rgb(255, 255, 255)' : 'rgb(115, 135, 159)',
          backgroundColor: ifHasValue(selectedTabId, companyEmploymentsTabId) === tabId ? 'rgb(44, 151, 75)' : 'rgb(255, 255, 255)',
        }}
        onClick={() => {
          history.push(`/company/${companyId}/tab/${tabId}`)
        }}
      />
    )
  }

  render() {
    const { jwtUser, userRole, companyId, company, selectedTabId, history, l } = this.props
    const allowSuperagentChanging = hasValue(userRole) && userRole.superadmin
    return (
      <View>
        <ViewHeader content={this.renderHeaderContent()} />
        <Panel>
          <PanelColumn>
            <BooleanEditor
              id={'superagent'}
              caption={l.t('Companies.Superagent')}
              value={company.superagent}
              readOnly={!allowSuperagentChanging}
              onChange={this.onSuperagentChange}
            />
          </PanelColumn>
        </Panel>
        <MobileIndent>
          <Row id={'tabSelector'} style={{ padding: '8px 8px 8px 0px', backgroundColor: 'rgb(255, 255, 255)', marginTop: '16px' }}>
            {this.renderTabButton(companyEmploymentsTabId, l.t('Employments.Employments'))}
            {this.renderTabButton(companyCfgItemsTabId, l.t('CfgItems.CfgItems'))}
            {this.renderTabButton(companySlasTabId, l.t('Slas.Slas'))}
            {this.renderTabButton(companyGroupsTabId, l.t('Groups.Groups'))}
          </Row>
        </MobileIndent>
        <CompanyEmploymentsView
          jwtUser={jwtUser}
          userRole={userRole}
          companyId={companyId}
          history={history}
          visible={!hasValue(selectedTabId) || selectedTabId === companyEmploymentsTabId}
          l={l}
        />
        <CompanyCfgItemsView userRole={userRole} companyId={companyId} history={history} visible={selectedTabId === companyCfgItemsTabId} l={l} />
        <CompanySlasView userRole={userRole} companyId={companyId} visible={selectedTabId === companySlasTabId} l={l} />
        <CompanyGroupsView userRole={userRole} companyId={companyId} history={history} visible={selectedTabId === companyGroupsTabId} l={l} />
      </View>
    )
  }
}

const CompanyViewContainer = props => {
  const { userRole } = props
  const resolverName = 'companyById'
  const viewModelName = 'CompanyVm'
  const { companyId, tab } = useParams()
  const userRoleString = JSON.stringify(userRole)

  // Company read model.
  const client1 = useClient()
  const object1 = useClientQuery(client1, {}, 'Saturn', resolverName, { userRoleString, companyId })

  // Company view model.
  const [object2, setObject2] = useState({})
  const { connect, dispose } = useViewModel(viewModelName, [companyId], setObject2)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])

  const companyRename = useCommandBuilder(name => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'rename', payload: { name } }
  })
  const companyActivate = useCommand({ aggregateName: 'Company', aggregateId: companyId, type: 'activate' })
  const companyDeactivate = useCommand({ aggregateName: 'Company', aggregateId: companyId, type: 'deactivate' })
  const companySetSuperagent = useCommand({ aggregateName: 'Company', aggregateId: companyId, type: 'setSuperagent' })
  const companyClearSuperagent = useCommand({ aggregateName: 'Company', aggregateId: companyId, type: 'clearSuperagent' })

  return (
    <CompanyView
      {...props}
      companyId={companyId}
      company={{ ...object1, ...object2 }}
      selectedTabId={tab}
      commands={{ companyRename, companyActivate, companyDeactivate, companySetSuperagent, companyClearSuperagent }}
    />
  )
}

export default CompanyViewContainer
