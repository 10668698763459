import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Pagination, Row } from '..'
import { LookupInput } from '../lookupInput/LookupInput'
import { hasValue, ifHasValue } from '../../../utils/utils'

const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const pageSizeItems = [
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
  { value: 250, text: '250' },
  { value: 500, text: '500' },
  { value: 1000, text: '1000' },
]

export class Pager extends PureComponent {
  raiseOnCurrentPageIndexChange = currentPageIndex => {
    const { onCurrentPageIndexChange } = this.props
    if (hasValue(onCurrentPageIndexChange)) {
      onCurrentPageIndexChange(currentPageIndex)
    }
  }

  raiseOnPageSizeChange = pageSize => {
    const { onPageSizeChange } = this.props
    if (hasValue(onPageSizeChange)) {
      onPageSizeChange(pageSize)
    }
  }

  onPaginationPageChange = (e, pageIndex) => {
    e.preventDefault()
    this.raiseOnCurrentPageIndexChange(pageIndex - 1)
  }

  onPageSizeChange = value => {
    this.raiseOnPageSizeChange(value)
  }

  render() {
    const { id, currentPageIndex, pageCount, pageSize, previousLabel, nextLabel, pageSizeLabel } = this.props
    return (
      <RootDiv>
        <Pagination
          pageCount={pageCount}
          currentPage={currentPageIndex + 1}
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          onPageChange={this.onPaginationPageChange}
        />
        <Row style={{ marginTop: '20px', marginBottom: '15px' }}>
          <div style={{ marginRight: '4px' }}>{pageSizeLabel}</div>
          <LookupInput
            id={ifHasValue(id, '') + '.pageSize'}
            items={pageSizeItems}
            value={pageSize}
            onChange={this.onPageSizeChange}
          />
        </Row>
      </RootDiv>
    )
  }
}
