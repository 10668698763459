import React, { PureComponent } from 'react'

import { TextInput as LibTextInput } from '../../ui'

import { getDateFromLocalIsoDateString, getLocalIsoDateString, hasValue } from '../../../utils/utils'

export class TextInput extends PureComponent {
  raiseOnChange = (value) => {
    const { type, onChange } = this.props
    let correctValue = value
    if (type === 'date') {
      correctValue = getDateFromLocalIsoDateString(value).valueOf()
    }
    if (hasValue(onChange)) {
      onChange(correctValue)
    }
  }

  onTextInputBlur = (value) => {
    const { type } = this.props
    // This patch is for the date inputs management in TestCafe tests.
    if (type === 'date') {
      this.raiseOnChange(value)
    }
  }

  onTextInputChange = (value) => {
    this.raiseOnChange(value)
  }

  onTextAreaChange = (e) => {
    this.raiseOnChange(e.target.value)
  }

  render() {
    const {
      id,
      name,
      type,
      placeholder,
      multiLines,
      autoFocus,
      value,
      readOnly,
      width,
      backgroundColor,
      marginTop,
      marginRight,
      style,
    } = this.props
    if (multiLines) {
      const style1 = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'LightGray',
        height: '150px',
        backgroundColor: backgroundColor,
        fontSize: 'small',
        resize: 'none',
      }
      return (
        <textarea
          id={id}
          name={name}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          style={{ ...style1, ...style }}
          onChange={this.onTextAreaChange}
        />
      )
    } else {
      const inputType = hasValue(type) ? type : 'text'
      let correctValue = value
      if (type === 'date') {
        correctValue = getLocalIsoDateString(value)
      }
      return (
        <LibTextInput
          id={id}
          name={name}
          type={inputType}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={correctValue}
          readOnly={readOnly}
          backgroundColor={backgroundColor}
          width={width}
          mt={marginTop}
          mr={marginRight}
          onBlur={this.onTextInputBlur}
          onChange={this.onTextInputChange}
        />
      )
    }
  }
}
