import React, { PureComponent } from 'react'

import { View, ViewHeader } from '../../ui/view/View'
import { Button, buttonKind, ButtonsRow, FilterTextInput } from '../../ui'
import CompaniesList from './CompaniesList'

export class CompaniesView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { filterText: null }
  }

  onFilterTextInputChange = value => {
    this.setState({ filterText: value })
  }

  onCreateButtonClick = () => {
    const { history } = this.props
    history.push('/companyCreating')
  }

  renderButtons = () => {
    const { l } = this.props
    return (
      <ButtonsRow>
        <FilterTextInput placeholder={l.t('Companies.Actions.FilterPlaceholder')} onChange={this.onFilterTextInputChange} />
        <Button
          id={'create'}
          kind={buttonKind.primary}
          variant={'small'}
          title={l.t('Companies.Actions.Create')}
          onClick={this.onCreateButtonClick}
        />
      </ButtonsRow>
    )
  }

  render() {
    const { jwtUser, userRole, l, languageId } = this.props
    const { filterText } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Companies.Companies')} buttons={this.renderButtons()} />
        <CompaniesList jwtUser={jwtUser} userRole={userRole} filterText={filterText} l={l} languageId={languageId} />
      </View>
    )
  }
}

export default CompaniesView
