import React, { PureComponent } from 'react'
import { hasValue } from '../../../utils/utils'

import { Row } from '../row/Row'
import { Button, buttonKind, ImageButton } from '..'

export class ChangingButtons extends PureComponent {
  render() {
    const { changing, onChangeButtonClick, onSaveButtonClick, onCancelButtonClick, l } = this.props
    return changing ? (
      <SaveCancelButtons
        style={{ justifyContent: 'flex-end' }}
        l={l}
        onSaveButtonClick={onSaveButtonClick}
        onCancelButtonClick={onCancelButtonClick}
      />
    ) : (
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          id={'change'}
          variant={'small'}
          title={l.t('ChangingButtons.Change')}
          style={{ padding: '4px 4px 4px 4px', width: 'auto', height: 'auto' }}
          onClick={onChangeButtonClick}
        />
      </Row>
    )
  }
}

export class ChangeButton extends PureComponent {
  render() {
    const { id, size, style, onClick } = this.props
    return (
      <ImageButton
        id={id + '.change'}
        imageUrl={'/edit.png'}
        width={`${size}px`}
        height={`${size}px`}
        style={{
          borderRadius: '50%',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'LightGray',
          ...style,
        }}
        onClick={onClick}
      />
    )
  }
}

export class SaveCancelButtons extends PureComponent {
  render() {
    const { id, style, l, onSaveButtonClick, onCancelButtonClick } = this.props
    return (
      <Row style={style}>
        <Button id={hasValue(id) ? `${id}.save` : 'save'} title={l.t('ChangingButtons.Save')} onClick={onSaveButtonClick} />
        <Button
          id={hasValue(id) ? `${id}.cancel` : 'cancel'}
          kind={buttonKind.invisible}
          title={l.t('ChangingButtons.Cancel')}
          ml={2}
          onClick={onCancelButtonClick}
        />
      </Row>
    )
  }
}
