import React, { PureComponent } from 'react'
import { useParams } from 'react-router'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { LookupEditor } from '../../ui/lookupEditor/LookupEditor'
import { Button, buttonKind, Row } from '../../ui'
import { ticketFeedbackRatings } from '../../../common/ticketFeedbackRatings'

export class TicketFeedbackSubmittingView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { rating: null, description: '' }
  }

  onRatingChange = value => {
    this.setState({ rating: value })
  }

  onCommentChange = value => {
    this.setState({ description: value })
  }

  onSubmitButtonClick = async () => {
    const { history, ticketSubmitFeedback } = this.props
    const { rating, description } = this.state
    await executeCommand(ticketSubmitFeedback, { rating, description })
    history.goBack()
  }

  render() {
    const { l } = this.props
    const { rating, description } = this.state
    const ratingItems = ticketFeedbackRatings.map(ticketFeedbackRating => {
      return { value: ticketFeedbackRating, text: ticketFeedbackRating }
    })
    return (
      <View>
        <ViewHeader text={l.t('Tickets.Feedback')} />
        <LookupEditor
          id={'rating'}
          caption={l.t('Tickets.Rating')}
          items={ratingItems}
          addEmptyItem={true}
          value={rating}
          autoFocus={true}
          onChange={this.onRatingChange}
        />
        <TextEditor
          id={'description'}
          caption={l.t('Tickets.FeedbackDescription')}
          value={description}
          multiLines={true}
          onChange={this.onCommentChange}
        />
        <Row>
          <Button variant={'small'} kind={buttonKind.primary} title={l.t('Tickets.Actions.Submit')} onClick={this.onSubmitButtonClick} />
        </Row>
      </View>
    )
  }
}

const TicketFeedbackSubmittingViewContainer = props => {
  const { ticketId } = useParams()
  const ticketSubmitFeedback = useCommandBuilder(({ rating, description }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'submitFeedback',
      payload: { rating, description },
    }
  })
  return <TicketFeedbackSubmittingView {...props} ticketSubmitFeedback={ticketSubmitFeedback} />
}

export default TicketFeedbackSubmittingViewContainer
