import { useState, useMemo, useCallback, useEffect } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../utils/utils'

export const usePermissionJwt = (resolverName, args) => {
  const [permissionJwt, setPermissionJwt] = useState(null)
  const client = useClient()
  useMemo(() => {
    client.query({ name: 'Saturn', resolver: resolverName, args }, (error, result) => {
      if (hasValue(result)) {
        setPermissionJwt(result.data)
      }
    })
  }, Object.values(args))
  return permissionJwt
}

export const useClientQuery = (client, initialValue, readmodelName, resolverName, args) => {
  const [queryResult, setQueryResult] = useState(initialValue)
  useEffect(() => {
    client.query(
      {
        name: readmodelName,
        resolver: resolverName,
        args,
      },
      (error, result) => {
        if (hasValue(result)) {
          setQueryResult(result.data)
        }
      }
    )
  }, Object.values(args))

  return queryResult
}
