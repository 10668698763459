import React, { useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { LookupEditor } from '../../ui/lookupEditor/LookupEditor'

const LookupEditorContainer = props => {
  const { resolverName, resolverArgs = {}, valueFieldName, textFieldName, onItemTextGet } = props
  const { id, caption, value, autoFocus, readOnly, style, onChange } = props
  const [items, setItems] = useState([])
  const client = useClient()
  useEffect(() => {
    client.query({ name: 'Saturn', resolver: resolverName, args: resolverArgs }, (error, result) => {
      if (hasValue(result) && Array.isArray(result.data)) {
        setItems(
          result.data.map(obj => {
            return {
              value: obj[valueFieldName],
              text: hasValue(onItemTextGet) ? onItemTextGet(obj) : obj[textFieldName],
            }
          })
        )
      } else {
        setItems([])
      }
    })
  }, [...Object.values(resolverArgs)])
  return (
    <LookupEditor
      id={id}
      caption={caption}
      items={items}
      addEmptyItem={true}
      value={value}
      autoFocus={autoFocus}
      readOnly={readOnly}
      style={style}
      onChange={onChange}
    />
  )
}

export default LookupEditorContainer
