import React, { PureComponent } from 'react'
import { ThemeProvider as LibThemeProvider, BaseStyles, theme } from '@primer/react'

import { hasValue } from './utils'

export const themeColorSettings = [
  { id: 'auto', name: 'Auto', colorMode: 'auto', dayScheme: 'light', nightScheme: 'dark_dimmed' },
  { id: 'light1', name: 'Light', colorMode: 'day', dayScheme: 'light' },
  { id: 'light2', name: 'Light colorblind', colorMode: 'day', dayScheme: 'light_colorblind' },
  { id: 'dark1', name: 'Dark', colorMode: 'night', nightScheme: 'dark' },
  { id: 'dark2', name: 'Dark dimmed', colorMode: 'night', nightScheme: 'dark_dimmed' },
  { id: 'dark4', name: 'Dark high contrast', colorMode: 'night', nightScheme: 'dark_high_contrast' },
  { id: 'dark3', name: 'Dark colorblind', colorMode: 'night', nightScheme: 'dark_colorblind' },
]

export class ThemeProvider extends PureComponent {
  render() {
    const { colorSettingId, children } = this.props
    let themeColorSetting = themeColorSettings.find(themeColorSetting => {
      return themeColorSetting.id === colorSettingId
    })
    if (!hasValue(themeColorSetting)) {
      themeColorSetting = {}
    }
    return (
      <LibThemeProvider
        theme={{ ...theme, breakpoints: ['544px', '768px'] }}
        colorMode={themeColorSetting.colorMode}
        dayScheme={themeColorSetting.dayScheme}
        nightScheme={themeColorSetting.nightScheme}
      >
        <BaseStyles children={children} />
      </LibThemeProvider>
    )
  }
}
