import localizer, { InitOptions } from 'i18next'

localizer.init({
  resources: {
    en: {
      name: 'English',
      translation: {
        LoginView: {
          Title: 'Log in to Saturn',
          Email: 'Email',
          Password: 'Password',
          Login: 'Log in',
          Or: 'Or',
          LoginWithGoogle: 'Log in with Google',
          LoginWithMagicLink: 'Log in with Magic Link',
        },
        MagicLinkLoginView: {
          Title: 'Log in with Magic Link',
          Email: 'Email',
          EmailPlaceholder: 'Type the email',
          CheckYourInbox: 'Check your inbox messages!',
          Login: 'Log in',
        },
        MagicLinkRegisterView: {
          Title: 'Create a new account',
          Name: 'Name',
          NamePlaceholder: 'Type the user name',
          AgreementConfirmation: 'I undertake to comply with all the rules.',
          Login: 'Log in',
        },
        Header: {
          Tickets: 'Tickets',
          Companies: 'Companies',
          Slas: 'SLAs',
          CfgItems: 'Cfg Items',
          TicketTypes: 'Ticket Types',
          Users: 'Users',
        },
        SignedIn: 'Signed in as {userName}',
        UserMenu: {
          Role: 'Role',
          Theme: 'Theme',
          Language: 'Language',
          User: 'User',
          Settings: 'Settings',
          LogOut: 'Log out',
          LogIn: 'Log in',
        },
        Users: {
          Users: 'Users',
          User: 'User',
          Company: 'Company',
          Name: 'Name',
          Email: 'Email',
          Superadmin: 'Superadmin',
          CreatedOn: 'Created On',
          PasswordPlaceholder: 'Type the password text and press the Set button',
          Actions: {
            Create: 'Create',
            Set: 'Set',
            FilterPlaceholder: 'Type text to filter the users list',
          },
        },
        Companies: {
          Companies: 'Companies',
          Company: 'Company',
          Name: 'Name',
          Active: 'Active',
          Superagent: 'Superagent',
          CreatedOn: 'created the company on {datetime}',
          Actions: {
            Create: 'Create',
            Edit: 'Edit',
            Activate: 'Activate',
            Deactivate: 'Deactivate',
            FilterPlaceholder: 'Type text to filter the companies list',
          },
          FiltersPanel: {
            Unknown: 'Unknown',
            Activity: 'Activity: {text}',
            Sorting: 'Sorting: {text}',
          },
          Toolbar: {
            All: 'All',
            Filter: 'Filter',

            Activity: 'Activity',
            FilterByActive: 'Filter by Active',
            Active: 'Active',
            Inactive: 'Inactive',

            Sorting: 'Sorting',
            SortBy: 'Sort by',
            AB: 'A -> B',
            BA: 'B -> A',
            Newest: 'Newest',
            Oldest: 'Oldest',
          },
        },
        Employments: {
          Employments: 'Employments',
          Company: 'Company',
          Name: 'Name',
          Email: 'Email',
          Roles: {
            Roles: 'Roles',
            Agent: 'Agent',
            Superagent: 'Superagent',
            Client: 'Client',
            Superclient: 'Superclient',
            Done: 'Done',
          },
          Actions: {
            Create: 'Create',
            Add: 'Add',
            SendLink: 'Send Link',
            Remove: 'Remove',
            AddAndSendLinkDescription: 'Type an email and press the Add or Send Link button.',
          },
        },
        Groups: {
          Groups: 'Groups',
          Group: 'Group',
          Name: 'Name',
          Actions: {
            Create: 'Create',
            Remove: 'Remove',
            RemoveConfirmation: 'Do you want to remove the "{groupName}" group?',
          },
        },
        CfgItems: {
          CfgItems: 'Configuration Items',
          CfgItem: 'Configuration Item',
          Name: 'Name',
          CreatedOn: 'Created On',
          Company: 'Company',
          Active: 'Active',
          Actions: {
            Create: 'Create',
            Remove: 'Remove',
            Pause: 'Pause',
            Resume: 'Resume',
            ActivateConfirmation: 'Do you want to activate the configuration item?',
            DeactivateConfirmation: 'Do you want to deactivate the configuration item?',
            FilterPlaceholder: 'Type text to filter the configuration items list',
          },
        },
        Services: {
          Services: 'Services',
          Service: 'Service',
          Name: 'Name',
          Actions: {
            Create: 'Create',
          },
        },
        Slas: {
          Slas: 'SLAs',
          Sla: 'SLA',
          CreatedOn: 'Created On',
          Subject: 'Subject',
          Agent: 'Agent',
          Client: 'Client',
          Service: 'Service',
          From: 'From',
          To: 'To',
          Active: 'Active',
          Actions: {
            Create: 'Create',
            FilterPlaceholder: 'Type text to filter the SLAs list',
          },
        },
        TicketTypes: {
          TicketTypes: 'Ticket Types',
          TicketType: 'Ticket Type',
          CreatedOn: 'Created On',
          Name: 'Name',
          Color: 'Color',
          Actions: {
            Create: 'Create',
          },
        },
        Tickets: {
          Tickets: 'Tickets',
          Ticket: 'Ticket',
          Author: 'Author',
          Client: 'Client',
          Contact: 'Contact',
          Agent: 'Agent',
          Engineer: 'Engineer',
          Sla: 'SLA',
          Subject: 'Subject',
          Description: 'Description',
          Type: 'Type',
          WorkReports: 'Work reports',
          WorkReportsTotal: 'Total',
          CreatedOn: 'created the ticket on {datetime}',
          CommentsCount: '{count} comment(s)',
          CommentDescription: 'Description',
          SpentTime: 'Spent Time',
          Visibility: 'Visibility',
          Internal: 'Internal',
          External: 'External',
          Feedback: 'Feedback',
          Rating: 'Rating',
          FeedbackDescription: 'Description',
          Actions: {
            Create: 'Create',
            Take: 'Take',
            Edit: 'Edit',
            Clarify: 'Clarify',
            Comment: 'Comment',
            Refuse: 'Refuse',
            RefuseConfirmation: 'Do you want to refuse the ticket?',
            Return: 'Return',
            ReturnConfirmation: 'Do you want to return the refused ticket?',
            Accept: 'Accept',
            AcceptConfirmation: 'Do you want to accept the ticket?',
            Start: 'Start',
            StartConfirmation: 'Do you want to start the ticket?',
            Pause: 'Pause',
            PauseConfirmation: 'Do you want to pause the ticket?',
            Resume: 'Resume',
            ResumeConfirmation: 'Do you want to resume the ticket?',
            CreateReport: 'Create Report',
            Complete: 'Complete',
            Reject: 'Reject',
            Confirm: 'Confirm',
            ConfirmConfirmation: 'Do you want to confirm the ticket?',
            SubmitFeedback: 'Submit Feedback',
            Submit: 'Submit',
            FilterPlaceholder: 'Type text to filter the tickets list',
          },
          States: {
            New: 'New',
            Clarification: 'Clarification',
            Refused: 'Refused',
            Accepted: 'Accepted',
            Rejected: 'Rejected',
            InProgress: 'In Progress',
            Paused: 'Paused',
            Completed: 'Completed',
            Confirmed: 'Confirmed',
          },
          FiltersPanel: {
            Unknown: 'Unknown',
            Author: 'Author: {text}',
            Engineer: 'Engineer: {text}',
            CreatedOn: 'Created: {text}',
            State: 'State: {text}',
            Type: 'Type: {text}',
            Sorting: 'Sorting: {text}',
          },
          Toolbar: {
            Popular: 'Popular',
            All: 'All',
            Empty: 'Empty',
            NotEmpty: 'Not Empty',
            CreatedByMe: 'Created by me',
            AssignedToMe: 'Assigned to me',
            Classification: 'Classification',
            Current: 'Current',
            Filter: 'Filter',

            Author: 'Author',
            FilterByAuthor: 'Filter by Author',
            Authors: 'Authors',

            Engineer: 'Engineer',
            FilterByEngineer: 'Filter by Engineer',
            Engineers: 'Engineers',

            CreatedOn: 'Created',
            FilterByCreatedOn: 'Filter by Created On',
            Today: 'Today',
            Yesterday: 'Yesterday',

            State: 'State',
            FilterByState: 'Filter by State',
            States: 'States',

            Type: 'Type',
            FilterByType: 'Filter by Type',
            Types: 'Types',

            Sorting: 'Sorting',
            SortBy: 'Sort by',
            Newest: 'Newest',
            Oldest: 'Oldest',
          },
          Activities: {
            On: 'on ',
            Created: 'created ticket',
            SubjectChanged: 'changed subject to "{data}"',
            ClientChanged: 'changed client to "{data}"',
            ContactChanged: 'changed contact to "{data}"',
            AgentChanged: 'changed agent to "{data}"',
            TakenForClassification: 'took for classification',
            SentForClarification: 'sent for clarification',
            Commented: 'commented',
            Refused: 'refused',
            Returned: 'returned',
            Assigned: 'assigned to "{data}"',
            SlaChanged: 'changed sla to "{data}"',
            TypeChanged: 'changed type to "{data}"',
            Accepted: 'accepted',
            Started: 'started',
            Paused: 'paused',
            Resumed: 'resumed',
            ReportCreated: 'created work report (spent time: {data})',
            Completed: 'completed (spent time: {data})',
            Rejected: 'rejected',
            Confirmed: 'confirmed',
            FeedbackSubmitted: 'submitted feedback (rating: {data}/10)',
            Answer: 'Answer',
          },
        },
        Pager: {
          Previous: 'Previous',
          Next: 'Next',
          PageSize: 'Page size',
        },
        ChangingButtons: {
          Change: 'Change',
          Save: 'Save',
          Cancel: 'Cancel',
        },
      },
    },
    ru: {
      name: 'Русский',
      translation: {
        LoginView: {
          Title: 'Вход в Saturn',
          Email: 'Электронная почта',
          Password: 'Пароль',
          Login: 'Войти',
          Or: 'Или',
          LoginWithGoogle: 'Войти с учётной записью Google',
          LoginWithMagicLink: 'Войти с помощью ссылки',
        },
        MagicLinkLoginView: {
          Title: 'Вход с помощью ссылки',
          Email: 'Электронная почта',
          EmailPlaceholder: 'Введите адрес Вашей электронной почты',
          CheckYourInbox: 'Проверьте входящие сообщения!',
          Login: 'Войти',
        },
        MagicLinkRegisterView: {
          Title: 'Создание нового профиля',
          Name: 'Имя',
          NamePlaceholder: 'Введите имя пользователя',
          AgreementConfirmation: 'Обязуюсь соблюдать все правила.',
          Login: 'Войти',
        },
        Header: {
          Tickets: 'Заявки',
          Companies: 'Компании',
          Slas: 'Договоры',
          CfgItems: 'Конфигурационные единицы',
          TicketTypes: 'Типы заявок',
          Users: 'Пользователи',
        },
        SignedIn: 'Вы вошли как {userName}',
        UserMenu: {
          Role: 'Роль',
          Theme: 'Тема',
          Language: 'Язык',
          User: 'Пользователь',
          Settings: 'Настройки',
          LogOut: 'Выйти',
          LogIn: 'Войти',
        },
        Users: {
          Users: 'Пользователи',
          User: 'Пользователь',
          Company: 'Компания',
          Name: 'Имя',
          Email: 'Эл. почта',
          Superadmin: 'Суперадминистратор',
          CreatedOn: 'Создан',
          PasswordPlaceholder: 'Введите пароль и нажмите кнопку Установить',
          Actions: {
            Create: 'Создать',
            Set: 'Установить',
            FilterPlaceholder: 'Введите текст для фильтрации списка пользователей',
          },
        },
        Companies: {
          Companies: 'Компании',
          Company: 'Компания',
          Name: 'Название',
          Active: 'Активная',
          Superagent: 'Суперагент',
          CreatedOn: 'создал(а) компанию {datetime}',
          Actions: {
            Create: 'Создать',
            Edit: 'Редактировать',
            Activate: 'Активировать',
            Deactivate: 'Деактивировать',
            FilterPlaceholder: 'Введите текст для фильтрации списка компаний',
          },
          FiltersPanel: {
            Unknown: 'Неизвестный',
            Activity: 'Активность: {text}',
            Sorting: 'Сортировка: {text}',
          },
          Toolbar: {
            All: 'Все',
            Filter: 'Фильтр',

            Activity: 'Активность',
            FilterByActive: 'Фильтровать по активности',
            Active: 'Активные',
            Inactive: 'Неактивные',

            Sorting: 'Сортировка',
            SortBy: 'Сортировать',
            AB: 'А -> Б',
            BA: 'Б -> А',
            Newest: 'Новые вначале',
            Oldest: 'Старые вначале',
          },
        },
        Employments: {
          Employments: 'Сотрудники',
          Company: 'Компания',
          Name: 'Имя',
          Email: 'Эл. почта',
          Roles: {
            Roles: 'Роли',
            Agent: 'Агент',
            Superagent: 'Суперагент',
            Client: 'Клиент',
            Superclient: 'Суперклиент',
            Done: 'Готово',
          },
          Actions: {
            Create: 'Создать',
            Add: 'Добавить',
            SendLink: 'Отправить ссылку',
            Remove: 'Удалить',
            AddAndSendLinkDescription: 'Введите э-почту и нажмите "Добавить" или "Отправить ссылку".',
          },
        },
        Groups: {
          Groups: 'Группы',
          Group: 'Группа',
          Name: 'Название',
          Actions: {
            Create: 'Создать',
            Remove: 'Удалить',
            RemoveConfirmation: 'Удалить группу "{groupName}"?',
          },
        },
        CfgItems: {
          CfgItems: 'Конфигурационные единицы',
          CfgItem: 'Конфигурационная единица',
          Name: 'Название',
          CreatedOn: 'Создана',
          Company: 'Компания',
          Active: 'Активная',
          Actions: {
            Create: 'Создать',
            Pause: 'Деактивировать',
            Resume: 'Активировать',
            Remove: 'Удалить',
            ActivateConfirmation: 'Активировать конфигурационную единицу?',
            DeactivateConfirmation: 'Деактивировать конфигурационную единицу?',
            FilterPlaceholder: 'Введите текст для фильтрации списка конфигурационных единиц',
          },
        },
        Services: {
          Services: 'Сервисы',
          Service: 'Сервис',
          Actions: {
            Create: 'Создать',
          },
        },
        Slas: {
          Slas: 'Договоры',
          Sla: 'Договор',
          CreatedOn: 'Создан',
          Subject: 'Название',
          Agent: 'Агент',
          Client: 'Клиент',
          Service: 'Сервис',
          From: 'От',
          To: 'До',
          Active: 'Активный',
          Actions: {
            Create: 'Создать',
            FilterPlaceholder: 'Введите текст для фильтрации списка договоров',
          },
        },
        TicketTypes: {
          TicketTypes: 'Типы заявок',
          TicketType: 'Тип заявки',
          CreatedOn: 'Создан',
          Name: 'Название',
          Color: 'Цвет',
          Actions: {
            Create: 'Создать',
          },
        },
        Tickets: {
          Tickets: 'Заявки',
          Ticket: 'Заявка',
          Author: 'Автор',
          Client: 'Клиент',
          Contact: 'Контакт',
          Agent: 'Агент',
          Engineer: 'Инженер',
          Sla: 'Договор',
          Subject: 'Тема',
          Description: 'Описание',
          Type: 'Тип',
          WorkReports: 'Отчёты о работе',
          WorkReportsTotal: 'Всего',
          CreatedOn: 'создал(а) заявку {datetime}',
          CommentsCount: 'комментариев: {count}',
          CommentDescription: 'Описание',
          SpentTime: 'Время',
          Visibility: 'Видимость',
          Internal: 'Внутренний',
          External: 'Внешний',
          Feedback: 'Отзыв',
          Rating: 'Оценка',
          FeedbackDescription: 'Описание',
          Actions: {
            Create: 'Создать',
            Take: 'Взять на классификацию',
            Edit: 'Редактировать',
            Clarify: 'Уточнить',
            Comment: 'Комментировать',
            Refuse: 'Отклонить',
            RefuseConfirmation: 'Отклонить заявку?',
            Return: 'Вернуть',
            ReturnConfirmation: 'Вернуть заявку?',
            Accept: 'Принять',
            AcceptConfirmation: 'Принять заявку?',
            Start: 'Начать',
            StartConfirmation: 'Начать заявку?',
            Pause: 'Остановить',
            PauseConfirmation: 'Остановить заявку?',
            Resume: 'Продолжить',
            ResumeConfirmation: 'Продолжить заявку?',
            CreateReport: 'Создать отчёт',
            Complete: 'Завершить',
            Reject: 'Вернуть на доработку',
            Confirm: 'Подтвердить',
            ConfirmConfirmation: 'Подтвердить заявку?',
            SubmitFeedback: 'Отправить отзыв',
            Submit: 'Отправить',
            FilterPlaceholder: 'Введите текст для фильтрации списка заявок',
          },
          States: {
            New: 'Новая',
            Clarification: 'На уточнении',
            Refused: 'Отклонена',
            Accepted: 'Принята',
            Rejected: 'Возвращена на доработку',
            InProgress: 'Обрабатывается',
            Paused: 'Приостановлена',
            Completed: 'Завершена',
            Confirmed: 'Подтверждена',
          },
          FiltersPanel: {
            Unknown: 'Неизвестный',
            Author: 'Автор: {text}',
            Engineer: 'Инженер: {text}',
            CreatedOn: 'Создана: {text}',
            State: 'Состояние: {text}',
            Type: 'Тип: {text}',
            Sorting: 'Сортировка: {text}',
          },
          Toolbar: {
            Popular: 'Популярные',
            All: 'Все',
            Empty: 'Нет значения',
            NotEmpty: 'Есть значение',
            CreatedByMe: 'Созданные мной',
            AssignedToMe: 'Назначенные на меня',
            Classification: 'На классификации',
            Current: 'Текущие',
            Filter: 'Фильтр',

            Author: 'Автор',
            FilterByAuthor: 'Фильтровать по автору',
            Authors: 'Авторы',

            Engineer: 'Инженер',
            FilterByEngineer: 'Фильтровать по инженеру',
            Engineers: 'Инженеры',

            CreatedOn: 'Создана',
            FilterByСreatedOn: 'Фильтровать по времени создания',
            Today: 'Сегодня',
            Yesterday: 'Вчера',

            State: 'Состояние',
            FilterByState: 'Фильтровать по состоянию',
            States: 'Состояния',

            Type: 'Тип',
            FilterByType: 'Фильтровать по типу',
            Types: 'Типы',

            Sorting: 'Сортировка',
            SortBy: 'Сортировать',
            Newest: 'Новые вначале',
            Oldest: 'Старые вначале',
          },
          Activities: {
            On: '',
            Created: 'создал(а) заявку',
            SubjectChanged: 'поменял(в) тему на "{data}"',
            ClientChanged: 'поменял(в) клиента на "{data}"',
            ContactChanged: 'поменял(в) контакта на "{data}"',
            AgentChanged: 'поменял(в) агента на "{data}"',
            TakenForClassification: 'взял(а) на классификацию',
            SentForClarification: 'отправил(в) на уточнение',
            Commented: 'добавил(а) комментарий',
            Refused: 'отклонил(а)',
            Returned: 'вернул(а)',
            Assigned: 'назначил(а) на "{data}"',
            SlaChanged: 'поменял(в) договор на "{data}"',
            TypeChanged: 'поменял(в) тип на "{data}"',
            Accepted: 'принял(а)',
            Started: 'начал(а)',
            Paused: 'остановил(а)',
            Resumed: 'продолжил(а)',
            ReportCreated: 'создал(а) отчёт о работе (время: {data})',
            Completed: 'завершил(а) (время: {data})',
            Rejected: 'вернул(а) на доработку',
            Confirmed: 'подтвердил(а)',
            FeedbackSubmitted: 'отправил(а) отзыв (оценка: {data}/10)',
            Answer: 'Ответ',
          },
        },
        Pager: {
          Previous: 'Предыдущая',
          Next: 'Следующая',
          PageSize: 'Размер страницы',
        },
        ChangingButtons: {
          Change: 'Редактировать',
          Save: 'Сохранить',
          Cancel: 'Отменить',
        },
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
} as InitOptions)

export default localizer
