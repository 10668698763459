import React, { PureComponent } from 'react'

import { Row } from '../row/Row'
import { TextInput } from '../textInput/TextInput'

export class TextEditor extends PureComponent {
  render() {
    const { id, name, type, caption, multiLines, autoFocus, placeholder, value, readOnly, style, onChange } = this.props
    return (
      <Row style={{ justifyContent: 'space-between', alignItems: multiLines ? 'flex-start' : 'center', ...style }}>
        <label htmlFor={id} style={{ width: '150px', minWidth: '150px', fontSize: 'small', fontWeight: 'bold', color: 'rgb(101, 121, 141)' }}>
          {caption}
        </label>
        <TextInput
          id={id}
          name={name}
          type={type}
          multiLines={multiLines}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          width={'stretch'}
          style={{ flex: 1 }}
          onChange={onChange}
        />
      </Row>
    )
  }
}
