import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { MobileIndent, View, ViewHeader } from '../../ui/view/View'
import { employmentRoles } from '../../../common/employmentRoles'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class CompanyCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    const { userRole } = props
    this.state = {
      name: '',
      active: false,
      createSla: hasValue(userRole) && (userRole.agent || userRole.superagent),
    }
  }

  onNameChange = value => {
    this.setState({ name: value })
  }

  onActiveChange = value => {
    this.setState({ active: value })
  }

  onCreateSlaChange = value => {
    this.setState({ createSla: value })
  }

  onCreateButtonClick = async () => {
    const { jwtUser, userRole, history, companyCreate, companyCreateEmployment, companyAddEmploymentRole, slaCreate } = this.props
    const { name, active, createSla } = this.state
    const companyId = v4()
    await executeCommand(companyCreate, { companyId, name, active })
    if (hasValue(userRole) && (userRole.agent || userRole.superagent) && createSla) {
      const slaId = v4()
      await executeCommand(slaCreate, {
        slaId,
        subject: `${userRole.companyName} - ${name} SLA.`,
        agentId: userRole.companyId,
        clientId: companyId,
        service: 'Service',
        termFrom: Date.now(),
      })
    } else {
      const userId = jwtUser.id
      await executeCommand(companyCreateEmployment, { companyId, userId })
      await executeCommand(companyAddEmploymentRole, { companyId, userId, roleId: employmentRoles.superclient.id })
    }
    history.push(`/company/${companyId}`)
  }

  render() {
    const { userRole, l } = this.props
    const { name, active, createSla } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Companies.Company')} />
        <Panel>
          <PanelColumn>
            <TextEditor id={'name'} caption={l.t('Companies.Name')} value={name} autoFocus={true} onChange={this.onNameChange} />
            <BooleanEditor id={'active'} caption={l.t('Companies.Active')} value={active} onChange={this.onActiveChange} />
          </PanelColumn>
        </Panel>
        <MobileIndent>
          {hasValue(userRole) && (userRole.agent || userRole.superagent) ? (
            <Row style={{ marginTop: '16px' }}>
              <BooleanEditor id={'createSla'} caption={'Create SLA'} value={createSla} labelWidth={'auto'} onChange={this.onCreateSlaChange} />
            </Row>
          ) : null}
          <Row style={{ marginTop: '16px' }}>
            <Button kind={buttonKind.primary} variant={'small'} title={l.t('Companies.Actions.Create')} onClick={this.onCreateButtonClick} />
          </Row>
        </MobileIndent>
      </View>
    )
  }
}

const CompanyCreatingViewContainer = props => {
  const companyCreate = useCommandBuilder(({ companyId, name, active }) => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'create', payload: { name, active } }
  })
  const companyCreateEmployment = useCommandBuilder(({ companyId, userId }) => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'createEmployment', payload: { userId } }
  })
  const companyAddEmploymentRole = useCommandBuilder(({ companyId, userId, roleId }) => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'addEmploymentRole', payload: { userId, roleId } }
  })
  const slaCreate = useCommandBuilder(({ slaId, subject, agentId, clientId, service, termFrom }) => {
    return {
      aggregateName: 'Sla',
      aggregateId: slaId,
      type: 'create',
      payload: { subject, agentId, clientId, service, termFrom, active: true },
    }
  })
  return (
    <CompanyCreatingView
      {...props}
      companyCreate={companyCreate}
      companyCreateEmployment={companyCreateEmployment}
      companyAddEmploymentRole={companyAddEmploymentRole}
      slaCreate={slaCreate}
    />
  )
}

export default CompanyCreatingViewContainer
