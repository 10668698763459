import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import { useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { TextField } from '../../ui/textField/TextField'
import ConnectedLookupEditor from '../lookup/LookupEditor'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { Button, buttonKind, Panel, PanelColumn, Row } from '../../ui'

export class TicketCreatingView extends PureComponent {
  constructor(props) {
    super(props)
    const { jwtUser, userRole } = props
    let clientId = null
    let contactId = null
    let agentId = null
    if (hasValue(userRole)) {
      if (userRole.client) {
        clientId = userRole.companyId
        contactId = hasValue(jwtUser) ? jwtUser.id : null
      } else if (userRole.agent) {
        agentId = userRole.companyId
      }
    }
    this.state = { clientId, contactId, agentId, slaId: null, subject: '' }
  }

  onClientChange = value => {
    this.setState({ clientId: value, slaId: null })
  }

  onContactChange = value => {
    this.setState({ contactId: value })
  }

  onAgentChange = value => {
    this.setState({ agentId: value, slaId: null })
  }

  onSlaChange = value => {
    this.setState({ slaId: value })
  }

  onSubjectChange = value => {
    this.setState({ subject: value })
  }

  onDescriptionChange = value => {
    this.setState({ description: value })
  }

  onCreateButtonClick = async () => {
    const { history, ticketCreate } = this.props
    const { clientId, contactId, agentId, slaId, subject, description } = this.state
    const ticketId = v4()
    await executeCommand(ticketCreate, { ticketId, clientId, contactId, agentId, slaId, subject, description })
    history.goBack()
  }

  render() {
    const { jwtUser, userRole, l } = this.props
    const { clientId, contactId, agentId, slaId, subject, description } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Tickets.Ticket')} />
        <Panel>
          <PanelColumn>
            <TextEditor id={'subject'} caption={l.t('Tickets.Subject')} value={subject} autoFocus={true} onChange={this.onSubjectChange} />
            <TextEditor
              id={'description'}
              caption={l.t('Tickets.Description')}
              value={description}
              multiLines={true}
              onChange={this.onDescriptionChange}
            />
          </PanelColumn>
          <PanelColumn>
            <TextField id={'authorName'} caption={l.t('Tickets.Author')} value={jwtUser.name} />
            <ConnectedLookupEditor
              id={'clientId'}
              caption={l.t('Tickets.Client')}
              resolverName={'simpleCompaniesClients'}
              resolverArgs={{ userRoleString: JSON.stringify(userRole), agentId }}
              valueFieldName={'id'}
              textFieldName={'name'}
              value={clientId}
              onChange={this.onClientChange}
            />
            <ConnectedLookupEditor
              id={'contactId'}
              caption={l.t('Tickets.Contact')}
              resolverName={'simpleEmploymentsClients'}
              resolverArgs={{ companyId: clientId }}
              valueFieldName={'userId'}
              textFieldName={'userName'}
              value={contactId}
              onChange={this.onContactChange}
            />
            <ConnectedLookupEditor
              id={'agentId'}
              caption={l.t('Tickets.Agent')}
              resolverName={'simpleCompaniesAgents'}
              resolverArgs={{ userRoleString: JSON.stringify(userRole), clientId }}
              valueFieldName={'id'}
              textFieldName={'name'}
              value={agentId}
              onChange={this.onAgentChange}
            />
            <ConnectedLookupEditor
              id={'slaId'}
              caption={l.t('Tickets.Sla')}
              resolverName={'simpleSlas'}
              resolverArgs={{ userRoleString: JSON.stringify(userRole), agentId, clientId }}
              valueFieldName={'id'}
              textFieldName={'service'}
              value={slaId}
              onChange={this.onSlaChange}
            />
          </PanelColumn>
        </Panel>
        <Row style={{ marginTop: '16px' }}>
          <Button kind={buttonKind.primary} variant={'small'} title={l.t('Tickets.Actions.Create')} onClick={this.onCreateButtonClick} />
        </Row>
      </View>
    )
  }
}

const TicketCreatingViewContainer = props => {
  const ticketCreate = useCommandBuilder(({ ticketId, clientId, contactId, agentId, slaId, subject, description }) => {
    return {
      aggregateName: 'Ticket',
      aggregateId: ticketId,
      type: 'create',
      payload: { clientId, contactId, agentId, slaId, subject, description },
    }
  })
  return <TicketCreatingView {...props} ticketCreate={ticketCreate} />
}

export default TicketCreatingViewContainer
