import React, { PureComponent } from 'react'

import { hasValue } from '../../../../utils/utils'
import { executeCommand } from '../../../utils'
import { Box, TextField } from '../../../ui'
import LookupField from '../../lookup/LookupField'
import { accessRights } from '../../accessRights'

export class TicketFields extends PureComponent {
  renderLookupField = (id, title, resolverName, resolverArgs, valueFieldName, textFieldName, allowClear, value, url, command) => {
    const { l } = this.props
    return (
      <LookupField
        id={id}
        title={l.t('Tickets.' + title.replace(' ', ''))}
        resolverName={resolverName}
        resolverArgs={resolverArgs}
        valueFieldName={valueFieldName}
        textFieldName={textFieldName}
        allowClear={allowClear}
        value={value}
        url={url}
        mb={3}
        onChange={async value => {
          await executeCommand(command, value)
        }}
      />
    )
  }

  renderTextField = (id, title, text, url) => {
    const { l } = this.props
    return <TextField id={id} title={l.t('Tickets.' + title.replace(' ', ''))} text={text} url={url} mb={3} />
  }

  render() {
    const { userRole, ticket, ticketChangeClient, ticketChangeContact, ticketChangeAgent, ticketAssign, ticketChangeSla, ticketChangeType } =
      this.props
    return (
      <Box display={'flex'} flexDirection={'column'}>
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.clientEditing(userRole, ticket)
          ? this.renderLookupField(
              'client',
              'Client',
              'simpleCompaniesClients',
              { userRoleString: JSON.stringify(userRole), agentId: ticket.agentId },
              'id',
              'name',
              false,
              ticket.clientId,
              `/company/${ticket.clientId}`,
              ticketChangeClient
            )
          : this.renderTextField('client', 'Client', ticket.clientName, `/company/${ticket.clientId}`)}
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.contactEditing(userRole, ticket)
          ? this.renderLookupField(
              'contact',
              'Contact',
              'simpleEmploymentsClients',
              { companyId: ticket.clientId },
              'userId',
              'userName',
              false,
              ticket.contactId,
              `/user/${ticket.contactId}`,
              ticketChangeContact
            )
          : this.renderTextField('contact', 'Contact', ticket.contactName, `/user/${ticket.contactId}`)}
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.agentEditing(userRole, ticket)
          ? this.renderLookupField(
              'agent',
              'Agent',
              'simpleCompaniesAgents',
              { userRoleString: JSON.stringify(userRole), clientId: ticket.clientId },
              'id',
              'name',
              true,
              ticket.agentId,
              `/company/${ticket.agentId}`,
              ticketChangeAgent
            )
          : this.renderTextField('agent', 'Agent', ticket.agentName, `/company/${ticket.agentId}`)}
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.engineerEditing(userRole, ticket)
          ? this.renderLookupField(
              'engineer',
              'Engineer',
              'simpleEmploymentsAgents',
              { companyId: ticket.agentId },
              'userId',
              'userName',
              false,
              ticket.engineerId,
              `/user/${ticket.engineerId}`,
              ticketAssign
            )
          : this.renderTextField('engineer', 'Engineer', ticket.engineerName, `/user/${ticket.engineerId}`)}
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.slaEditing(userRole, ticket)
          ? this.renderLookupField(
              'sla',
              'Sla',
              'simpleSlas',
              {
                userRoleString: JSON.stringify(userRole),
                agentId: ticket.agentId,
                clientId: ticket.clientId,
              },
              'id',
              'service',
              true,
              ticket.slaId,
              `/sla/${ticket.slaId}`,
              ticketChangeSla
            )
          : this.renderTextField('sla', 'Sla', ticket.slaService, `/sla/${ticket.slaId}`)}
        {accessRights.tickets.editing(userRole, ticket) && accessRights.tickets.typeEditing(userRole, ticket)
          ? this.renderLookupField(
              'type',
              'Type',
              'ticketTypes',
              {},
              'id',
              'name',
              false,
              ticket.typeId,
              `/ticketType/${ticket.typeId}`,
              ticketChangeType
            )
          : this.renderTextField('type', 'Type', ticket.typeName, `/ticketType/${ticket.typeId}`)}
      </Box>
    )
  }
}
