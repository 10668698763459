import React from 'react'
import { Link as LibLink } from '@primer/react'
import { Link as RouterLink } from 'react-router-dom'

export const Link = props => {
  const { id, url, color, fontSize, fontWeight, children } = props
  return (
    <LibLink id={id} to={url} muted={true} sx={{ color, fontSize, fontWeight }} as={RouterLink}>
      {children}
    </LibLink>
  )
}
