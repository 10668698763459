import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader } from '../../ui/view/View'
import { Button, ButtonsRow } from '../../ui'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'CompanyGroupsView.pageSize'

export class CompanyGroupsView extends PureComponent {
  onCreateButtonClick = () => {
    const { companyId, history } = this.props
    history.push(`/company/${companyId}/groupCreating`)
  }

  onRemoveButtonClick = async group => {
    const { groupRemove, l } = this.props
    if (confirm(l.t('Groups.Actions.RemoveConfirmation').replace('{groupName}', group.name))) {
      await executeCommand(groupRemove, { groupId: group.id })
    }
  }

  renderButtons = () => {
    const { l } = this.props
    return (
      <ButtonsRow>
        <Button id={'createGroup'} variant={'small'} title={l.t('Groups.Actions.Create')} onClick={this.onCreateButtonClick} />
      </ButtonsRow>
    )
  }

  renderPager = () => {
    const { userRole, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, companyId, l } = this.props
    return (
      <Pager
        id={'companyGroupsPager'}
        resolverName={'groupsForCompanyCount'}
        resolverArgs={{ userRoleString: JSON.stringify(userRole), companyId }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { companyId, groups, visible, l } = this.props
    return visible ? (
      <View>
        <ViewHeader text={l.t('Groups.Groups')} buttons={this.renderButtons()} />
        <List
          keyFieldName={'id'}
          data={groups}
          columnSettings={[
            { title: l.t('Groups.Name'), fieldName: 'name', width: '25%', important: true },
            { title: '', buttonTitle: l.t('Groups.Actions.Remove'), type: 'button', onClick: this.onRemoveButtonClick, width: '10%' },
          ]}
          onGetItemUrl={group => `/company/${companyId}/group/${group.id}`}
        />
        {this.renderPager()}
      </View>
    ) : null
  }
}

const CompanyGroupsViewContainer = props => {
  const { userRole, companyId } = props
  const resolverName = 'groupsForCompany'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    companyId,
    sortingFieldName: 'name',
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  const groupRemove = useCommandBuilder(({ groupId }) => {
    return { aggregateName: 'Company', aggregateId: companyId, type: 'removeGroup', payload: { groupId } }
  })
  return (
    <CompanyGroupsView
      {...props}
      groups={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
      groupRemove={groupRemove}
    />
  )
}

export default CompanyGroupsViewContainer
