import React, { PureComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useViewModel, useCommandBuilder } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'
import { Label, Panel, PanelColumn } from '../../ui'
import { TextField } from '../../ui/textField/TextField'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { ChangingButtons } from '../../ui/buttons/ChangingButtons'

export class TicketTypeView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { changing: false }
  }

  onChangeButtonClick = () => {
    const { ticketType } = this.props
    this.setState({ changing: true, color: ticketType.color })
  }

  onSaveButtonClick = async () => {
    const { ticketType, ticketTypeChangeColor } = this.props
    const { color } = this.state
    if (color !== ticketType.color) {
      await executeCommand(ticketTypeChangeColor, { color })
    }
    this.setState({ changing: false })
  }

  onCancelButtonClick = () => {
    this.setState({ changing: false })
  }

  onNameChange = async value => {
    const { ticketTypeRename } = this.props
    await executeCommand(ticketTypeRename, value)
  }

  onColorChange = async value => {
    this.setState({ color: value })
  }

  render() {
    const { ticketType, l } = this.props
    const { changing, color } = this.state
    return (
      <View>
        <ViewHeader text={l.t('TicketTypes.TicketType')} />
        <MobileIndent>
          <AdvancedText
            id={'name'}
            caption={l.t('TicketTypes.Name')}
            value={ticketType.name}
            allowChanging={true}
            style={{ marginBottom: '8px' }}
            l={l}
            onChange={this.onNameChange}
          />
        </MobileIndent>
        <Panel>
          <PanelColumn>
            <TextField id={'createdOn'} caption={l.t('TicketTypes.CreatedOn')} value={getLocalDateTimeString(ticketType.createdOn)} />
          </PanelColumn>
          <PanelColumn>
            <Label id={'label'} color={changing ? color : ticketType.color}>
              {hasValue(ticketType.name) && ticketType.name !== '' ? ticketType.name : l.t('TicketTypes.TicketType')}
            </Label>
            {changing ? (
              <TextEditor
                id={'color'}
                caption={l.t('TicketTypes.Color')}
                value={color}
                autoFocus={true}
                style={{ flex: 1 }}
                onChange={this.onColorChange}
              />
            ) : null}
            <ChangingButtons
              changing={changing}
              l={l}
              onChangeButtonClick={this.onChangeButtonClick}
              onSaveButtonClick={this.onSaveButtonClick}
              onCancelButtonClick={this.onCancelButtonClick}
            />
          </PanelColumn>
        </Panel>
      </View>
    )
  }
}

const TicketTypeViewContainer = props => {
  const viewModelName = 'TicketTypeVm'
  const [object, setObject] = useState({})
  const { ticketTypeId } = useParams()
  const { connect, dispose } = useViewModel(viewModelName, [ticketTypeId], setObject)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])
  const ticketTypeRename = useCommandBuilder(name => {
    return { aggregateName: 'TicketType', aggregateId: ticketTypeId, type: 'rename', payload: { name } }
  })
  const ticketTypeChangeColor = useCommandBuilder(({ color }) => {
    return {
      aggregateName: 'TicketType',
      aggregateId: ticketTypeId,
      type: 'changeColor',
      payload: { color },
    }
  })
  return (
    <TicketTypeView
      {...props}
      ticketTypeId={ticketTypeId}
      ticketType={object}
      ticketTypeRename={ticketTypeRename}
      ticketTypeChangeColor={ticketTypeChangeColor}
    />
  )
}

export default TicketTypeViewContainer
