export const ticketStates = {
  new: { id: 'new', name: 'New', color: 'rgb(3, 86, 213)' },
  clarification: { id: 'clarification', name: 'Clarification', color: 'rgb(42, 167, 69)' },
  refused: { id: 'refused', name: 'Refused', color: 'rgb(225, 228, 232)' },
  accepted: { id: 'accepted', name: 'Accepted', color: 'rgb(38, 167, 69)' },
  rejected: { id: 'rejected', name: 'Rejected', color: 'rgb(215, 58, 73)' },
  inProgress: { id: 'inProgress', name: 'In Progress', color: 'rgb(234, 74, 170)' },
  paused: { id: 'paused', name: 'Paused', color: 'rgb(247, 147, 207)' },
  completed: { id: 'completed', name: 'Completed', color: 'rgb(245, 106, 10)' },
  confirmed: { id: 'confirmed', name: 'Confirmed', color: 'rgb(255, 171, 112)' },
}
