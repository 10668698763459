import React, { PureComponent } from 'react'
import { Button as LibButton, ButtonDanger, ButtonInvisible, ButtonOutline, ButtonPrimary } from '@primer/react'

export const buttonKind = {
  simple: 1,
  primary: 2,
  outline: 3,
  danger: 4,
  invisible: 5,
}

export class Button extends PureComponent {
  render() {
    const { title, type = 'button', kind, variant, formAction, href, disabled, width = 'auto', height, ml, mt, as, onClick } = this.props
    let Component = LibButton
    if (kind === buttonKind.primary) {
      Component = ButtonPrimary
    } else if (kind === buttonKind.outline) {
      Component = ButtonOutline
    } else if (kind === buttonKind.danger) {
      Component = ButtonDanger
    } else if (kind === buttonKind.invisible) {
      Component = ButtonInvisible
    }
    return (
      <Component
        type={type}
        variant={variant}
        formAction={formAction}
        href={href}
        disabled={disabled}
        sx={{ width, height, ml, mt }}
        as={as}
        onClick={onClick}
      >
        {title}
      </Component>
    )
  }
}
