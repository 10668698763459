import React, { PureComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, PointerBox, Text, Timeline } from '@primer/react'
import { DotIcon, PersonIcon } from '@primer/octicons-react'

import { hasValue } from '../utils'
import { Button, buttonKind } from '../buttons/Button'

class ActivitiesListItem extends PureComponent {
  renderAvatar = (boxSize, imageSize, authorUrl, position) => {
    return (
      <Link to={authorUrl} muted={true} sx={{ position, color: 'fg.muted' }} as={RouterLink}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'timeline.badgeBg',
            width: boxSize,
            height: boxSize,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: boxSize / 2,
            borderColor: 'avatar.border',
          }}
        >
          <PersonIcon size={imageSize} />
        </Box>
      </Link>
    )
  }

  renderActionInfo = (authorName, authorUrl, details, px, py, ml) => {
    return (
      <Box sx={{ display: 'block', px, py, ml }}>
        <Link id={'authorName'} to={authorUrl} muted={true} sx={{ color: 'fg.default', fontWeight: 'bold' }} as={RouterLink}>
          {authorName}
        </Link>
        <Text id={'details'} sx={{ ml: 1 }}>
          {details}
        </Text>
      </Box>
    )
  }

  onButtonClick = () => {
    const { item, onButtonClick } = this.props
    if (onButtonClick instanceof Function) {
      onButtonClick(item)
    }
  }

  render() {
    const { item, condensed } = this.props
    const { authorName, authorUrl, details, description, button } = item
    let content = null
    if (hasValue(description) && description.trim() !== '') {
      content = (
        <Timeline.Item display={'flex'} condensed={condensed}>
          <Timeline.Badge>{this.renderAvatar(34, 20, authorUrl, 'absolute')}</Timeline.Badge>
          <Timeline.Body sx={{ marginTop: 0, paddingLeft: 2 }}>
            <PointerBox caret={'left-top'} width={'100%'} bg={'accent.subtle'} borderColor={'accent.muted'}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.renderActionInfo(authorName, authorUrl, details, 3, 2, 0)}
                {hasValue(button) ? (
                  <Button id={'button'} kind={buttonKind.outline} title={button.title} sx={{ mr: 2 }} onClick={this.onButtonClick} />
                ) : null}
              </Box>
              <Box
                sx={{
                  bg: 'canvas.default',
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: 'accent.muted',
                  borderBottomLeftRadius: 2,
                  borderBottomRightRadius: 2,
                  py: 3,
                  px: 3,
                }}
              >
                {/* The "whiteSpace: pre-wrap" is used for multiline text correct displaying. */}
                <Text id={'description'} sx={{ whiteSpace: 'pre-wrap' }}>
                  {description}
                </Text>
              </Box>
            </PointerBox>
          </Timeline.Body>
        </Timeline.Item>
      )
    } else {
      content = (
        <Timeline.Item sx={{ display: 'flex' }} condensed={condensed}>
          <Timeline.Badge>
            <DotIcon />
          </Timeline.Badge>
          <Timeline.Body sx={{ display: 'flex', flexDirection: 'row' }}>
            {this.renderAvatar(22, 16, authorUrl)}
            {this.renderActionInfo(authorName, authorUrl, details, 0, 0, 1)}
          </Timeline.Body>
        </Timeline.Item>
      )
    }
    return content
  }
}
ActivitiesListItem.displayName = 'ActivitiesListItem'

export const ActivitiesList = props => {
  const { items, condensed, onItemButtonClick } = props
  return (
    <Timeline clipSidebar={true}>
      {Array.isArray(items)
        ? items.map((item, index) => {
            return <ActivitiesListItem key={index + 1} item={item} condensed={condensed} onButtonClick={onItemButtonClick} />
          })
        : null}
      <Timeline.Break />
    </Timeline>
  )
}
