import React, { PureComponent } from 'react'

import { TextInput } from './TextInput'
import { SaveCancelButtons } from '../buttons/ChangingButtons'

export class AdvancedTextInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  onTextInputChange = value => {
    this.setState({ value: value })
  }

  onSaveButtonClick = async () => {
    const { value: propsValue, onSave } = this.props
    const { value: stateValue } = this.state
    if (onSave instanceof Function) {
      onSave(stateValue, stateValue !== propsValue)
    }
  }

  onCancelButtonClick = () => {
    const { onCancel } = this.props
    if (onCancel instanceof Function) {
      onCancel()
    }
  }

  render() {
    const { id, l } = this.props
    const { value } = this.state
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <TextInput id={id} value={value} autoFocus={true} width={'100%'} marginRight={'16px'} onChange={this.onTextInputChange} />
        <SaveCancelButtons id={id} l={l} onSaveButtonClick={this.onSaveButtonClick} onCancelButtonClick={this.onCancelButtonClick} />
      </div>
    )
  }
}
