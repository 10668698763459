import React from 'react'
import styled from 'styled-components'

import { useStaticResolver } from '@resolve-js/react-hooks'

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
`

export const ImageButton = (props) => {
  const { imageUrl, width, height, style, onClick } = props
  const resolveStatic = useStaticResolver()
  return (
    <StyledButton type={'button'} style={{ width, height, ...style }} onClick={onClick}>
      <img src={resolveStatic(imageUrl)} width={width} height={height} />
    </StyledButton>
  )
}
