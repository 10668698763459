import React, { PureComponent, useState, useEffect, useCallback } from 'react'

import { useClient } from '@resolve-js/react-hooks'

import { hasValue } from '../../../utils/utils'
import { View, ViewHeader } from '../../ui/view/View'
import { List } from '../../ui/list/List'
import Pager, { readPageSizeFromStorage, writePageSizeToStorage } from '../Pager'
import { useClientQuery } from '../hooks'

const pageSizeKey = 'CompanySlasView.PageSize'

export class CompanySlasView extends PureComponent {
  renderPager = () => {
    const { userRole, currentPageIndex, pageSize, onCurrentPageIndexChange, onPageSizeChange, companyId, l } = this.props
    return (
      <Pager
        id={'companySlasViewPager'}
        resolverName={'slasForCompanyCount'}
        resolverArgs={{ userRoleString: JSON.stringify(userRole), companyId }}
        currentPageIndex={currentPageIndex}
        pageSize={pageSize}
        l={l}
        onCurrentPageIndexChange={onCurrentPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    )
  }

  render() {
    const { companyId, slas, visible, l } = this.props
    return visible ? (
      <View>
        <ViewHeader text={l.t('Slas.Slas')} />
        <List
          keyFieldName={'id'}
          data={slas}
          columnSettings={[
            { title: l.t('Slas.Subject'), fieldName: 'subject', width: '20%', important: true },
            { title: l.t('Slas.Agent'), fieldName: 'agentName', width: '15%', important: true },
            { title: l.t('Slas.Client'), fieldName: 'clientName', width: '15%', important: true },
            { title: l.t('Slas.Service'), fieldName: 'service', width: '25%' },
            { title: l.t('Slas.From'), fieldName: 'termFrom', type: 'date', width: '10%' },
            { title: l.t('Slas.To'), fieldName: 'termTo', type: 'date', width: '10%' },
            { title: l.t('Slas.Active'), fieldName: 'active', type: 'boolean', width: '5%' },
          ]}
          onGetItemUrl={sla => `/company/${companyId}/sla/${sla.id}`}
        />
        {this.renderPager()}
      </View>
    ) : null
  }
}

const CompanySlasViewContainer = props => {
  const { userRole, companyId } = props
  const resolverName = 'slasForCompany'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(readPageSizeFromStorage(pageSizeKey))
  const userRoleString = JSON.stringify(userRole)
  const client = useClient()

  const objects = useClientQuery(client, [], 'Saturn', resolverName, {
    userRoleString,
    companyId,
    firstRecordIndex: currentPageIndex * pageSize,
    recordCount: pageSize,
  })

  const onCurrentPageIndexChange = useCallback(currentPageIndex => {
    setCurrentPageIndex(currentPageIndex)
  }, [])
  const onPageSizeChange = useCallback((pageSize, currentPageIndex) => {
    writePageSizeToStorage(pageSize, pageSizeKey)
    setPageSize(pageSize)
    if (hasValue(currentPageIndex)) {
      setCurrentPageIndex(currentPageIndex)
    }
  }, [])

  return (
    <CompanySlasView
      {...props}
      slas={objects}
      currentPageIndex={currentPageIndex}
      pageSize={pageSize}
      onCurrentPageIndexChange={onCurrentPageIndexChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

export default CompanySlasViewContainer
