import { CfgItem_Created, CfgItem_Renamed, CfgItem_Activity_Changed, CfgItem_Removed } from '../eventTypes'

const cfgItemViewModel = {
  Init: () => ({}),

  [CfgItem_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, name, companyId, active } = payload
    return { id: aggregateId, authorId, createdOn: new Date(timestamp ?? 0), name, companyId, active }
  },

  [CfgItem_Renamed]: (state: any, event: any) => {
    const { payload } = event
    const { name } = payload
    return { ...state, name }
  },

  [CfgItem_Activity_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { active } = payload
    return { ...state, active }
  },

  [CfgItem_Removed]: () => {
    return {}
  },
}

export default cfgItemViewModel
