import React, { PureComponent } from 'react'

import { ticketActivityVisibilities } from '../../../../common/ticketActivityVisibilities'
import { TextEditor } from '../../../ui/textEditor/TextEditor'
import { LookupEditor } from '../../../ui/lookupEditor/LookupEditor'
import { Button, buttonKind, Dialog, Panel, PanelColumn, Row } from '../../../ui'

export class TicketReportCreatingForm extends PureComponent {
  constructor(props) {
    super(props)
    const { description } = props
    this.state = {
      description,
      spentTime: 1,
      visibility: ticketActivityVisibilities.internal.id,
    }
  }

  onDescriptionChange = value => {
    this.setState({ description: value })
  }

  onSpentTimeChange = value => {
    this.setState({ spentTime: value })
  }

  onVisibilityChange = value => {
    this.setState({ visibility: value })
  }

  onButtonClick = () => {
    const { onButtonClick } = this.props
    const { description, spentTime, visibility } = this.state
    onButtonClick({ description, spentTime, visibility })
  }

  render() {
    const { l, onDismiss } = this.props
    const { description, spentTime, visibility } = this.state
    const visibilityItems = Object.keys(ticketActivityVisibilities).map(key => {
      return { value: ticketActivityVisibilities[key].id, text: l.t('Tickets.' + ticketActivityVisibilities[key].name) }
    })
    return (
      <Dialog title={l.t('Tickets.Actions.CreateReport')} onDismiss={onDismiss}>
        <Panel>
          <PanelColumn>
            <TextEditor
              id={'reportCreatingForm.description'}
              caption={l.t('Tickets.CommentDescription')}
              multiLines={true}
              autoFocus={true}
              value={description}
              onChange={this.onDescriptionChange}
            />
            <TextEditor
              id={'reportCreatingForm.spentTime'}
              caption={l.t('Tickets.SpentTime')}
              type={'number'}
              value={spentTime}
              onChange={this.onSpentTimeChange}
            />
            <LookupEditor
              id={'reportCreatingForm.visibility'}
              caption={l.t('Tickets.Visibility')}
              items={visibilityItems}
              value={visibility}
              onChange={this.onVisibilityChange}
            />
            <Row style={{ marginTop: '16px' }}>
              <Button
                id={'reportCreatingForm.createReport'}
                variant={'small'}
                kind={buttonKind.primary}
                title={l.t('Tickets.Actions.CreateReport')}
                onClick={this.onButtonClick}
              />
            </Row>
          </PanelColumn>
        </Panel>
      </Dialog>
    )
  }
}
