const localIsoTimeString = 'T00:00:00'

enum Operator {
  and = 'and',
  or = 'or',
}

const combineConditions = (operator: Operator, conditions: any[]) => {
  let result = {}
  const notEmptyConditions: any[] = []
  if (Array.isArray(conditions)) {
    conditions.forEach(condition => {
      if (hasValue(condition) && Object.keys(condition).length > 0) {
        notEmptyConditions.push(condition)
      }
    })
  }
  if (notEmptyConditions.length === 1) {
    result = notEmptyConditions[0]
  } else if (notEmptyConditions.length > 1) {
    if (operator === Operator.or) {
      result = { $or: notEmptyConditions }
    } else {
      result = { $and: notEmptyConditions }
    }
  }
  return result
}

export const writeToLog = (...values: string[]): void => {
  console.log(...values)
}

export const hasValue = (v: any) => {
  return v !== undefined && v !== null
}

export const isNull = (v: any): boolean => {
  return v === undefined || v === null
}

export function ifHasValue<TValue>(a: TValue, fallback: TValue): TValue {
  return hasValue(a) ? a : fallback
}

export function isDefined<TValue = any>(v: TValue | undefined): v is Exclude<TValue, null | undefined> {
  return v !== undefined
}

export function getObjectFromString<TValue extends object = object>(str: any, defaultValue: any = {}): TValue {
  const obj = hasValue(str) ? JSON.parse(str) : defaultValue
  return ifHasValue(obj, defaultValue)
}

export const getValue = (obj: any, fieldName: string) => {
  const fieldNameParts = fieldName.split('.')
  let currentValue = obj
  for (const fieldNamePart of fieldNameParts) {
    if (hasValue(currentValue)) {
      currentValue = currentValue[fieldNamePart]
    } else {
      break
    }
  }
  return currentValue
}

export const trim = (value: any): string | null => {
  return hasValue(value) ? value.trim() : value
}

export const isEmptyString = (value: string | undefined | null): value is undefined => {
  return isNull(value) || (value as string).trim().length <= 0
}

export const pick = (obj: any, fieldNames: string[]): any => {
  let result: any = {}
  fieldNames.map(fieldName => {
    if (Object.keys(obj).includes(fieldName)) {
      result[fieldName] = obj[fieldName]
    }
  })
  return result
}

export const capitalize = (str: any) => {
  if (hasValue(str)) {
    return str.charAt(0).toUpperCase() + str.substring(1)
  } else {
    return str
  }
}

export class Dictionary {
  keysObject: { [key: string]: number }

  constructor() {
    this.keysObject = {}
  }
  addKey = (key: string) => {
    this.keysObject[key] = 1
  }
  keyCount = () => {
    return Object.keys(this.keysObject).length
  }
  getKeys = () => {
    return Object.keys(this.keysObject)
  }
}

export const combineTexts = (text1: string, text2: string) => {
  let result = null
  if (hasValue(text1) && hasValue(text2)) {
    result = text1 + ' - ' + text2
  } else if (hasValue(text1)) {
    result = text1
  } else if (hasValue(text2)) {
    result = text2
  }
  return result
}

// The localIsoDateString should has the yyyy-mm-dd format.
export const getDateFromLocalIsoDateString = (localIsoDateString: string) => {
  return hasValue(localIsoDateString) ? new Date(localIsoDateString + localIsoTimeString) : new Date()
}

export const getLocalIsoDateString = (value: string | Date | number): string => {
  let result = ''
  if (hasValue(value)) {
    const dt = new Date(value)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1).toString().padStart(2, '0')
    const d = dt.getDate().toString().padStart(2, '0')
    result = `${y}-${m}-${d}`
  }
  return result
}

export const getLocalDateString = (value: any): string => {
  return hasValue(value) ? new Date(value).toLocaleDateString() : ''
}

export const getLocalDateTimeString = (value: string | Date | number): string => {
  return hasValue(value)
    ? new Date(value).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
    : ''
}

export const combineConditionsByOr = (conditions: any[]) => {
  return combineConditions(Operator.or, conditions)
}

export const combineConditionsByAnd = (conditions: any[]) => {
  return combineConditions(Operator.and, conditions)
}
