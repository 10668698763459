import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, ButtonInvisible, Header as LibHeader, SelectMenu, Text } from '@primer/react'
import { PersonIcon, ThreeBarsIcon } from '@primer/octicons-react'

import { hasValue } from '../utils'

const OpenMenuButton = props => {
  const { id, width, height, as, children, onClick } = props
  return (
    <ButtonInvisible
      id={id}
      sx={{
        width,
        height,
        px: 0,
        py: 0,
        bg: 'transparent',
        '> *': { color: 'header.logo' },
        '&:hover': {
          bg: 'transparent',
          '> *': { color: 'header.text' },
        },
      }}
      as={as}
      children={children}
      onClick={onClick}
    />
  )
}

const DesktopHeaderItem = props => {
  const { full, flexDirection, justifyContent, children } = props
  return (
    <LibHeader.Item
      full={full}
      sx={{ display: ['none', 'none', 'flex'], flexDirection, justifyContent }}
      as={Box}
      children={children}
    />
  )
}

const MobileHeaderItem = props => {
  const { full, flexDirection, justifyContent, children, mt, mr, py } = props
  return (
    <LibHeader.Item
      full={full}
      sx={{ display: ['flex', 'flex', 'none'], flexDirection, justifyContent, mt, mr, py }}
      as={Box}
      children={children}
    />
  )
}

export class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { linksMenuDropdownVisible: false }
  }

  onOpenMenuButtonClick = () => {
    const { linksMenuDropdownVisible } = this.state
    this.setState({ linksMenuDropdownVisible: !linksMenuDropdownVisible })
  }

  onLinksMenuItemClick = () => {
    this.setState({ linksMenuDropdownVisible: false })
  }

  renderLinksMenuForDesktop = () => {
    const { linksMenuItems } = this.props
    return Array.isArray(linksMenuItems)
      ? linksMenuItems.map((linksMenuItem, index) => {
          return (
            <LibHeader.Link
              id={linksMenuItem.id}
              key={index}
              to={linksMenuItem.url}
              sx={{ py: '16px', my: '-16px', mr: '16px' }}
              as={NavLink}
            >
              {linksMenuItem.text}
            </LibHeader.Link>
          )
        })
      : null
  }

  renderLinksMenuForMobile = () => {
    const { linksMenuItems } = this.props
    return Array.isArray(linksMenuItems)
      ? linksMenuItems.map((linksMenuItem, index) => {
          return (
            <LibHeader.Link
              id={linksMenuItem.id}
              key={index}
              to={linksMenuItem.url}
              sx={{
                width: '100%',
                height: '100%',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: 'rgba(255, 255, 255, 0.15)',
                py: '8px',
              }}
              onClick={this.onLinksMenuItemClick}
              as={NavLink}
            >
              {linksMenuItem.text}
            </LibHeader.Link>
          )
        })
      : null
  }

  renderUserMenu = () => {
    const { avatar, userName, userMenuItems } = this.props
    const items = Array.isArray(userMenuItems)
      ? userMenuItems.map((menuItem, index) => {
          const result = menuItem.groupHeader ? (
            <SelectMenu.Divider key={index}>
              <Text sx={{ fontSize: [1, 0, 0] }}>{menuItem.text}</Text>
            </SelectMenu.Divider>
          ) : (
            <SelectMenu.Item
              key={index}
              selected={menuItem.selected}
              href={menuItem.url}
              as={hasValue(menuItem.url) ? undefined : 'button'}
              onClick={menuItem.onClick}
            >
              <Text sx={{ fontSize: [1, 0, 0] }}>{menuItem.text}</Text>
            </SelectMenu.Item>
          )
          return result
        })
      : null
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', position: 'relative' }}>
        <SelectMenu>
          <OpenMenuButton id={'userMenuButton'} width={'24px'} height={'24px'} as={'summary'}>
            {hasValue(avatar) ? avatar : <PersonIcon size={24} />}
          </OpenMenuButton>
          <SelectMenu.Modal align={'right'} width={['100%', 'max-content', 'max-content']}>
            <SelectMenu.Header>{userName}</SelectMenu.Header>
            <SelectMenu.List>{items}</SelectMenu.List>
          </SelectMenu.Modal>
        </SelectMenu>
      </Box>
    )
  }

  render() {
    const { px = [3, 3, 5], logo } = this.props
    const { linksMenuDropdownVisible } = this.state
    return (
      <LibHeader sx={{ flexDirection: 'column', px: px }} as={'header'}>
        <LibHeader.Item full={true} sx={{ display: 'flex', flexDirection: 'row', mr: 0 }} as={Box}>
          <DesktopHeaderItem justifyContent={'center'}>{logo}</DesktopHeaderItem>
          <MobileHeaderItem justifyContent={'center'}>
            <OpenMenuButton id={'openMenuButton'} onClick={this.onOpenMenuButtonClick}>
              <ThreeBarsIcon size={24} />
            </OpenMenuButton>
          </MobileHeaderItem>
          <DesktopHeaderItem full={true} flexDirection={'row'} justifyContent={'flex-start'}>
            {this.renderLinksMenuForDesktop()}
          </DesktopHeaderItem>
          <MobileHeaderItem full={true} justifyContent={'center'}>
            {logo}
          </MobileHeaderItem>
          <LibHeader.Item sx={{ display: 'flex', justifyContent: 'center', mr: 0 }} as={Box}>
            {this.renderUserMenu()}
          </LibHeader.Item>
        </LibHeader.Item>
        {linksMenuDropdownVisible ? (
          <MobileHeaderItem flexDirection={'column'} mt={'16px'} mr={0} py={0}>
            {this.renderLinksMenuForMobile()}
          </MobileHeaderItem>
        ) : null}
      </LibHeader>
    )
  }
}
