import {
  Ticket_Created,
  Ticket_Subject_Changed,
  Ticket_Client_Changed,
  Ticket_Contact_Changed,
  Ticket_Agent_Changed,
  Ticket_Taken,
  Ticket_Sent_To_Clarify,
  Ticket_Commented,
  Ticket_Refused,
  Ticket_Returned,
  Ticket_Assigned,
  Ticket_Sla_Changed,
  Ticket_Type_Changed,
  Ticket_Accepted,
  Ticket_Started,
  Ticket_Paused,
  Ticket_Resumed,
  Ticket_Report_Created,
  Ticket_Completed,
  Ticket_Rejected,
  Ticket_Confirmed,
  Ticket_Feedback_Submitted,
} from '../eventTypes'
import { hasValue } from '../../utils/utils'
import { ticketStates } from '../ticketStates'

const ticketViewModel = {
  Init: () => ({}),

  [Ticket_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, clientId, contactId, agentId, slaId, subject, description } = payload
    const result = {
      id: aggregateId,
      authorId,
      createdOn: new Date(timestamp ?? 0),
      clientId,
      contactId,
      agentId,
      slaId,
      subject,
      feedbackSubmitted: false,
      state: ticketStates.new.id,
    }
    return result
  },

  [Ticket_Subject_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, subject } = payload
    const result = { ...state, subject }
    return result
  },

  [Ticket_Client_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, clientId } = payload
    const result = { ...state, clientId, contactId: null, slaId: null }
    if (result.state !== ticketStates.clarification.id) {
      result.state = ticketStates.new.id
    }
    return result
  },

  [Ticket_Contact_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, contactId } = payload
    const result = { ...state, contactId }
    return result
  },

  [Ticket_Agent_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, agentId } = payload
    const result = { ...state, agentId, engineerId: null, slaId: null, state: ticketStates.new.id }
    return result
  },

  [Ticket_Taken]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: authorId }
    return result
  },

  [Ticket_Sent_To_Clarify]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, description } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.clarification.id, prevState: state.state }
    return result
  },

  [Ticket_Commented]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, description, newState } = payload
    const result = { ...state }
    if (hasValue(newState)) {
      result.state = newState
    }
    return result
  },

  [Ticket_Refused]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.refused.id }
    return result
  },

  [Ticket_Returned]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: null, state: ticketStates.new.id }
    return result
  },

  [Ticket_Assigned]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, engineerId } = payload
    const result = { ...state, engineerId }
    return result
  },

  [Ticket_Sla_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, slaId } = payload
    const result = { ...state, slaId }
    return result
  },

  [Ticket_Type_Changed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, typeId } = payload
    const result = { ...state, typeId }
    return result
  },

  [Ticket_Accepted]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: null, state: ticketStates.accepted.id }
    return result
  },

  [Ticket_Started]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.inProgress.id }
    return result
  },

  [Ticket_Paused]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.paused.id }
    return result
  },

  [Ticket_Resumed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.inProgress.id }
    return result
  },

  [Ticket_Report_Created]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, description, spentTime, visibility } = payload
    const result = { ...state }
    return result
  },

  [Ticket_Completed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, description, spentTime, visibility } = payload
    const result = { ...state, engineerId: authorId, state: ticketStates.completed.id }
    return result
  },

  [Ticket_Rejected]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, description } = payload
    const result = { ...state, state: ticketStates.rejected.id }
    return result
  },

  [Ticket_Confirmed]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId } = payload
    const result = { ...state, state: ticketStates.confirmed.id }
    return result
  },

  [Ticket_Feedback_Submitted]: (state: any, event: any) => {
    const { timestamp, payload } = event
    const { authorId, rating, description } = payload
    const result = { ...state, feedbackSubmitted: true }
    return result
  },
}

export default ticketViewModel
