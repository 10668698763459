import { useTheme } from '@primer/react'

export const hasValue = v => {
  return v !== undefined && v !== null
}

export const getThemeColors = () => {
  const { theme } = useTheme()
  return theme.colors
}
