import React, { PureComponent } from 'react'

import { Box, Button, buttonKind } from '../ui'

export class LanguageSelector extends PureComponent {
  render() {
    const { languages, onChange } = this.props
    return (
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
        {languages.map((language, index) => {
          return (
            <Button
              key={index}
              id={language.id}
              kind={buttonKind.invisible}
              title={language.name}
              onClick={() => onChange(language.id)}
            />
          )
        })}
      </Box>
    )
  }
}
