import React, { PureComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, ButtonTableList, Text, LabelGroup, Link, Tooltip, SelectMenu } from '@primer/react'
import { PersonIcon } from '@primer/octicons-react'

import { hasValue } from '../utils'
import { Label } from '../label/Label'

const borderRadius = 2

class ListItem extends PureComponent {
  render() {
    const { isLastItem, children } = this.props
    const borderBottomRadius = isLastItem ? borderRadius : 0
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          borderStyle: 'solid',
          borderColor: 'border.muted',
          borderWidth: 0,
          borderTopWidth: 1,
          borderBottomWidth: 0,
          borderRadius: 0,
          borderBottomLeftRadius: [0, borderBottomRadius, borderBottomRadius],
          borderBottomRightRadius: [0, borderBottomRadius, borderBottomRadius],
          px: 3,
          py: 2,
          '&:hover': { backgroundColor: 'canvas.subtle' },
        }}
      >
        {children}
      </Box>
    )
  }
}

class ToolbarMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { filterText: '' }
  }

  onFilterChange = e => {
    this.setState({ filterText: e.target.value })
  }

  render() {
    const { menu } = this.props
    const { filterText } = this.state
    return (
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', ml: 5 }}>
        <SelectMenu id={menu.id}>
          <ButtonTableList sx={{ '&:hover': { textDecoration: 'none', color: 'fg.default' } }}>{menu.title}</ButtonTableList>
          <SelectMenu.Modal align={'right'} width={['100%', 'max-content', 'max-content']}>
            <SelectMenu.Header>{menu.dropdownTitle}</SelectMenu.Header>
            <SelectMenu.Filter
              placeholder={menu.dropdownFilter}
              value={filterText}
              aria-label={menu.dropdownFilter}
              onChange={this.onFilterChange}
            />
            <SelectMenu.List>
              {Array.isArray(menu.items)
                ? menu.items.map((menuItem, index) => {
                    if (menuItem.groupHeader) {
                      return (
                        <SelectMenu.Divider key={index}>
                          <Text sx={{ fontSize: [1, 0, 0] }}>{menuItem.text}</Text>
                        </SelectMenu.Divider>
                      )
                    } else {
                      if (
                        !hasValue(filterText) ||
                        filterText === '' ||
                        (hasValue(menuItem.text) && menuItem.text.toLowerCase().includes(filterText.toLowerCase()))
                      ) {
                        return (
                          <SelectMenu.Item key={index} selected={menuItem.selected} as={'button'} onClick={menuItem.onClick}>
                            <Text sx={{ fontSize: [1, 0, 0], color: 'fg.default' }}>{menuItem.text}</Text>
                          </SelectMenu.Item>
                        )
                      }
                    }
                  })
                : null}
            </SelectMenu.List>
          </SelectMenu.Modal>
        </SelectMenu>
      </Box>
    )
  }
}

export class List extends PureComponent {
  renderToolbarMenus = menus => {
    return Array.isArray(menus)
      ? menus.map((menu, index) => {
          return <ToolbarMenu key={index} menu={menu} />
        })
      : null
  }

  renderToolbar = () => {
    const { toolbarSettings } = this.props
    return (
      <Box
        key={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          borderTopLeftRadius: [0, borderRadius, borderRadius],
          borderTopRightRadius: [0, borderRadius, borderRadius],
          backgroundColor: 'canvas.subtle',
          px: 3,
          py: 3,
        }}
      >
        {hasValue(toolbarSettings) ? this.renderToolbarMenus(toolbarSettings.menus) : null}
      </Box>
    )
  }

  renderLabel = (key, id, text, color) => {
    return hasValue(text) && text !== '' ? <Label key={key} id={id} color={color} children={text} /> : null
  }

  renderSubject = (text, url) => {
    return hasValue(url) ? (
      <Link
        to={url}
        muted={true}
        sx={{ verticalAlign: 'middle', color: 'fg.default', fontSize: 2, fontWeight: 'bold', mr: 2 }}
        as={RouterLink}
      >
        {text}
      </Link>
    ) : (
      <Text sx={{ verticalAlign: 'middle', fontSize: 2, fontWeight: 'bold', mr: 2 }}>{text}</Text>
    )
  }

  renderLabels = labels => {
    return Array.isArray(labels) ? (
      <LabelGroup>
        {labels.map((label, index) => {
          return this.renderLabel(index, label.id, label.text, label.color)
        })}
      </LabelGroup>
    ) : null
  }

  renderCreationInfo = creationInfo => {
    const { authorName, authorUrl, details } = creationInfo
    return (
      <Box sx={{ display: 'block' }}>
        <Link
          id={'authorName'}
          to={authorUrl}
          muted={true}
          sx={{ fontSize: 0, color: 'fg.muted', fontWeight: 'bold' }}
          as={RouterLink}
        >
          {authorName}
        </Link>
        <Text id={'details'} sx={{ fontSize: 0, color: 'fg.muted', ml: 1 }}>
          {details}
        </Text>
      </Box>
    )
  }

  renderAssagnee = assagnee => {
    let result = null
    if (hasValue(assagnee)) {
      const { id, text, url } = assagnee
      if (hasValue(text)) {
        const icon = <PersonIcon size={20} />
        result = (
          <Tooltip id={id} aria-label={`Assigned to ${text}`} noDelay={true} sx={{ mt: 1 }}>
            {hasValue(url) ? (
              <Link to={url} muted={true} sx={{ color: 'fg.muted' }} as={RouterLink}>
                {icon}
              </Link>
            ) : (
              icon
            )}
          </Tooltip>
        )
      }
    }
    return result
  }

  renderComments = comments => {
    return null
  }

  renderItem = (key, item, isLastItem) => {
    return (
      <ListItem key={key} isLastItem={isLastItem}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%', pr: 5 }}>
          <Box sx={{ display: 'block' }}>
            {this.renderSubject(item.subject, item.url)}
            {this.renderLabels(item.labels)}
          </Box>
          {this.renderCreationInfo(item.creationInfo)}
        </Box>
        <Box sx={{ display: ['none', 'flex', 'flex'], flexDirection: 'row', width: '25%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%' }}>{this.renderComments(item.comments)}</Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', width: '50%', alignItems: 'flex-start', justifyContent: 'center' }}
          >
            {this.renderAssagnee(item.assagnee)}
          </Box>
        </Box>
        {hasValue(item.url) ? (
          <Link
            to={item.url}
            sx={{
              display: ['block', 'none', 'none'],
              position: 'absolute',
              left: '0px',
              top: '0px',
              right: '0px',
              bottom: '0px',
            }}
            as={RouterLink}
          />
        ) : null}
      </ListItem>
    )
  }

  renderItems = () => {
    const { items } = this.props
    return Array.isArray(items)
      ? items.map((item, index) => {
          return this.renderItem(index + 1, item, index === items.length - 1)
        })
      : null
  }

  render() {
    return (
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'border.default',
          borderRadius: [0, borderRadius, borderRadius],
        }}
      >
        {this.renderToolbar()}
        {this.renderItems()}
      </Box>
    )
  }
}
