import { TicketType_Created, TicketType_Renamed, TicketType_Color_Changed } from '../eventTypes'

const ticketTypeViewModel = {
  Init: () => ({}),

  [TicketType_Created]: (state: any, event: any) => {
    const { aggregateId, timestamp, payload } = event
    const { authorId, name, color } = payload
    return { id: aggregateId, authorId, createdOn: new Date(timestamp ?? 0), name, color }
  },

  [TicketType_Renamed]: (state: any, event: any) => {
    const { payload } = event
    const { name } = payload
    return { ...state, name }
  },

  [TicketType_Color_Changed]: (state: any, event: any) => {
    const { payload } = event
    const { color } = payload
    return { ...state, color }
  },
}

export default ticketTypeViewModel
