import React, { PureComponent } from 'react'

import { View, ViewHeader } from '../../ui/view/View'
import TicketsList from '../lists/TicketsList'

export class UserTicketsView extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { jwtUser, userRole, userId, l, languageId } = this.props
    return (
      <View>
        <ViewHeader text={l.t('Tickets.Tickets')} />
        <TicketsList
          jwtUser={jwtUser}
          userRole={userRole}
          condition={{ authorId: userId }}
          keysPrefix={'UserTicketsView.'}
          l={l}
          languageId={languageId}
        />
      </View>
    )
  }
}
