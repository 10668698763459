import React, { PureComponent, Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useViewModel, useCommand, useCommandBuilder } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue, trim } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { usePermissionJwt } from '../hooks'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { Button, ButtonsRow, Panel, PanelColumn } from '../../ui'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'
import { TextField } from '../../ui/textField/TextField'
import { TextInput } from '../../ui/textInput/TextInput'
import { TextEditor } from '../../ui/textEditor/TextEditor'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'
import { ChangingButtons } from '../../ui/buttons/ChangingButtons'
import UserEmploymentsView from './UserEmploymentsView'
import { UserTicketsView } from './UserTicketsView'

export class UserView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { password: '' }
    this.state = { changing: false }
  }

  onChangeButtonClick = () => {
    const { user } = this.props
    this.setState({ changing: true, email: user.email })
  }

  onSaveButtonClick = async () => {
    const { user, userUpdate } = this.props
    const { email } = this.state
    if (email !== user.email) {
      await executeCommand(userUpdate, { email })
    }
    this.setState({ changing: false })
  }

  onCancelButtonClick = () => {
    this.setState({ changing: false })
  }

  onNameChange = async value => {
    const { userUpdate } = this.props
    await executeCommand(userUpdate, { name: value })
  }

  onEmailChange = async value => {
    this.setState({ email: value })
  }

  onPasswordChange = value => {
    this.setState({ password: value })
  }

  onSetPasswordButtonClick = async () => {
    const { userSetPassword } = this.props
    const { password } = this.state
    if (trim(password) !== '' && password.length >= 5) {
      await executeCommand(userSetPassword, { password })
      this.setState({ password: '' })
    } else {
      alert('The password length must be more than 5 symbols.')
    }
  }

  onSuperadminChange = async value => {
    const { userSetSuperadmin, userClearSuperadmin } = this.props
    if (value) {
      if (confirm(`Do you want to set the superadmin role?`)) {
        await executeCommand(userSetSuperadmin, {})
      }
    } else {
      if (confirm(`Do you want to remove the superadmin role?`)) {
        await executeCommand(userClearSuperadmin, {})
      }
    }
  }

  renderButtons = () => {
    const { l } = this.props
    const { password } = this.state
    return (
      <ButtonsRow>
        <TextInput id={'password'} value={password} placeholder={l.t('Users.PasswordPlaceholder')} width={'350px'} onChange={this.onPasswordChange} />
        <Button
          id={'setPassword'}
          variant={'small'}
          title={l.t('Users.Actions.Set')}
          disabled={!hasValue(password) || trim(password) === ''}
          onClick={this.onSetPasswordButtonClick}
        />
      </ButtonsRow>
    )
  }

  render() {
    const { jwtUser, userRole, userId, user: propsUser, l, languageId } = this.props
    const { changing, email } = this.state
    return (
      <View>
        <ViewHeader text={l.t('Users.User')} buttons={this.renderButtons()} />
        <MobileIndent>
          <AdvancedText
            id={'name'}
            caption={l.t('Users.Name')}
            value={propsUser.name}
            allowChanging={true}
            style={{ marginBottom: '8px' }}
            l={l}
            onChange={this.onNameChange}
          />
        </MobileIndent>
        <Panel>
          <PanelColumn>
            <TextField id={'createdOn'} caption={l.t('Users.CreatedOn')} value={getLocalDateTimeString(propsUser.createdOn)} />
            <BooleanEditor
              id={'superadmin'}
              caption={l.t('Users.Superadmin')}
              value={propsUser.superadmin}
              readOnly={!hasValue(userRole) || !userRole.superadmin}
              onChange={this.onSuperadminChange}
            />
          </PanelColumn>
          <PanelColumn>
            {changing ? (
              <Fragment>
                <TextEditor id={'email'} caption={l.t('Users.Email')} value={email} autoFocus={true} onChange={this.onEmailChange} />
              </Fragment>
            ) : (
              <Fragment>
                <TextField id={'email'} caption={l.t('Users.Email')} value={propsUser.email} />
              </Fragment>
            )}
            <ChangingButtons
              changing={changing}
              l={l}
              onChangeButtonClick={this.onChangeButtonClick}
              onSaveButtonClick={this.onSaveButtonClick}
              onCancelButtonClick={this.onCancelButtonClick}
            />
          </PanelColumn>
        </Panel>
        <UserEmploymentsView userRole={userRole} userId={userId} l={l} />
        <UserTicketsView jwtUser={jwtUser} userRole={userRole} userId={userId} l={l} languageId={languageId} />
      </View>
    )
  }
}

const UserViewContainer = props => {
  const viewModelName = 'UserVm'
  const [object, setObject] = useState({})
  const { userId } = useParams()
  const { connect, dispose } = useViewModel(viewModelName, [userId], setObject)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])
  const permissionJwt = usePermissionJwt('userCommandsPermission', { userId })
  const userUpdate = useCommandBuilder(({ name, email }) => {
    return { aggregateName: 'User', aggregateId: userId, type: 'update', payload: { name, email, permissionJwt } }
  })
  const userSetPassword = useCommandBuilder(({ password }) => {
    return { aggregateName: 'User', aggregateId: userId, type: 'setPassword', payload: { password, permissionJwt } }
  })
  const userSetSuperadmin = useCommand({ aggregateName: 'User', aggregateId: userId, type: 'setSuperadmin' })
  const userClearSuperadmin = useCommand({ aggregateName: 'User', aggregateId: userId, type: 'clearSuperadmin' })
  return (
    <UserView
      {...props}
      userId={userId}
      user={object}
      userUpdate={userUpdate}
      userSetPassword={userSetPassword}
      userSetSuperadmin={userSetSuperadmin}
      userClearSuperadmin={userClearSuperadmin}
    />
  )
}

export default UserViewContainer
