import React, { useLayoutEffect } from 'react'
import { Text, Pagination as LibPagination } from '@primer/react'

export const Pagination = props => {
  const { pageCount, currentPage, previousLabel, nextLabel, onPageChange } = props

  // The Previous and Next labels are not accessed for the setup via the Pagination props.
  useLayoutEffect(() => {
    const spanSelectors = document.querySelectorAll('span')
    const linkSelectors = document.querySelectorAll('a')
    ;[...spanSelectors, ...linkSelectors].forEach(selector => {
      if (selector.innerText === 'Previous') {
        selector.innerText = previousLabel
      }
      if (selector.innerText === 'Next') {
        selector.innerText = nextLabel
      }
    })
  }, [pageCount, currentPage, previousLabel, nextLabel])

  return (
    <LibPagination
      pageCount={pageCount}
      currentPage={currentPage}
      sx={{ fontSize: 1 }}
      as={Text}
      onPageChange={onPageChange}
    />
  )
}
