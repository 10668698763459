import styled from 'styled-components'

import { Row } from '../row/Row'

export const ButtonsRow = styled(Row)`
  justify-content: flex-end;
  flex-wrap: wrap;
  > * {
    margin-left: 4px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
    justify-content: flex-start;
    > * {
      margin-left: 0px;
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
`
