import React, { PureComponent } from 'react'
import { TextInput as LibTextInput } from '@primer/react'

import { hasValue } from '../utils'

export class TextInput extends PureComponent {
  onBlur = e => {
    const { onBlur } = this.props
    if (onBlur instanceof Function) {
      onBlur(e.target.value)
    }
  }

  onChange = e => {
    const { onChange } = this.props
    if (onChange instanceof Function) {
      onChange(e.target.value)
    }
  }

  render() {
    const {
      id,
      name,
      type,
      autoFocus,
      placeholder,
      value,
      readOnly,
      backgroundColor = 'canvas.default',
      width,
      height,
      mt,
      mr,
    } = this.props
    return (
      <LibTextInput
        id={id}
        name={name}
        type={type}
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={hasValue(value) ? value : ''}
        readOnly={readOnly}
        sx={{ backgroundColor, width, height, mt, mr }}
        onBlur={this.onBlur}
        onChange={this.onChange}
      />
    )
  }
}
