export const User_Created = 'User_Created'
export const User_Updated = 'User_Updated'
export const User_Password_Set = 'User_Password_Set'
export const User_Superadmin_Changed = 'User_Superadmin_Changed'
// TODO : Delete the User_Superadmin_Set and User_Superadmin_Cleared events after any replication.
export const User_Superadmin_Set = 'User_Superadmin_Set'
export const User_Superadmin_Cleared = 'User_Superadmin_Cleared'

export const Company_Created = 'Company_Created'
export const Company_Renamed = 'Company_Renamed'
export const Company_Activity_Changed = 'Company_Activity_Changed'
export const Company_Superagent_Changed = 'Company_Superagent_Changed'
export const Company_Employment_Created = 'Company_Employment_Created'
export const Company_Employment_Removed = 'Company_Employment_Removed'
export const Company_Employment_Role_Added = 'Company_Employment_Role_Added'
export const Company_Employment_Role_Removed = 'Company_Employment_Role_Removed'
export const Company_Group_Created = 'Company_Group_Created'
export const Company_Group_Renamed = 'Company_Group_Renamed'
export const Company_Group_Removed = 'Company_Group_Removed'
export const Company_Add_Employment_To_Group = 'Company_Add_Employment_To_Group'
export const Company_Remove_Employment_From_Group = 'Company_Remove_Employment_From_Group'

export const CfgItem_Created = 'CfgItem_Created'
export const CfgItem_Renamed = 'CfgItem_Renamed'
export const CfgItem_Activity_Changed = 'CfgItem_Activity_Changed'
export const CfgItem_Removed = 'CfgItem_Removed'

export const Service_Created = 'Service_Created'
export const Service_Renamed = 'Service_Renamed'

export const Sla_Created = 'Sla_Created'
export const Sla_Updated = 'Sla_Updated'
export const Sla_Activity_Changed = 'Sla_Activity_Changed'

export const TicketType_Created = 'TicketType_Created'
export const TicketType_Renamed = 'TicketType_Renamed'
export const TicketType_Color_Changed = 'TicketType_Color_Changed'

export const Ticket_Created = 'Ticket_Created'
export const Ticket_Subject_Changed = 'Ticket_Subject_Changed'
export const Ticket_Client_Changed = 'Ticket_Client_Changed'
export const Ticket_Contact_Changed = 'Ticket_Contact_Changed'
export const Ticket_Agent_Changed = 'Ticket_Agent_Changed'
export const Ticket_Taken = 'Ticket_Taken'
export const Ticket_Sent_To_Clarify = 'Ticket_Sent_To_Clarify'
export const Ticket_Commented = 'Ticket_Commented'
export const Ticket_Refused = 'Ticket_Refused'
export const Ticket_Returned = 'Ticket_Returned'
export const Ticket_Assigned = 'Ticket_Assigned'
export const Ticket_Sla_Changed = 'Ticket_Sla_Changed'
export const Ticket_Type_Changed = 'Ticket_Type_Changed'
export const Ticket_Accepted = 'Ticket_Accepted'
export const Ticket_Started = 'Ticket_Started'
export const Ticket_Paused = 'Ticket_Paused'
export const Ticket_Resumed = 'Ticket_Resumed'
export const Ticket_Report_Created = 'Ticket_Report_Created'
export const Ticket_Completed = 'Ticket_Completed'
export const Ticket_Rejected = 'Ticket_Rejected'
export const Ticket_Confirmed = 'Ticket_Confirmed'
export const Ticket_Feedback_Submitted = 'Ticket_Feedback_Submitted'
