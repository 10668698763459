import React, { PureComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useViewModel, useClient, useCommandBuilder } from '@resolve-js/react-hooks'

import { getLocalDateTimeString, hasValue } from '../../../utils/utils'
import { executeCommand } from '../../utils'
import { usePermissionJwt, useClientQuery } from '../hooks'
import { View, ViewHeader, MobileIndent } from '../../ui/view/View'
import { AdvancedText } from '../../ui/advancedText/AdvancedText'
import { Panel, PanelColumn } from '../../ui'
import { TextField } from '../../ui/textField/TextField'
import { BooleanEditor } from '../../ui/booleanEditor/BooleanEditor'

export class CfgItemView extends PureComponent {
  onNameChange = async value => {
    const { cfgItemRename } = this.props
    await executeCommand(cfgItemRename, { name: value })
  }

  onActiveChange = async value => {
    const { cfgItemResume, cfgItemPause, l } = this.props
    if (value) {
      if (confirm(l.t('CfgItems.Actions.ActivateConfirmation'))) {
        await executeCommand(cfgItemResume, {})
      }
    } else {
      if (confirm(l.t('CfgItems.Actions.DeactivateConfirmation'))) {
        await executeCommand(cfgItemPause, {})
      }
    }
  }

  render() {
    const { cfgItem, l } = this.props
    return (
      <View>
        <ViewHeader text={l.t('CfgItems.CfgItem')} />
        <MobileIndent>
          <AdvancedText
            id={'name'}
            caption={l.t('CfgItems.Name')}
            value={cfgItem.name}
            allowChanging={true}
            style={{ marginBottom: '8px' }}
            l={l}
            onChange={this.onNameChange}
          />
        </MobileIndent>
        <Panel>
          <PanelColumn>
            <TextField id={'createdOn'} caption={l.t('CfgItems.CreatedOn')} value={getLocalDateTimeString(cfgItem.createdOn)} />
            <TextField id={'companyName'} caption={l.t('CfgItems.Company')} value={cfgItem.companyName} />
            <BooleanEditor id={'active'} caption={l.t('CfgItems.Active')} value={cfgItem.active} onChange={this.onActiveChange} />
          </PanelColumn>
        </Panel>
      </View>
    )
  }
}

const CfgItemViewContainer = props => {
  const { userRole } = props
  const viewModelName = 'CfgItemVm'
  const resolverName = 'cfgItemById'
  const { cfgItemId } = useParams()
  const userRoleString = JSON.stringify(userRole)

  // Read model.
  const client = useClient()
  const object1 = useClientQuery(client, {}, 'Saturn', resolverName, { userRoleString, cfgItemId })

  // View model.
  const [object2, setObject2] = useState({})
  const { connect, dispose } = useViewModel(viewModelName, [cfgItemId], setObject2)
  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])

  const companyId = hasValue(object2) ? object2.companyId : null
  const permissionJwt = usePermissionJwt('cfgItemCommandsPermission', { companyId })
  const cfgItemRename = useCommandBuilder(({ name }) => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'rename', payload: { name, permissionJwt } }
  })
  const cfgItemPause = useCommandBuilder(() => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'pause', payload: { permissionJwt } }
  })
  const cfgItemResume = useCommandBuilder(() => {
    return { aggregateName: 'CfgItem', aggregateId: cfgItemId, type: 'resume', payload: { permissionJwt } }
  })
  return (
    <CfgItemView
      {...props}
      cfgItemId={cfgItemId}
      cfgItem={{ ...object1, ...object2 }}
      cfgItemRename={cfgItemRename}
      cfgItemResume={cfgItemResume}
      cfgItemPause={cfgItemPause}
    />
  )
}

export default CfgItemViewContainer
